import React from 'react';
import { AiIcon, SuggestionsButtonContainer, SuggestionsIconContainer } from './SuggestionsButtonStyles';
import { Tooltip } from '@mui/material';

export type SuggestionsButtonProps = {
  opened: boolean;
  isLoading: boolean;
  onClick: () => void;
};

const SuggestionsButton = ({ opened, isLoading, onClick }: SuggestionsButtonProps) =>


(
  <SuggestionsButtonContainer
    opened={opened}
    onClick={onClick}>
    <Tooltip
      title={!opened ? 'See Suggestions' : 'Close'}>
      <SuggestionsIconContainer >
        <AiIcon
          width="15px"
          height="15px"
          isLoading={isLoading}
        />
      </SuggestionsIconContainer>
    </Tooltip>
    {/* <SuggestionsText >
      {!opened ? 'See Suggestions' : 'Close'}
    </SuggestionsText> */}
  </SuggestionsButtonContainer>
);


export default SuggestionsButton;
