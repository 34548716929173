import React from 'react';
import { ReactComponent as ErrorPageImage } from '../../assets/errorPage.svg';
import { Stack, Typography, alpha } from '@mui/material';
import palette from '../../theme/palette';

const PageHasBeenForceRefreshed = 'page-has-been-force-refreshed';

const retryPageLoading = () => {
  const pageHasAlreadyBeenForceRefreshed = JSON.parse(
    localStorage.getItem(PageHasBeenForceRefreshed) || 'false'
  );

  if (pageHasAlreadyBeenForceRefreshed === 'false') {
    localStorage.setItem(PageHasBeenForceRefreshed, 'true');
    return location.reload();
  } else {
    localStorage.setItem(PageHasBeenForceRefreshed, 'false');
  }
};

interface ErrorBoundaryProps {
  children: React.ReactNode;
}
export class ErrorBoundary extends React.Component<ErrorBoundaryProps, { hasError: boolean }> {
  constructor(props: Readonly<ErrorBoundaryProps>) {
    super(props);
    this.state = { hasError: false };
  }
  componentDidCatch(error: unknown, info: unknown) {
    retryPageLoading();
    this.setState({ hasError: true });
    console.log(error, info);
  }
  render() {
    if (this.state.hasError) {
      return <Stack
        alignItems="center"
        gap="5px"
        height="100%"
        width="100%"
        sx={{
          backgroundColor: palette.background.grey,
          svg: { path: { fill: alpha(palette.primary.main, 0.7) } }
        }}>
        <ErrorPageImage width="1000px" height="500px" />
        <Typography
          fontFamily='Plus Jakarta Sans'
          variant="h1"
          fontSize="30px" >Oops... we are sorry, something went wrong.</Typography>
           <Typography
          fontFamily='Plus Jakarta Sans'
          variant="body2"
          fontSize="20px" >We are on it and we will get it fixed as soon as we can.</Typography>
        <Stack
          sx={{
            marginTop: 4,
            backgroundColor: palette.primary.main,
            color: "white",
            padding: 2,
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "6px",
            cursor: "pointer",
            ":hover": {
              opacity: 0.8
            },
            fontSize: "20px",
            fontWeight: 700,
            width: "max-content"
          }}
          onClick={
            () => window.history.back()
          }>Go Back</Stack>



      </Stack>;
    }
    return this.props.children;
  }
}
