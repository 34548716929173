import React, { useState } from 'react';

import SendIcon from '@mui/icons-material/Send';
import { Avatar, Stack } from '@mui/material';

import useAuth from '../../hooks/useAuth';
import Editor from '../../screens/BizAreaModules/components/Editor';
import palette from '../../theme/palette';
import { randomBgColor } from '../../utils/avatarHelper';
import { htmlParser } from '../../utils/htmlParser';
import { generateEmptySpaces } from '../../utils/textFormater';
import {
  AvatarAndEditorContainer,
  CancelButton,
  CommentButtonContainer,
  CommentInputContainer,
  Container
} from './AddUpdateStyles';

type AddCommentProps = {
  title?: string;
  createComment: (content: string, taggedUserIds: string[]) => Promise<void>;
  refetch: () => void;
};

const AddUpdate = ({
  createComment,
  title,
  refetch
}: AddCommentProps) => {
  const [taggedUserIds, setTaggedUserIds] = useState<string[]>([]);
  const [typedComment, setTypedComment] = useState('');
  const { user } = useAuth();
  const commentContent = htmlParser(typedComment);

  const [focused, setFocused] = useState<boolean>(false);

  return (
    <Container>
      <AvatarAndEditorContainer>
        <Avatar
          variant="rounded"
          sx={{
            fontSize: '16px',
            borderRadius: '16px',
            height: '33px',
            width: '33px',
            background: user
              ? randomBgColor(user.firstName + generateEmptySpaces(1) + user.lastName)
              : palette.background.lightGrey
          }}
        >
          {user?.firstName[0]}
        </Avatar>
        <CommentInputContainer
          onClick={() => setFocused(true)}
        >
          <Editor
            includeMentions={true}
            addTaggedUserId={(userId: string) =>
              setTaggedUserIds(prevState => [...prevState, userId])
            }
            showBorder={false}
            sx={{ width: '100%' }}
            defaultValue={typedComment}
            value={typedComment}
            onChange={event => setTypedComment(event)}
            placeholder={title ? "Add a " + title + "..." : "Add a comment..."}
          />
        </CommentInputContainer>
      </AvatarAndEditorContainer>

      {focused &&
        <Stack
          direction="row"
          alignItems="center"
          gap={1}>
          <CancelButton
            onClick={() => {
              setFocused(false);
              setTypedComment('');
            }
            }
          >
            Cancel
          </CancelButton>
          <CommentButtonContainer
            disabled={!commentContent}
            onClick={() => {

              commentContent &&
                createComment(
                  typedComment,
                  taggedUserIds
                ).then(() => {
                  setTypedComment('');
                  refetch();
                  setFocused(false);
                  setTaggedUserIds([]);
                });
            }}
          >
            Send
            <SendIcon sx={{ color: 'white', fontSize: '15px', marginLeft: '3px' }} />
          </CommentButtonContainer>
        </Stack>
      }
    </Container >
  );
};

export default AddUpdate;
