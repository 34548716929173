import { FilterAlt } from '@mui/icons-material';
import { Stack, Typography, styled } from '@mui/material';

import palette from '../../../../theme/palette';

const ActionTitle = styled(Typography)({
  fontFamily: 'Plus Jakarta Sans',
  fontWeight: 600,
  fontSize: '14px',
  color: palette.common.darkBlue,
  letterSpacing: '.5px'
});

const SelectedActionTitle = styled(ActionTitle)({
  color: palette.primary.main,
  paddingLeft: '4px'
});

const FilterAndSortContainer = styled(Stack)(() => ({
  backgroundColor: 'white',
  gap: '5px',
  flexDirection: 'row',
  justifyContent:"space-between"
}));

const ClickAwayContainer = styled(Stack)({
  flexDirection: 'row'
});

const ControlItem = styled(Stack)<{
  selected?: boolean;
  disableBottomBorder?: boolean;
  disableTopBorder?: boolean;
}>(({ theme, selected, disableBottomBorder, disableTopBorder }) => ({
  background: selected ? palette.background.lightGreen : palette.background.default,
  borderTop: `${disableTopBorder ? '0px' : '1px'} solid ${theme.journeyPalette.border.grey}`,
  borderBottom: `${disableBottomBorder ? '0px' : '1px'} solid ${theme.journeyPalette.border.grey}`,
  padding: '10px 20px'
}));

const ActionSection = styled(Stack)(() => ({
  flexDirection: "row",
  justifyContent: "space-between",
  background: palette.background.default
}));

const MonthContainer = styled(ControlItem)({
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: '6px'
});


const ControlButton = styled(Stack)<{
  selected?: boolean;
}>(({ selected }) => ({
  alignItems: 'center',

  cursor: 'pointer',
  gap: '2px',
  backgroundColor: selected && palette.background.lightGreen,
  color: selected ? palette.common.darkBlue : palette.grey[3100],
  borderRadius: "8px",
  padding: "2px 5px",
  svg: { path: { fill: selected ? palette.common.darkBlue : palette.grey[3100] } },
  ":hover": {
    svg: { path: { fill: palette.common.darkBlue } },
    color: palette.common.darkBlue,
    backgroundColor: !selected && palette.background.grey
  }
}));

const Title = styled(Typography)({
  fontFamily: 'Plus Jakarta Sans',
  fontSize: '13px',
  fontWeight: 700
});;


const HorizontalControlButton = styled(ControlButton)({
  flexDirection: 'row'
});

const FilterIconAndCount = styled(Stack)({
  position: 'relative'
});

const FilterCount = styled(Typography)({
  fontFamily: 'Plus Jakarta Sans',
  fontWeight: 600,
  fontSize: '10px',
  color: '#fff'
});

const FilterCountContainer = styled(Stack)({
  position: 'absolute',
  top: '-8px',
  right: '-10px',
  backgroundColor: palette.primary.main,
  borderRadius: '50%',
  height: '18px',
  width: '18px',
  alignItems: 'center',
  justifyContent: 'center',
  letterSpacing: 0
});

const FilterIcon = styled(FilterAlt)({
  fontSize: '24px'
});

const ClearButton = styled(Stack)({
  marginLeft: 4,
  paddingBottom:1,
  svg: { path: { fill: palette.grey[800] } },
  ":hover": { opacity: 0.7 }
});


export {
  ActionTitle,
  FilterAndSortContainer,
  ControlItem,
  ControlButton,
  MonthContainer,
  FilterIcon,
  HorizontalControlButton,
  SelectedActionTitle,
  ClickAwayContainer,
  FilterIconAndCount,
  FilterCount,
  FilterCountContainer,
  ActionSection,
  Title,
  ClearButton
};
