import React, { useState } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Tooltip
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

interface DeleteItemProps {
  title: string;
  id: string;
  doDelete: (id: string) => Promise<void>;
  refetch?: () => void;
  sx?: Record<string, unknown>;
  iconSize?: 'inherit' | 'large' | 'medium' | 'small';
  toolTip?: string;
  loading?: boolean;
  disabled?: boolean;
}

const DeleteItem = ({ title, id, doDelete, refetch, sx, iconSize = 'medium', toolTip, loading = false, disabled }: DeleteItemProps) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setOpen(false);
  };

  const handleDelete = async (e: React.MouseEvent<HTMLButtonElement>) => {
    await doDelete(id);
    refetch && await refetch();
    handleClose(e);
  };

  return (
    <>
      <Tooltip title={toolTip ? toolTip : ""}>
        <IconButton
          disabled={disabled}
          onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            event.stopPropagation();
            handleClickOpen();
          }}
          sx={sx}
        >
          <DeleteIcon fontSize={iconSize} />
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Delete {title}</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to delete this {title}?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            style={{ textDecoration: 'none' }}
            onClick={e => handleClose(e)}
          >
            Cancel
          </Button>
          <LoadingButton
            loading={loading}
            variant="contained"
            style={{ textDecoration: 'none' }}
            onClick={e => handleDelete(e)}
            autoFocus
          >
            Delete
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteItem;
