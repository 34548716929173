import React, { useEffect, useState } from 'react';

import { Avatar, AvatarGroup, Menu, MenuItem, Stack, Tooltip, Typography, alpha, styled } from '@mui/material';

import GroupsIcon from '@mui/icons-material/Groups';
import { TeamDto } from '../../api/generated';
import palette from '../../theme/palette';
import { randomBgColor } from '../../utils/avatarHelper';

import useTeams from '../../hooks/useTeam';
import { useNavigate } from 'react-router-dom';
import { toAccountSettings } from '../../services/linker';


interface TeamsSelect {
  teamIds: string[];
  onChange?: (teamIds: string[]) => void;
  tooltip?: boolean;
  size?: string;
  viewOnly?: boolean;
}


export const TeamName = styled(Typography)<{
  selected?: boolean;
}>(({ selected }) => ({
  fontFamily: 'Plus Jakarta Sans',
  fontWeight: 500,
  fontSize: '12px',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  backgroundColor: selected && palette.green[6]
}));


export const EmptyStateText = styled(Typography)(() => ({
  fontFamily: 'Plus Jakarta Sans',
  fontSize: '13px'
}));







const TeamsSelect = ({
  teamIds,
  onChange,
  tooltip,
  viewOnly,
  size
}: TeamsSelect) => {
  const [selectedTeamIds, setSelectedTeamIds] = useState<string[]>([]);
  const [teams, setTeams] = useState<TeamDto[]>([]);
  const { teams: teamList } = useTeams();
  const navigate = useNavigate();

  useEffect(() => {
    if (teamList) {
      setTeams(teamList.filter(team => selectedTeamIds.includes(team.id)) || []);
    }
  }, [teamList, selectedTeamIds, setTeams]);

  useEffect(() => {
    if (teamIds) {
      setSelectedTeamIds(teamIds);
    }
  }, [teamIds, setSelectedTeamIds]);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    !viewOnly && setAnchorEl(event.currentTarget);
  };

  const handleSelected = (teamId: string) => {
    const newList = selectedTeamIds.includes(teamId)
      ? selectedTeamIds.filter(id => id !== teamId)
      : [...selectedTeamIds, teamId];
    setSelectedTeamIds(newList
    );
    onChange(newList);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };



  return (
    <Stack>
      <AvatarGroup
        sx={{
          '& .MuiAvatar-root': {
            width: size ? size : "21px",
            height: size ? size : "21px"
          }
        }}
        max={3}
        onClick={handleClick}>
        {teams?.length > 0 ?
          teams.map(team =>
            <Tooltip
              title={tooltip && team.name}
              key={team.id}>
              <Avatar

                variant="rounded"
                sx={{
                  cursor: onChange && 'pointer',

                  fontSize: '13px',
                  borderRadius: '100px',
                  height: size || '21px',
                  width: size || '21px',
                  background: randomBgColor(team.id)

                }}
              >
                {team?.name[0]?.toUpperCase()}
              </Avatar>
            </Tooltip>)

          :

          <Tooltip
            title={!viewOnly && "Add teams"}>
            <Avatar
              onClick={handleClick}
              variant="rounded"
              sx={{
                '&.MuiAvatar-root': {
                  border: "none"
                },
                cursor: onChange && 'pointer',
                ':hover': { opacity: viewOnly ? 1 : 0.5 },
                borderRadius: '100px',
                height: size || '20px',
                width: size || '20px',
                background:
                  palette.background.lightGrey
              }}
            ><GroupsIcon sx={{ width: "25px", height: "25px" }} /></Avatar>
          </Tooltip>


        }

      </AvatarGroup>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 25,
          horizontal: 'left'
        }}
        sx={{
          filter: 'drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.13))',
          '& .MuiPaper-root': { borderRadius: '10px' }
        }}
      >
        {teamList?.length > 0 ?
          teamList
            .map(team => (
              <MenuItem
                key={team.id}
                sx={{ backgroundColor: selectedTeamIds.includes(team.id) && alpha(palette.green[6], 0.7), marginBottom: "2px" }}
                onClick={() => handleSelected(team.id)}>
                <TeamName
                >
                  {team.name}
                </TeamName>

              </MenuItem>

            )) :
          <MenuItem>
            <Stack
              direction="row"
              alignItems="center"
              gap="4px">
              <EmptyStateText color={palette.grey[3100]}>No team found.</EmptyStateText>
              <EmptyStateText
                onClick={() => navigate(toAccountSettings() + '?tab=teams')}
                color={palette.primary.main}>Create now</EmptyStateText>

            </Stack>
          </MenuItem>}

      </Menu>
    </Stack>
  );
};

export default TeamsSelect;
