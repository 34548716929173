import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { AttachMoney, Percent, Tag } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { DialogProps, InputAdornment, MenuItem, SelectChangeEvent, Stack, Tooltip, Typography } from '@mui/material';
import { unabbreviateNumber } from 'js-abbreviation-number';
import { enqueueSnackbar } from 'notistack';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  ActionPlanObjectiveDetailedDto,
  ActionPlanObjectiveDtoType,
  BusinessAreaMetadataDto,
  BusinessAreaMetadataDtoType,
  KeyResultUnit,
  MetricDefinitionDto,
  MetricDefinitionDtoDataPointType,
  MetricDefinitionDtoPeriod,
  MetricDefinitionDtoTrendType,
  Target
} from '../../api/generated';
import useBusinessAreaModulesMetadata from '../../hooks/useBusinessAreaModulesMetadata';
import actionPlanTemplates, { ActionPlanTemplate } from '../../mappings/actionPlanTemplates';
import { TOAST_VARIANTS } from '../../components/NotificationToast/NotificationToast';
import { getAbbreviateNumber } from '../../utils/numberUtil';
import { generateEmptySpaces } from '../../utils/textFormater';
import {
  AddTargetsButton,
  AddTargetsButtonText,
  AutoCompleteBlock,
  CancelText,
  CreateButton,
  DescriptionContainer,
  FormInput,
  InputDescription,
  InputTitle,
  KeyResultTypeSelection,
  MetricDropDown,
  MetricDropDownTextInputSelect,
  ModalContainer,
  PeriodDropDown,
  QIcon,
  QIconText,
  QTargetDesc,
  QTargetDescContainer,
  QTargetDescTitle,
  QTargetDescValue,
  Qprogress,
  QprogressTitle,
  RedAsterisk,
  SelectInputContainer,
  TextInputSelect,
  Title,
  TrendDropDown,
  UnitIcon,
  ValueInput,
  VisibleToDropDown
} from './ObjectiveModalStyles';
import useActionPlanBoardContext, { mainBoardID } from '../../hooks/context-providers/useActionPlanBoardContext';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import palette from '../../theme/palette';
import { getQuraterMonthsDesc } from '../../utils/dateUtil';
import useAuth, { AdminRoleId } from '../../hooks/useAuth';

import { UserTypes } from '../../utils/commonTypes';
import CustomSwitch from '../../components/Common/CustomSwitch';
import Editor from '../../screens/BizAreaModules/components/Editor';
import useActionPlanObjective from '../../hooks/useActionPlanObjective';
import UserView from '../../components/User/UserView';
import useMetricDefinition from '../../hooks/useMetricDefinition';
import TeamsSelect from '../../components/Team/TeamsSelect';


interface Qurater {
  name: string;
  value: string;
  placeholder: string;
}

interface ObjectiveModalProps extends DialogProps {
  businessArea?: string;
  onCancel: () => void;
  onConfirm: (id?: string, title?: string) => void;
  title?: string;
  krValue?: number;
  krMetric?: MetricDefinitionDto;
}


export enum Visiblity {
  EVERYONE = 'everyone',
  ADMINS_ONLY = 'adminsOnly'
}


export const keyResultTypes = [
  {
    value: KeyResultUnit.number,
    label: '#',
    icon: <Tag />
  },
  {
    value: KeyResultUnit.currency,
    label: '$',
    icon: <AttachMoney />
  },
  {
    value: KeyResultUnit.percentage,
    label: '%',
    icon: <Percent />
  }
] as const;

const ObjectiveModal: FC<ObjectiveModalProps> = ({
  businessArea,
  onCancel,
  onConfirm,
  krValue,
  krMetric,
  title,
  ...props
}: ObjectiveModalProps) => {
  const [krUpdated, setKrUpdated] = useState<boolean>(false);
  const { metrics } = useMetricDefinition(null, true);

  const updateKeyResult = (value: string) => {
    setKeyResultValue(value);
    const newQuarters = [...quarters];
    newQuarters[3].value = value;
    setQuarters(newQuarters);
    setKrUpdated(true);
  }

  const { businessAreasMetadata } = useBusinessAreaModulesMetadata();
  const [currentBusinessArea, setCurrentBusinessArea] = useState<BusinessAreaMetadataDto>(null);
  useEffect(() => {
    if (businessArea) {
      setCurrentBusinessArea(businessAreasMetadata.find(ba => ba.id === businessArea) || {
        name: '',
        id: ''
      });
    }
  }, [businessAreasMetadata, businessArea]);


  const template: ActionPlanTemplate = actionPlanTemplates.find(
    temp => temp.businessArea === currentBusinessArea?.id
  );

  const [keyResultType, setKeyResultType] = useState<KeyResultUnit>(
    template ? template.krUnit : KeyResultUnit.number
  );

  const [period, setPeriod] = useState<MetricDefinitionDtoPeriod>(
    MetricDefinitionDtoPeriod.monthly
  );
  const [trend, setTrend] = useState<MetricDefinitionDtoTrendType>(
    MetricDefinitionDtoTrendType.more
  );
  const [dataPointType, setDataPointType] = useState<MetricDefinitionDtoDataPointType>(MetricDefinitionDtoDataPointType.independent);

  const [teamIds, setTeamIds] = useState<string[]>([]);


  const [showTargets, setShowTargets] = useState<boolean>(false);
  const defaultPlcaeholder = keyResultType === 'currency'
    ? '300k / 2.5m / 10000'
    : 'Insert value';

  const [quarters, setQuarters] = useState<Qurater[]>([
    {
      name: "Q1",
      value: '',
      placeholder: defaultPlcaeholder
    },
    {
      name: "Q2",
      value: '',
      placeholder: defaultPlcaeholder
    },
    {
      name: "Q3",
      value: '',
      placeholder: defaultPlcaeholder
    }, {
      name: "Q4",
      value: '',
      placeholder: defaultPlcaeholder
    }
  ]);

  const onQValueChanged = (index: number, value: string) => {
    const newQuarters = [...quarters];
    newQuarters[index].value = value;
    setQuarters(newQuarters);
  }




  const [objectiveTitle, setObjectiveTitle] = useState(title || '');
  const [description, setDescription] = useState('');
  const [keyResultDescription, setkeyResultDescription] = useState('');
  const { createActionPlanObjective, refetchDetailedObjectives } =
    useActionPlanObjective();
  const [startingPointValue, setStartingPointValue] = useState('');
  const [keyResultValue, setKeyResultValue] = useState(krValue ? krValue : '');
  const [visibility, setVisibility] = useState<Visiblity>(Visiblity.EVERYONE);
  const isValidObjective = !!objectiveTitle && !!keyResultValue && !!keyResultDescription;
  const { selectedBoard } = useActionPlanBoardContext();
  const { currentAccount, user } = useAuth();
  const monthToStart = currentAccount?.config?.fiscalYearStartMonth || 1;
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [ownerId, setOwnerId] = useState<string>(user?.id);

  const [chosenMetric, setChosenMetric] = useState<MetricDefinitionDto | string | null>(krMetric ? krMetric : null);
  const [showTrendOptions, setShowTrendOptions] = useState<boolean>(false);

  useEffect(() => {
    if (chosenMetric !== null) {
      if (typeof chosenMetric === 'string') {
        setkeyResultDescription(chosenMetric);
        setShowTrendOptions(true);
      } else {
        setkeyResultDescription(chosenMetric.name);
        setShowTrendOptions(false);
      }
    } else {
      setkeyResultDescription('');
    }

  }, [chosenMetric, setkeyResultDescription]);

  const constructedObjective: Partial<ActionPlanObjectiveDetailedDto> = useMemo(() => {
    try {

      const startingPoint =
        unabbreviateNumber(startingPointValue ? startingPointValue : '0', ['', 'k', 'm', 'b']) || 0;
      const ketResult =
        unabbreviateNumber(keyResultValue ? keyResultValue : '0', ['', 'k', 'm', 'b']) || 0;


      const targets: Target[] = [];
      quarters.map((q, index) => {
        if (q.value !== '') {
          targets.push({ period: index + 1, value: unabbreviateNumber(q.value, ['', 'k', 'm', 'b']) });
        }
      })

      return {
        type: ActionPlanObjectiveDtoType.businessArea,
        businessArea: currentBusinessArea.id,
        title: objectiveTitle,
        description: description,
        boardId: selectedBoard.id !== mainBoardID ? selectedBoard.id : null,
        ownerId: ownerId ? ownerId : null,
        teamIds: teamIds,
        keyResult: {
          unit: keyResultType,
          value: ketResult,
          subject: keyResultDescription,
          startingPoint: startingPoint,
          targets: targets,
          metricId: typeof chosenMetric !== "string" ? chosenMetric.id : null,
          period: period,
          trendType: trend,
          dataPointType: dataPointType

        },
        adminOnly: visibility === Visiblity.ADMINS_ONLY ? true : false
      };
    } catch (error) {
      null;
    }
  }, [
    description,
    currentBusinessArea,
    objectiveTitle,
    keyResultDescription,
    keyResultType,
    keyResultValue,
    chosenMetric,
    startingPointValue,
    selectedBoard,
    quarters,
    ownerId,
    teamIds,
    dataPointType,
    period,
    trend,
    visibility
  ]);

  const createObjective = useCallback(() => {
    isValidObjective &&
      createActionPlanObjective(constructedObjective)
        .then(created => {
          onConfirm(created?.id, created?.title);
          refetchDetailedObjectives();
          enqueueSnackbar({
            variant: 'custom',
            customProps: {
              caption: 'Objective Added Successfully',
              variant: TOAST_VARIANTS.SUCCESS
            }
          });
        })
        .catch(() => {
          enqueueSnackbar({
            variant: 'custom',
            customProps: {
              caption: 'Error Adding Objective',
              variant: TOAST_VARIANTS.ERROR
            }
          });
        });

  }, [
    constructedObjective,
    onConfirm,
    refetchDetailedObjectives,
    isValidObjective,
    createActionPlanObjective
  ]);


  useEffect(() => {
    try {

      if (krUpdated || startingPointValue) {
        const ketResult =
          unabbreviateNumber(keyResultValue ? keyResultValue : '0', ['', 'k', 'm', 'b']) || 0;
        const startingPoint =
          unabbreviateNumber(startingPointValue ? startingPointValue : '0', ['', 'k', 'm', 'b']) || 0;

        const range = ketResult - startingPoint;


        let step = range / 4;

        if (keyResultType === KeyResultUnit.number) {
          step = Math.round(step);
        }

        const q1 = startingPoint + step;
        const q2 = q1 + step;
        const q3 = q2 + step;

        const newQuarters = [...quarters];
        newQuarters[0].placeholder = getAbbreviateNumber(q1, 2);
        newQuarters[1].placeholder = getAbbreviateNumber(q2, 2);
        newQuarters[2].placeholder = getAbbreviateNumber(q3, 2);
        newQuarters[3].placeholder = getAbbreviateNumber(ketResult, 2);
        setQuarters(newQuarters);
        setKrUpdated(false);
      }

    }
    catch (error) {
      null;
    }
  }, [krUpdated, startingPointValue, keyResultValue, quarters, keyResultType]);


  const getQTargetDescriptionValue = (index: number) => {
    let retVal = 0;
    try {

      if (quarters[index].value) {
        const qValue =
          unabbreviateNumber(quarters[index].value, ['', 'k', 'm', 'b']) || 0;


        const startingPoint =
          unabbreviateNumber(startingPointValue ? startingPointValue : '0', ['', 'k', 'm', 'b']) || 0;
        const ketResult =
          unabbreviateNumber(keyResultValue ? keyResultValue : '0', ['', 'k', 'm', 'b']) || 0;

        if (index === 0) {
          retVal = qValue - startingPoint;
        } else {

          let previousQValue = startingPointValue ? startingPointValue : '0';
          for (let i = index - 1; i >= 0; i--) {
            if (quarters[i].value) {
              previousQValue = quarters[i].value;
              break;
            }
          }

          retVal = qValue - unabbreviateNumber(previousQValue, ['', 'k', 'm', 'b']);
        }

        if (startingPoint < ketResult && retVal < 0) {
          retVal = 0;
        }




      }




      return retVal;

    }
    catch (error) {
      null;
    }

  }

  const handleBaSelected = (event: React.SyntheticEvent, newValue: BusinessAreaMetadataDto | null) => {
    setCurrentBusinessArea(newValue);
  };




  return (
    <ModalContainer maxWidth="lg" aria-labelledby="add-objective" {...props}>
      <Stack direction="row" justifyContent="space-between">
        <Title>{`Create new ${currentBusinessArea?.name || ''} objective`}</Title>
        <CloseIcon
          sx={{ cursor: 'pointer', ':hover': { transform: 'scale:1.5' } }}
          onClick={() => onCancel()}
        />
      </Stack>
      <Stack
        gap={2}
        marginTop={1}>

        {!businessArea &&
          <Stack
            marginBottom={1}>
            <Stack direction="row">
              <InputTitle>Business Area</InputTitle>
              <RedAsterisk>{generateEmptySpaces(1)}*</RedAsterisk>
            </Stack>

            <AutoCompleteBlock
              height="40px"
              disableClearable
              options={businessAreasMetadata
                .filter(
                  ba => ba.actionPlanDefaultIndex || ba.type === BusinessAreaMetadataDtoType.custom
                )}
              getOptionLabel={(option: BusinessAreaMetadataDto) => option?.name || ''}
              onChange={handleBaSelected}
              value={currentBusinessArea}
              renderInput={params => <TextInputSelect {...params} placeholder='Choose Business Area' />}
            />
          </Stack>}

        <Stack
          direction="row"
          alignItems="start"
          justifyContent="space-between">
          <Stack
            width="100%">
            <Stack direction="row">
              <InputTitle>Objective</InputTitle>
              <RedAsterisk>{generateEmptySpaces(1)}*</RedAsterisk>
            </Stack>

            <ValueInput
              fullWidth
              value={objectiveTitle}
              onChange={event => setObjectiveTitle(event.currentTarget.value)}
              placeholder={template ? 'e.g. ' + template.objective : 'Type Objective'}
            />
          </Stack>





        </Stack>
        <Stack
          width="100%">
          <InputTitle>Description</InputTitle>
          <DescriptionContainer>
            <Editor
              fontSize='14px'
              showBorder={false}
              defaultValue={description}
              value={description}
              onChange={value => setDescription(value)}
            />
          </DescriptionContainer>
        </Stack>

        <Stack
          justifyContent="space-between"
          width="100%"
          direction="row"
          alignItems="start"
          gap={2}>
          <Stack
            gap="4px"

          >
            <InputTitle>Owner</InputTitle>
            <UserView
              onlyAvatar
              size='40px'
              tooltip="Owner"
              userId={ownerId}
              onChange={(userId: string) => setOwnerId(userId)}
            />
          </Stack>

          <Stack

          >
            <InputTitle>Teams</InputTitle>
            <TeamsSelect
              size='40px'
              tooltip
              teamIds={teamIds}
              onChange={setTeamIds} />
          </Stack>

          {(user.type !== UserTypes.USER || user.roleId === AdminRoleId) &&
            <Stack
              width="30%">
              <InputTitle>Visible to</InputTitle>
              <VisibleToDropDown
                value={visibility}
                onChange={event => setVisibility(event.target.value as Visiblity)}
              >
                <MenuItem value={Visiblity.EVERYONE}>
                  <Typography variant="caption">Everyone</Typography>
                </MenuItem>
                <MenuItem value={Visiblity.ADMINS_ONLY}>
                  <Typography variant="caption">Admins only</Typography>
                </MenuItem>

              </VisibleToDropDown>
            </Stack>}

        </Stack>


        <Stack

          direction="row"
          width="100%"
          gap={2}
          justifyContent="space-between"
        >
          <Stack
            width="75%">

            <Stack
              direction="row"
              alignItems="center">
              <InputTitle>Key result (Yearly)</InputTitle>

              <RedAsterisk>{generateEmptySpaces(1)}*</RedAsterisk>
            </Stack>
            <InputDescription>Choose an existing metric for this KR or name a new metric</InputDescription>

            <Stack direction="row" >
              <Stack>
                <SelectInputContainer >
                  <FormInput
                    sx={{
                      borderRadius: '8px 0px 0px 8px'
                    }}
                    value={keyResultValue}
                    disableUnderline
                    placeholder={
                      template
                        ? template.krValue
                        : keyResultType === 'currency'
                          ? '300k / 2.5m / 10000'
                          : 'Insert value'
                    }
                    onChange={e => updateKeyResult(e.target.value.replace(/[^kmb.\d-]/g, ''))}
                    startAdornment={
                      <KeyResultTypeSelection
                        id="outlined-select-keyresult"
                        value={keyResultType}
                        onChange={(event: SelectChangeEvent<KeyResultUnit>) =>
                          setKeyResultType(event.target.value as KeyResultUnit)
                        }
                      >
                        {keyResultTypes.map(keyResultType => (
                          <MenuItem key={keyResultType.value} value={keyResultType.value}>
                            <UnitIcon> {keyResultType.icon}</UnitIcon>
                          </MenuItem>
                        ))}
                      </KeyResultTypeSelection>
                    }
                  />
                </SelectInputContainer>

                <AddTargetsButton
                  sx={{
                    borderRadius: !showTargets && "0px 0px 8px 8px",
                    padding: showTargets ? "5px 2px" : "2px 10px"
                  }}
                  onClick={() => setShowTargets(!showTargets)}>
                  <AddTargetsButtonText>{showTargets ? 'Quarterly' : 'Add quarterly'} key results</AddTargetsButtonText>

                  {showTargets ? <KeyboardArrowUpIcon fontSize='small' /> : <KeyboardArrowDownIcon fontSize='small' />}

                </AddTargetsButton>
                {showTargets && quarters.map((q, index) =>
                  <Stack
                    key={"q-target-" + index}
                    direction="row"
                    alignItems="center"
                    marginBottom="5px">
                    <FormInput
                      value={q.value}
                      placeholder={q.placeholder}
                      disableUnderline
                      onChange={e => onQValueChanged(index, e.target.value.replace(/[^kmb.\d-]/g, ''))}
                      startAdornment={
                        <InputAdornment
                          sx={{ marginRight: "10px", marginLeft: "5px" }}
                          position="start">
                          <QIcon>
                            <QIconText>
                              {q.name}
                            </QIconText>
                          </QIcon>
                        </InputAdornment>
                      }
                    />
                  </Stack>)}


              </Stack>

              <Stack width="100%">

                <MetricDropDown
                  noOptionsText="No available metrics"
                  options={metrics}
                  freeSolo
                  getOptionLabel={(option: MetricDefinitionDto | string) => typeof option === "string" ? option : option.name}
                  renderOption={(props: React.HTMLAttributes<HTMLLIElement>,
                    option: MetricDefinitionDto | string) =>
                  (
                    <li {...props} key={typeof option === "string" ? crypto.randomUUID() : option.id}>
                      {typeof option === "string" ? option : option.name}
                    </li>
                  )
                  }
                  onInputChange={(event: React.SyntheticEvent,
                    value: string) => setChosenMetric(value)}
                  onChange={(event: React.SyntheticEvent, newValue: MetricDefinitionDto | string | null) => setChosenMetric(newValue)}
                  value={chosenMetric}
                  renderInput={params =>
                    <MetricDropDownTextInputSelect {...params} placeholder="Choose metric / write new metric name" />}
                />

                {showTargets &&
                  <Stack
                    marginLeft={1}>
                    <Qprogress>
                      <Stack
                        direction="row"
                        alignItems="end">
                        <TrendingUpIcon sx={{ color: palette.primary.main, width: "20px", height: "18px" }} />
                        <QprogressTitle>Show quarterly required progress</QprogressTitle>
                      </Stack>
                      <CustomSwitch
                        sx={{ height: "18px", margin: 0 }}
                        toggleColor={palette.green[1]}
                        checked={showProgress}
                        onChange={() => setShowProgress(prev => !prev)}
                      />
                    </Qprogress>

                    {showProgress && quarters.map((q, index) =>
                      <QTargetDesc
                        key={"q-progress-" + index}
                      >
                        <QTargetDescContainer>
                          <QTargetDescTitle>{getQuraterMonthsDesc(index, monthToStart)}</QTargetDescTitle>
                          <QTargetDescValue>{getAbbreviateNumber(getQTargetDescriptionValue(index), 2)}</QTargetDescValue>
                        </QTargetDescContainer>
                      </QTargetDesc>
                    )}

                  </Stack>}
              </Stack>

            </Stack>
          </Stack>

          <Stack
            marginTop="25px"
            width="30%"
          >
            <InputTitle>Baseline</InputTitle>
            <FormInput
              value={startingPointValue}
              disableUnderline
              placeholder={
                template
                  ? template.krStartingPoint
                  : keyResultType === 'currency'
                    ? '300k / 2.5m / 100'
                    : 'Insert value'
              }
              onChange={e => setStartingPointValue(e.target.value.replace(/[^kmb.\d-]/g, ''))}
              startAdornment={
                <InputAdornment position="start">
                  <UnitIcon>
                    {
                      Object.values(keyResultTypes).find(type => type.value === keyResultType)
                        .icon
                    }
                  </UnitIcon>
                </InputAdornment>
              }
            />
          </Stack>

        </Stack>

        {chosenMetric && showTrendOptions &&
          <Stack
            gap={1}
            marginTop={2}>
            <InputDescription>Progress settings for new metric</InputDescription>
            <Stack direction="row" alignItems="start" justifyContent="space-between">


              <Stack >
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between">
                  <InputTitle>Trend Period</InputTitle>
                  <Tooltip
                    title="Progress will be calculated for the chosen period">


                    <InfoOutlinedIcon style={{ color: palette.common.darkBlue, opacity: 0.6, width: "18px", height: "18px" }} />
                  </Tooltip>
                </Stack>

                <PeriodDropDown
                  value={period}
                  onChange={event => setPeriod(event.target.value as MetricDefinitionDtoPeriod)}
                >
                  <MenuItem value={MetricDefinitionDtoPeriod.monthly}>
                    <Typography variant="caption">Monthly</Typography>
                  </MenuItem>
                  <MenuItem value={MetricDefinitionDtoPeriod.quarterly}>
                    <Typography variant="caption">Quarterly</Typography>
                  </MenuItem>
                  <MenuItem value={MetricDefinitionDtoPeriod.semiannually}>
                    <Typography variant="caption">Semiannually</Typography>
                  </MenuItem>
                  <MenuItem value={MetricDefinitionDtoPeriod.annually}>
                    <Typography variant="caption">Annually</Typography>
                  </MenuItem>
                </PeriodDropDown>
              </Stack>

              <Stack >
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between">
                  <InputTitle>Trend Target</InputTitle>
                  <Tooltip
                    title="Determine if the progress trend should be higer or lower">


                    <InfoOutlinedIcon style={{ color: palette.common.darkBlue, opacity: 0.6, width: "18px", height: "18px" }} />
                  </Tooltip>
                </Stack>
                <TrendDropDown
                  value={trend}
                  onChange={(e: SelectChangeEvent) =>
                    setTrend(e.target.value as MetricDefinitionDtoTrendType)
                  }
                >

                  <MenuItem value={MetricDefinitionDtoTrendType.more}>
                    <Typography variant="caption"> Higher is better</Typography>
                  </MenuItem>
                  <MenuItem value={MetricDefinitionDtoTrendType.less}>
                    <Typography variant="caption">Lower is better</Typography>
                  </MenuItem>

                </TrendDropDown>
              </Stack>

              <Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between">
                  <InputTitle>Trend Type</InputTitle>
                  <Tooltip
                    title={
                      <> <div >Cumulative - the metric data points are cumulative YTD</div><div>Periodic - the metric data points are not cumulative</div></>}

                  >
                    <InfoOutlinedIcon style={{ color: palette.common.darkBlue, opacity: 0.6, width: "18px", height: "18px" }} />
                  </Tooltip>
                </Stack>
                <TrendDropDown
                  value={dataPointType}
                  onChange={(e: SelectChangeEvent) =>
                    setDataPointType(e.target.value as MetricDefinitionDtoDataPointType)
                  }
                >

                  <MenuItem value={MetricDefinitionDtoDataPointType.accumulated}>
                    <Typography variant="caption">Cumulative</Typography>
                  </MenuItem>

                  <MenuItem value={MetricDefinitionDtoDataPointType.independent}>
                    <Typography variant="caption">Periodic</Typography>
                  </MenuItem>

                </TrendDropDown>

              </Stack>
            </Stack>

          </Stack>
        }


      </Stack>


      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <CancelText
          onClick={() => {
            onCancel();
          }}
        >
          Cancel
        </CancelText>
        <CreateButton disabled={!isValidObjective} onClick={createObjective}>
          Create
        </CreateButton>
      </Stack>
    </ModalContainer>
  );
};

export default ObjectiveModal;
