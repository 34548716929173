import { ArrowCircleDown, SyncAlt } from '@mui/icons-material';
import {
  Button,
  Chip,
  FormControl,
  FormControlLabel,
  Popover,
  Select,
  Stack,
  Switch,
  Typography,
  alpha,
  styled
} from '@mui/material';
import palette from '../../../../theme/palette';
import { Close } from '@mui/icons-material';


const HeaderBlockPage = styled(Stack)(() => ({
  flexDirection: 'row'

}));

const IconContainer = styled(Stack)(() => ({
}));

export const WhiteboardContainer = styled(Stack)(() => ({
  border: `1px solid ${palette.border.grey}`,
  backgroundColor: palette.secondary.main,
  borderRadius: '16px',
  padding: '10px 0px 15px 15px'

}));


export const DriveContainer = styled(Stack)(() => ({
  border: `1px solid ${palette.border.grey}`,
  backgroundColor: palette.secondary.main,
  borderRadius: '16px',
  padding: '10px'
}));

const HeaderDropDownBlock = styled(Stack)(() => ({
  flexDirection: 'row',
  alignItems: 'center',
  gap: '10px'
}));

const HeaderDropDownMenu = styled(FormControl)(() => ({ width: '234px' }));

const DropDownSelect = styled(Select)(({ theme }) => ({
  height: '40px',
  width: '200px',
  backgroundColor: theme.journeyPalette.background.default,
  borderRadius: '10px',
  border: `1px solid ${palette.border.grey}`,
  '& .MuiSelect-select': {
    backgroundColor: theme.journeyPalette.background.default
  },
  '.MuiOutlinedInput-notchedOutline': { border: 0, padding: 0 }
}));

const HeaderFilterBlock = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  gap: '30px',
  color: theme.journeyPalette.secondary.main,
  svg: {
    path: {
      fill: alpha(theme.journeyPalette.secondary.main, 0.7)
    }
  }
}));

const FilterTitle = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.body2.fontSize
}));

const HeaderFilter = styled(Stack)<{ openFilter?: boolean }>(({ theme, openFilter }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  gap: '5px',
  cursor: 'pointer',
  color: openFilter && theme.journeyPalette.primary.main,
  svg: openFilter && {
    path: {
      fill: theme.journeyPalette.primary.main
    }
  },
  '&:hover': {
    color: theme.journeyPalette.primary.main,
    svg: {
      path: {
        fill: theme.journeyPalette.primary.main
      }
    }
  }
}));

const SyncAltIcon = styled(SyncAlt)(() => ({
  transform: 'rotate(90deg)'
}));

const ArrowCircleDownIcon = styled(ArrowCircleDown)<{ openFilter?: boolean }>(({ openFilter }) => ({
  transform: openFilter && 'rotate(180deg)'
}));

const PopupFilter = styled(Popover)(({ theme }) => ({
  marginTop: '10px',
  '& .MuiPopover-paper': {
    width: '246px',
    borderRadius: '8px',
    boxShadow: `0px 10px 20px ${alpha(theme.journeyPalette.blue[6], 0.03)}`
  }
}));

const SortButton = styled(Button)(({ theme }) => ({
  width: '100%',
  justifyContent: 'left',
  gap: '3px',
  color: theme.journeyPalette.common.darkBlue,
  fontSize: '12px',
  padding: '18px',
  borderRadius: '0',
  div: { color: theme.journeyPalette.grey[1100] },
  '&:not(:last-of-type)': {
    borderBottom: `1px solid ${theme.journeyPalette.border.grey}`
  },
  '&:hover': {
    div: { color: theme.journeyPalette.primary.main },
    textDecoration: 'none',
    color: theme.journeyPalette.primary.main,
    backgroundColor: theme.journeyPalette.background.lightGreen
  }
}));

const FilterItem = styled(Stack)(({ theme }) => ({
  padding: '9px 18px',
  borderTop: `1px solid ${theme.journeyPalette.border.grey}`,
  gap: '20px',
  fontSize: theme.typography.body2.fontSize
}));

const ButtonContainer = styled(Stack)(() => ({
  flexDirection: 'row',
  justifyContent: 'space-between'
}));
const FormCheckbox = styled(FormControlLabel)(({ theme }) => ({
  margin: '0',
  span: {
    padding: '0',
    fontSize: theme.typography.body2.fontSize
  }
}));

const FilterButton = styled(Button)(() => ({
  minWidth: '0',
  width: '50px'
}));

const DropDownYearFilter = styled(Select)(({ theme }) => ({
  backgroundColor: 'transparent',
  padding: '4px 10px',
  fontSize: '13px',
  width: '100%',
  border: `1px solid ${theme.journeyPalette.border.grey}`,
  borderRadius: '8px',
  '& .MuiSelect-select': {
    padding: '5px',
    backgroundColor: `${alpha(theme.journeyPalette.secondary.main, 0.05)} !important`
  }
}));

const Content = styled(Stack)(() => ({
  flexDirection: 'row'
}));


const ActionPlanSection = styled(Stack)<{ empty?: boolean }>(({ empty }) => ({
  alignItems: empty ? "center" : "start",
  borderRadius: '16px',
  paddingTop: '10px',
  border: `1px solid ${palette.border.grey}`,
  paddingRight: empty ? "0px" : "26px",
  paddingLeft: "10px",
  gap: "20px",
  height: 'auto',
  backgroundColor: palette.secondary.main
}));


export const SectionTitle = styled(Typography)(() => ({
  fontFamily: 'Plus Jakarta Sans',
  fontSize: '15px',
  fontWeight: 700,
  marginBottom:"5px"
}));

const TotalRatingText = styled(Typography)(() => ({
  fontFamily: 'Plus Jakarta Sans',
  fontSize: '15px',
  fontWeight: 500
}));

const TotalRatingScore = styled(Typography)(() => ({
  fontFamily: 'Plus Jakarta Sans',
  fontSize: '15px',
  fontWeight: 700
}));

const AnalysisSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-track': {
    backgroundColor: theme.journeyPalette.background.grey
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 16,
    height: 16,
    margin: 2
  }
}));

const NoticeText = styled(Stack)(() => ({
  flexDirection: "row",
  alignItems: "center",
  fontSize: '13px',
  fontFamily: "'Plus Jakarta Sans'",
  fontWeight: 500
}));

const Property = styled(Chip)(() => ({
  marginLeft: "2px",
  height: "20px",
  color: "white",
  fontSize: '15px',
  borderRadius: "5px",
  paddingTop: 3,
  paddingBottom: 3,
  backgroundColor: palette.blue[11]
}));

const NoticeTextBold = styled(Typography)(() => ({
  marginLeft: "2px",
  marginRight: "2px",
  fontFamily: "'Plus Jakarta Sans'",
  fontSize: '13px',
  fontWeight: 900
}));




const CloseIcon = styled(Close)(() => ({
  cursor: 'pointer',
  width: "18px",
  height: "18px",
  zIndex: 2,
  ':hover': { backgroundColor: palette.background.grey },
  borderRadius: '6px'
}));

const Notice = styled(Stack)(() => ({
  padding: 6,
  paddingLeft: 8,
  borderRadius: "10px",
  backgroundColor: palette.blue[8]
}));

const NoticeSubTitle = styled(Stack)(() => ({
  marginTop: 1,
  flexDirection: "row",
  fontFamily: "'Plus Jakarta Sans'",
  gap: 2,
  fontSize: "12px",
  color: palette.grey[1100]
}));

export {
  HeaderBlockPage,
  HeaderDropDownBlock,
  HeaderFilterBlock,
  HeaderDropDownMenu,
  DropDownSelect,
  HeaderFilter,
  ActionPlanSection,
  Content,
  FilterTitle,
  PopupFilter,
  SortButton,
  SyncAltIcon,
  ArrowCircleDownIcon,
  FilterItem,
  FormCheckbox,
  ButtonContainer,
  DropDownYearFilter,
  FilterButton,
  AnalysisSwitch,
  IconContainer,
  Notice,
  NoticeSubTitle,
  NoticeText,
  NoticeTextBold,
  Property,
  CloseIcon,
  TotalRatingText,
  TotalRatingScore
};
