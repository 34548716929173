import React from 'react';
import { useLocation } from 'react-router-dom';

import { Avatar, AvatarGroup, Box, Stack, Typography } from '@mui/material';

import { UserDetailsType, UserFeatureFeature } from '../../../api/generated';
import useAuth from '../../../hooks/useAuth';
import useUserFeature from '../../../hooks/useUserFeature';
import { toAccountSettings, toChooseAccount, toIntegrations, toUserProfile } from '../../../services/linker';
import palette from '../../../theme/palette';
import Menu, { MenuItem, MenuArrowPosition } from '../../Menu/Menu';
import UserMenuButton from './UserMenuButton';

const AVATAR_WIDTH = 50;
const AVATAR_HEIGHT = 50;

const UserMenu = () => {
  const { user, signOut, currentAccount } = useAuth();
  const { pathname } = useLocation();
  const { isFeatureBlocked } = useUserFeature();

  const menuItems = [
    {
      value: 'My Profile',
      to: toUserProfile()
    },
    {
      value: 'Switch CEO Account',
      isVisible:
        pathname !== toChooseAccount() &&
        [UserDetailsType.jp, UserDetailsType.admin, UserDetailsType.superAdmin].includes(
          user?.type
        ),
      isDisabled: !user?.accounts.length,
      to: toChooseAccount()
    },
    {
      value: 'Account Settings',
      isVisible: !isFeatureBlocked(UserFeatureFeature.settings) && user.type !== UserDetailsType.jp,
      to: toAccountSettings()
    },
    {
      value: 'Integrations',
      isVisible: !isFeatureBlocked(UserFeatureFeature.settings) && user.type !== UserDetailsType.jp,
      to: toIntegrations()
    },
    {
      value: 'Sign Out',
      onClick: signOut
    }
  ];



  return (
    <Box display="flex" alignItems="center">
      <Menu
        Component={props => (
          <UserMenuButton
            avatars={user?.imageUrl ? [user?.imageUrl] : []}
            name={`${user?.firstName} ${user?.lastName}`}
            industry={currentAccount?.industry || ''}
            {...props}
          />
        )}
        MenuListProps={{
          'aria-labelledby': 'user-dropdown',
          sx: {
            paddingTop: 3,
            p: 1
          }
        }}
        arrowPosition={MenuArrowPosition.RIGHT}
      >
        <MenuItem
          disabled
          value=""
          sx={{
            borderBottom: `1px solid ${palette.border.grey}`,
            display: 'flex',
            alignItems: 'center',
            '&.Mui-disabled': { opacity: 1 },
            pb: 2,
            marginBottom: 1
          }}
        >
          {user?.imageUrl && (
            <AvatarGroup
              sx={{
                mr: 1.5,
                width: AVATAR_WIDTH,
                height: AVATAR_HEIGHT,
                '& .MuiAvatar-root': {
                  border: `1px solid ${palette.border.grey}`,
                  boxSizing: 'border-box'
                }
              }}
            >
              <Avatar
                sx={{ width: AVATAR_WIDTH, height: AVATAR_HEIGHT }}
                alt={`${user?.firstName} ${user?.lastName}`}
                src={user?.imageUrl || ''}
              />
            </AvatarGroup>
          )}
          <Stack>
            <Typography
              sx={{
                fontFamily: 'Plus Jakarta Sans',
                color: palette.common.darkBlue,
                fontSize: '15px',
                fontWeight: 700
              }}
            >{`${user?.firstName} ${user?.lastName}`}</Typography>
            <Typography
              variant="caption"
              sx={{
                fontFamily: 'Plus Jakarta Sans',
                color: palette.grey[700]
              }}
            >
              {user?.email}
            </Typography>
          </Stack>
        </MenuItem>
        {menuItems.map(
          ({ value, onClick, to, isVisible = true, isDisabled = false }) =>
            isVisible && (
              <MenuItem
                key={value}
                sx={{
                  pt: 1,
                  paddingRight: 0,
                  width: '100%',
                  '&:hover': { backgroundColor: 'grey.400' },
                  '&.Mui-selected': {
                    backgroundColor: 'grey.400',
                    '&:hover': { backgroundColor: 'grey.400' }
                  },
                  color: 'grey.700'
                }}
                value={value}
                disabled={isDisabled}
                onClick={onClick}
                to={to}
              >
                {value}
              </MenuItem>
            )
        )}
      </Menu>
    </Box>
  );
};
export default UserMenu;
