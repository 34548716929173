import React from 'react';
import {
  ActionPlanActionItemDtoSprint,
  ActionPlanActionItemDtoStatus,
  ActionPlanInitiativeDtoPriority,
  ActionPlanInitiativeDtoStatus,
  KeyResultUnit,
  Timeframe
} from '../api/generated';
import palette from '../theme/palette';
import { months } from './filesUtil';

import { AttachMoney, Percent, Tag } from '@mui/icons-material';
import { generateEmptySpaces } from './textFormater';
import { getAbbreviateNumber } from './numberUtil';

export const initiativesPriorityColorsMap = {
  [ActionPlanInitiativeDtoPriority.L]: {
    backgroundColor: palette.green[6],
    checkedColor: palette.green[7],
    label: 'Low'
  },
  [ActionPlanInitiativeDtoPriority.M]: {
    backgroundColor: palette.yellow[2],
    checkedColor: palette.yellow[3],
    label: 'Medium'
  },

  [ActionPlanInitiativeDtoPriority.H]: {
    backgroundColor: palette.red[3],
    checkedColor: palette.red[4],
    label: 'High'
  }
};

export const initiativesPriorityComparison = {
  [ActionPlanInitiativeDtoPriority.L]: 1,
  [ActionPlanInitiativeDtoPriority.M]: 2,
  [ActionPlanInitiativeDtoPriority.H]: 3
};

export const initiativesStatusMetaDataMap = {
  [ActionPlanInitiativeDtoStatus.failed]: {
    circleColor: palette.red[4],
    label: 'Failed'
  },
  [ActionPlanInitiativeDtoStatus.onTrack]: {
    circleColor: palette.purple[2],
    label: 'On track'
  },
  [ActionPlanInitiativeDtoStatus.deferred]: {
    circleColor: palette.grey[1100],
    label: 'Deferred'
  },
  [ActionPlanInitiativeDtoStatus.atRisk]: {
    circleColor: palette.red[4],
    label: 'At risk'
  },
  [ActionPlanInitiativeDtoStatus.completed]: {
    circleColor: palette.green[1],
    label: 'Completed'
  }
};

export const actionItemSprintMap = {
  [ActionPlanActionItemDtoSprint.backlog]: {
    label: 'Backlog'
  },
  [ActionPlanActionItemDtoSprint.current]: {
    label: 'Current sprint'
  }
};

export const actionItemStatusMap = {
  [ActionPlanActionItemDtoStatus.pending]: {
    label: 'To do'
  },
  [ActionPlanActionItemDtoStatus.inProgress]: {
    label: 'In progress'
  },
  [ActionPlanActionItemDtoStatus.completed]: {
    label: 'Completed'
  }
};

export const getDueDateRange = (timeframe: Timeframe) => {
  const res = timeframe.month + timeframe.durationInMonths;
  if (res <= 12) {
    return `${months[timeframe.month]}-${months[res]} ${timeframe.year % 100}`;
  } else {
    return `${months[timeframe.month]} ${timeframe.year % 100}-${months[res - 12]}
    ${(timeframe.year + 1) % 100}`;
  }
};

export const keyResultTypes = [
  {
    value: KeyResultUnit.number,
    label: '#',
    icon: <Tag />
  },
  {
    value: KeyResultUnit.currency,
    label: '$',
    icon: <AttachMoney />
  },
  {
    value: KeyResultUnit.percentage,
    label: '%',
    icon: <Percent />
  }
] as const;

export const formatKeyResult = (unit: KeyResultUnit, value: number, subject: string) => {

  if (unit === KeyResultUnit.percentage) {
    return +
      getAbbreviateNumber(value, 2) +
      keyResultTypes.find(
        keyResultType => keyResultType.value === unit
      )?.label +
      generateEmptySpaces(1) +
      subject;
  } else {
    return keyResultTypes.find(
      keyResultType => keyResultType.value === unit
    )?.label +
      getAbbreviateNumber(value, 2) +
      generateEmptySpaces(1) +
      subject;
  }

};

export const formatValue = (unit: KeyResultUnit, value: number) => {

  if (unit === KeyResultUnit.percentage) {
    return +
      getAbbreviateNumber(value, 2) +
      keyResultTypes.find(
        keyResultType => keyResultType.value === unit
      )?.label +
      generateEmptySpaces(1)
  } else {
    return keyResultTypes.find(
      keyResultType => keyResultType.value === unit
    )?.label +
      getAbbreviateNumber(value, 2) +
      generateEmptySpaces(1)
  }

};

const unitValue = {
  percentage: '%',
  currency: '$',
  number: '#'
};


export const parseValue = (value: number, digits: number, unit?: KeyResultUnit): string => {
  let valueToParse = value;
  if (!Number.isInteger(value)) {
    valueToParse = Math.round(value);
  }

  if (unit) {
    return unit === KeyResultUnit.percentage ? `${getAbbreviateNumber(valueToParse, digits)}${unitValue[unit]}` : `${unitValue[unit]}${getAbbreviateNumber(valueToParse, digits)}`;
  } else {
    return getAbbreviateNumber(valueToParse, digits);
  }
};
