import React from 'react';

import { PopperOwnProps } from '@mui/base';

import { FilterPopperContainer } from './FilterPopperStyles';

const FilterPopper = ({ children, open, anchorEl }: PopperOwnProps) => (
  <FilterPopperContainer
  placement="bottom-start"
  anchorEl={anchorEl} 
  open={open}>
    {children}
  </FilterPopperContainer>
);

export default FilterPopper;
