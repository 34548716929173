import { format } from 'date-fns';

import { MetricDefinitionDtoPeriod } from '../api/generated';

// TODO this is too specific and not generic, make a proper date/time utill
export const getHour = (date: string) => (date ? format(new Date(date), 'hh:mm') : null);
export const getFullDate = (date: string) => (date ? format(new Date(date), 'MMMM dd, yyy') : null);
export const getDayToUpperCase = (date: string) => (date ? format(new Date(date), 'EEEE') : null);
export const getDate = (date: string) => (date ? format(new Date(date), 'MMM dd, yyy') : null);

export const getCurrentQurater = (monthToStart: number) => getDateQurater(new Date(), monthToStart);

export const getMinutesBetweenDates = (startDate: Date, endDate: Date) => {
  const diff = endDate.getTime() - startDate.getTime();

  return diff / 60000;
};

export const getHoursAndMinutesFromNumberOfSeconds = (seconds: number, separated?: boolean) => {
  const totalMinutes = Math.floor(seconds / 60);
  const remainingSeconds = (seconds % 60).toFixed(0);
  const hours = Math.floor(totalMinutes / 60);
  const minutes = (totalMinutes % 60).toFixed(0);

  if (Number.isNaN(hours) || Number.isNaN(minutes) || Number.isNaN(remainingSeconds)) {
    return null;
  }

  if (separated) {
    return { hours, minutes, seconds: remainingSeconds };
  }

  return hours + ':' + minutes + ':' + remainingSeconds;
};

export const getHoursAndMinutesFromNumberOfMinutes = (number: number, separated?: boolean) => {
  const hours = Math.floor(number / 60);
  const minutes = (number % 60).toFixed(0);
  if (Number.isNaN(hours) || Number.isNaN(minutes)) {
    return null;
  }
  if (separated) {
    return { hours, minutes };
  }
  return hours + ':' + minutes;
};

export const getDateQurater = (date: Date, monthToStart: number) => {
  const currentMonth = date.getMonth() + 1;

  if (monthToStart === 1) {
    return Math.floor((currentMonth - 1) / 3) + 1;
  }

  let adjustedMonth = (currentMonth - monthToStart + 12) % 12;
  if (adjustedMonth === 0) {
    adjustedMonth = 1;
  }

  let adjustedMonthDivided = (adjustedMonth - 1) / 3;

  if (Math.abs(currentMonth - monthToStart) % 3 === 0) {
    adjustedMonthDivided = Math.ceil(adjustedMonthDivided);
  } else {
    adjustedMonthDivided = Math.floor(adjustedMonthDivided);
  }

  return adjustedMonthDivided + 1;
};

export const getDateSemiannuallyPeriod = (date: Date, monthToStart: number) => {
  const halfDate = new Date();
  if (monthToStart > halfDate.getMonth() + 1) {
    halfDate.setFullYear(halfDate.getFullYear() - 1);
  }
  halfDate.setMonth(monthToStart - 1);
  halfDate.setDate(1);
  halfDate.setHours(0);
  halfDate.setMinutes(0);
  halfDate.setSeconds(0);
  halfDate.setMilliseconds(0);

  halfDate.setMonth(halfDate.getMonth() + 6);

  if (date.getMonth() >= halfDate.getUTCMonth()) {
    return 2;
  } else {
    return 1;
  }
};

export const getCurrentPeriodDate = (
  periodType: MetricDefinitionDtoPeriod,
  monthToStart: number
) => {
  switch (periodType) {
    case MetricDefinitionDtoPeriod.quarterly: {
      const currentQ = getCurrentQurater(monthToStart);
      return getPeriodDate(periodType, monthToStart, currentQ);
    }

    case MetricDefinitionDtoPeriod.semiannually: {
      const halfPeriod = getDateSemiannuallyPeriod(new Date(), monthToStart);
      return getPeriodDate(periodType, monthToStart, halfPeriod);
    }

    default:
    case MetricDefinitionDtoPeriod.monthly:
    case MetricDefinitionDtoPeriod.annually: {
      return getPeriodDate(periodType, monthToStart);
    }
  }
};

export const getCurrentPeriod = (periodType: MetricDefinitionDtoPeriod, monthToStart: number) => {
  switch (periodType) {
    case MetricDefinitionDtoPeriod.quarterly: {
      return getCurrentQurater(monthToStart);
    }

    case MetricDefinitionDtoPeriod.semiannually: {
      return getDateSemiannuallyPeriod(new Date(), monthToStart);
    }

    default:
    case MetricDefinitionDtoPeriod.monthly: {
      const currentDate = new Date();

      const currentMonth = currentDate.getMonth() + 1;

      if (monthToStart > currentMonth) {
        const startOfYear = new Date(currentDate.getFullYear() - 1, monthToStart - 1, 1);
        return monthDiff(startOfYear, currentDate) + 1;
      } else {
        const startOfYear = new Date(currentDate.getFullYear(), monthToStart - 1, 1);

        return monthDiff(startOfYear, currentDate) + 1;
      }
    }

    case MetricDefinitionDtoPeriod.annually: {
      return 1;
    }
  }
};

export const getDatePeriod = (
  periodType: MetricDefinitionDtoPeriod,
  monthToStart: number,
  date: Date
) => {
  switch (periodType) {
    case MetricDefinitionDtoPeriod.quarterly: {
      return getDateQurater(date, monthToStart);
    }

    case MetricDefinitionDtoPeriod.semiannually: {
      return getDateSemiannuallyPeriod(date, monthToStart);
    }

    default:
    case MetricDefinitionDtoPeriod.monthly: {
      const currentMonth = date.getMonth() + 1;

      if (monthToStart > currentMonth) {
        const startOfYear = new Date(date.getFullYear() - 1, monthToStart - 1, 1);
        return monthDiff(startOfYear, date) + 1;
      } else {
        const startOfYear = new Date(date.getFullYear(), monthToStart - 1, 1);

        return monthDiff(startOfYear, date) + 1;
      }
    }

    case MetricDefinitionDtoPeriod.annually: {
      return 1;
    }
  }
};

export const getPeriodDate = (
  periodType: MetricDefinitionDtoPeriod,
  monthToStart: number,
  period?: number
) => {
  const currentDate = new Date();
  if (monthToStart > currentDate.getMonth() + 1) {
    currentDate.setFullYear(currentDate.getFullYear() - 1);
  }
  currentDate.setMonth(monthToStart - 1);
  currentDate.setDate(2);
  currentDate.setHours(15);
  currentDate.setMinutes(0);
  currentDate.setSeconds(0);
  currentDate.setMilliseconds(0);

  switch (periodType) {
    case MetricDefinitionDtoPeriod.quarterly: {
      currentDate.setMonth(currentDate.getMonth() + 3 * period);
      currentDate.setDate(0);

      return currentDate;
    }

    case MetricDefinitionDtoPeriod.semiannually: {
      if (period === 2) {
        currentDate.setMonth(currentDate.getMonth() + 6);
      }
      return currentDate;
    }

    case MetricDefinitionDtoPeriod.annually: {
      currentDate.setMonth(currentDate.getMonth() + 11);
      return currentDate;
    }

    default:
    case MetricDefinitionDtoPeriod.monthly: {
      if (period) {
        currentDate.setMonth(currentDate.getMonth() + period);
        currentDate.setDate(0);
        return currentDate;
      } else {
        currentDate.setMonth(currentDate.getMonth() + 1);
        currentDate.setDate(0);
        return currentDate;
      }
    }
  }
};

export const getDateHalfOfTheYear = (date: Date, monthToStart: number) =>
  getDateQurater(date, monthToStart) > 2 ? 2 : 1;

export const getQuraterMonthsDesc = (qurater: number, monthToStart: number) => {
  let firstMonth = 1;

  if (monthToStart === 1) {
    firstMonth = qurater * 3 + 1;
  } else {
    firstMonth = (monthToStart + qurater * 3) % 12;
    if (firstMonth == 0) {
      firstMonth = 12;
    }
  }
  const date = new Date();
  date.setMonth(firstMonth - 1);
  const firstMonthStr = date.toLocaleString([], { month: 'short' });
  date.setMonth(firstMonth + 1);
  const lastMonthStr = date.toLocaleString([], { month: 'short' });

  return firstMonthStr + ' - ' + lastMonthStr;
};

/**
 *
 * Generates empty spaces for string
 *
 * @param   numberOfUpcomingYears  Number of years to generate after this year.
 * @returns array of years
 */
export const generateArrayOfUpcomingYears = (numberOfUpcomingYears: number) => {
  const min = new Date().getFullYear();
  const max = min + numberOfUpcomingYears;
  const years = [];

  for (let i = min; i <= max; i++) {
    years.push(i);
  }
  return years;
};

/**
 *
 * Formats date string to locale date string
 *
 * @param   dateString  Number of years to generate after this year.
 * @returns parsed are to locale string
 *
 */
export const getLocaleFormattedDate = (dateString: string) =>
  new Date(dateString).toLocaleString([], {
    month: 'short',
    year: 'numeric'
  });

export const monthDiff = (d1: Date, d2: Date) => {
  let months;
  months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth();
  months += d2.getMonth();
  return months <= 0 ? 0 : months;
};

export const daysDiff = (d1: Date, d2: Date) => {
  const differenceInTime = d2.getTime() - d1.getTime();

  const differenceInDays = Math.round(differenceInTime / (1000 * 3600 * 24));

  return differenceInDays;
};

export const isSameMonth = (d1: Date, d2: Date) =>
  d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth();

export const getNumberOfDaysInMonth = (month: number) =>
  new Date(new Date().getFullYear(), month, 0).getDate();

export const isLastDayOfMonth = (date: Date): boolean => {
  const month = date.getMonth();
  const nextDay = new Date(date.getFullYear(), month, date.getDate() + 1);
  return nextDay.getMonth() !== month;
};

export const toUtcIsoDate = (date: Date) => {
  const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
  return utcDate.toISOString();
};
