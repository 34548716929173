import isPropValid from '@emotion/is-prop-valid';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Stack, Typography, styled, alpha } from '@mui/material';

import palette from '../../theme/palette';

export const Container = styled(Stack)(({ theme }) => ({
  padding: '14px 8px 12px',
  gap: '12px',
  width: '100%',
  background: 'white',
  boxShadow: `0px 10px 20px ${alpha(theme.journeyPalette.blue[6], 0.03)}`,
  borderRadius: '12px'
}));

export const ActionsContainer = styled(Stack)(({ theme }) => ({
  position: 'relative',
  width: '17px',
  height: '17px',
  right: '-7px',
  top: '-7px',
  background: theme.journeyPalette.secondary.main,
  borderRadius: '6px',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer'
}));

export const ActionTitle = styled(Typography)(({ theme }) => ({
  fontFamily: 'Plus Jakarta Sans',
  fontWeight: 500,
  fontSize: '13px',
  color: theme.journeyPalette.common.darkBlue
}));

export const ProgressTitle = styled(Typography)(({ theme }) => ({
  fontFamily: 'Plus Jakarta Sans',
  fontWeight: 700,
  fontSize: '13px',
  lineHeight: '16px',
  color: theme.journeyPalette.common.darkBlue
}));

export const DescriptionAndTitleContainer = styled(Stack)(({ theme }) => ({
  padding: '12px',
  gap: '5px',
  background: theme.journeyPalette.blue[8],
  borderRadius: '8px'
}));

export const Description = styled(Typography)(({ theme }) => ({
  fontFamily: 'Plus Jakarta Sans',
  fontWeight: 800,
  fontSize: '14px',
  color: theme.journeyPalette.common.darkBlue
}));

export const KeyResultDescription = styled(Typography)(({ theme }) => ({
  fontFamily: 'Plus Jakarta Sans',
  fontWeight: 400,
  fontSize: '12px',
  color: theme.journeyPalette.common.darkBlue
}));

export const KeyResultEmpty = styled(Typography)(() => ({
  fontFamily: 'Plus Jakarta Sans',
  fontWeight: 400,
  cursor: "pointer",
  fontSize: '12px',
  width: "max-content",
  borderBottom: `0.5px solid ${palette.primary.main}`,
  ":hover": { opacity: 0.8 },
  color: palette.primary.main
}));

export const AddIcon = styled(AddCircleIcon, { shouldForwardProp: isPropValid })(({ theme }) => ({
  color: theme.journeyPalette.green[1],
  fontSize: '19px'
}));

export const NewInitiativeButton = styled(Typography, { shouldForwardProp: isPropValid })(({ theme }) => ({
  color: theme.journeyPalette.green[1],
  fontFamily: 'Plus Jakarta Sans',
  fontWeight: 700,
  fontSize: '12px',
  cursor: 'pointer'
}));

export const InitiativesListButton = styled(Typography)(({ theme }) => ({
  color: theme.journeyPalette.common.darkBlue,
  fontFamily: 'Plus Jakarta Sans',
  fontWeight: 800,
  fontSize: '13px'
}));

export const InitiativesCountButton = styled(Stack)(() => ({
  color: palette.common.darkBlue,
  backgroundColor: palette.blue[8],
  borderRadius: "50px",
  textAlign: "center",
  width: "19px",
  height: "19px",
  fontFamily: 'Plus Jakarta Sans',
  fontWeight: 800,
  fontSize: '12px'
}));

export const InitiativesContainer = styled(Stack)({
  gap: '8px',
  marginLeft: '25px',
  marginTop: '12px'
});
