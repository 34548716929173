import React from 'react';

import { ProgressTitle } from '../InitiativesCard/InitiativesCardStyles';
import { GoalProgressContainer, LinearGoalProgress } from './GoalProgressStyles';

type Props = {
  goalProgress: number;
};

const GoalProgress = ({ goalProgress }: Props) => (
  <GoalProgressContainer>
    <LinearGoalProgress value={goalProgress > 100 ? 100 : goalProgress} variant="determinate" />
    <ProgressTitle>{Math.trunc(goalProgress)}%</ProgressTitle>
  </GoalProgressContainer>
);

export default GoalProgress;
