import React, { useCallback, useEffect, useState } from 'react';

import { MenuItem, Skeleton, Stack } from '@mui/material';
import { ReactComponent as GoalIcon } from '../../assets/goal.svg';
import ActionItemsList from '../../components/ActionItemsList/ActionItemsList';
import useLayoutContext from '../../components/Layouts/LayoutContext';
import { ScrollMenu, VisibilityContext, publicApiType } from 'react-horizontal-scrolling-menu';
import 'react-horizontal-scrolling-menu/dist/styles.css';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';


import useNavigationListManifest, {
  NavigationItemsKey
} from '../../hooks/useNavigationListManifest';

import { HeaderTitle, NoOkrsTitle, Section, StartHere, TimeFrameSelect, TimeframeTitle, ViewAllButton } from './HomeStyles';
import useActionPlanObjective from '../../hooks/useActionPlanObjective';
import useAuth from '../../hooks/useAuth';

import OkrCard from './OkrCard';
import { OkrTimeframe } from '../../hooks/context-providers/useActionPlanTimeframeContext';
import useForum from '../../hooks/useForum';
import PostCard from '../Forum/components/PostCard';
import HomeSideMenu from './HomeSideMenu';
import { UserDetailsType } from '../../api/generated';
import palette from '../../theme/palette';
import { useNavigate } from 'react-router-dom';
import { toActionPlan, toForum } from '../../services/linker';
import { ButtonContainer, ButtonText } from '../Forum/ForumStyles';
import useModal from '../../hooks/context-providers/useModal/useModal';

const LazyLoadedCreatePostModal = React.lazy(
  () => import('../../modals/CreatePostModal/CreatePostModal')
);

function Arrow({
  children,
  disabled,
  onClick
}: {
  children: React.ReactNode;
  disabled: boolean;
  onClick: VoidFunction;
}) {
  return (
    <Stack
      onClick={onClick}
      sx={{
        cursor: "pointer",
        display: disabled ? "none" : "flex",
        flexDirection: "column",
        justifyContent: "center",
        height: "30px",
        right: "1%",
        alignSelf: "center",
        userSelect: "none"
      }}
    >
      {children}
    </Stack>
  );
}

export function LeftArrow() {
  const visibility = React.useContext<publicApiType>(VisibilityContext);
  const isFirstItemVisible = visibility.useIsVisible("first", true);

  return (
    <Arrow
      disabled={isFirstItemVisible}
      onClick={() => visibility.scrollPrev()}
    >
      <ArrowBackIosOutlinedIcon sx={{ color: palette.primary.main }} />
    </Arrow>
  );
}

export function RightArrow() {
  const visibility = React.useContext<publicApiType>(VisibilityContext);
  const isLastItemVisible = visibility.useIsVisible("last", false);

  return (
    <Arrow disabled={isLastItemVisible} onClick={() => visibility.scrollNext()}>
      <ArrowForwardIosOutlinedIcon sx={{ color: palette.primary.main }} />
    </Arrow>
  );
}

const Home = () => {
  const navList = useNavigationListManifest();
  const homeNavItem = navList.find(navItem => navItem.key === NavigationItemsKey.Home);

  const navigate = useNavigate();
  const { user } = useAuth();
  const { detailedObjectivesList, detailedObjectivesLoading, refetchDetailedObjective } =
    useActionPlanObjective(null, null, null, null, null, [user.id]);

  const { postList } = useForum(null, 3);
  const { setNavigationFlowItems } = useLayoutContext();
  const { showModal } = useModal();
  const [timeframe, setTimeframe] = useState<OkrTimeframe>(OkrTimeframe.YEARLY);


  const newPostClicked = useCallback(() => {
    const modal = showModal(LazyLoadedCreatePostModal, {
      onClose: () => {
        modal.hide();
      },
      onCancel: () => {
        modal.hide();
      },
      onConfirm: () => {
        modal.hide();
      }
    });
  }, [showModal]);


  useEffect(() => {
    setNavigationFlowItems([
      {
        label: homeNavItem.label,
        slug: homeNavItem.slug
      }
    ]);
  }, [homeNavItem, setNavigationFlowItems]);



  return (
    <Stack
      width="100%"
      marginLeft="10px"
      marginRight="10px"
      marginBottom="10px"
      justifyContent="space-between"
      gap={2}
      direction="row"
      height="100%">

      <Stack
        paddingBottom="20px"
        width="65%"
      >

        <Stack marginBottom="30px">

          {user.type !== UserDetailsType.jp &&
            <Section>
              <Stack
                direction="row"
                marginTop={1}
                alignItems="center"
                justifyContent="space-between"
                gap={3}>
                <HeaderTitle>My OKRs</HeaderTitle>
                <TimeFrameSelect
                  value={timeframe}

                  onChange={event => setTimeframe(event.target.value as OkrTimeframe)}
                >
                  <MenuItem value={OkrTimeframe.QUARTERLY}>
                    <TimeframeTitle>Quarterly</TimeframeTitle>
                  </MenuItem>
                  <MenuItem value={OkrTimeframe.YEARLY}>
                    <TimeframeTitle>Yearly</TimeframeTitle>
                  </MenuItem>
                </TimeFrameSelect>
              </Stack>


              <Stack
                marginTop={1}
              >

                {detailedObjectivesLoading ?
                  <Stack
                    direction="row"
                    alignItems="center"
                    gap={1}>
                    {['i', 'am', 'a', 'skeleton'].map((_, index) => (
                      <Skeleton
                        key={index}
                        animation="wave"
                        variant="rectangular" width="160px" height="160px" />
                    ))}
                  </Stack> :

                  detailedObjectivesList?.length > 0 ?
                    <ScrollMenu
                      LeftArrow={LeftArrow}
                      RightArrow={RightArrow}
                    >
                      {detailedObjectivesList?.map(objective =>
                        <OkrCard
                          refetch={refetchDetailedObjective}
                          key={objective.id}
                          objective={objective}
                          timeframe={timeframe} />)}

                    </ScrollMenu>
                    : <Stack
                      alignSelf="center">
                      <Stack
                        direction="row"
                        alignItems="center"
                        gap={1}>
                        <GoalIcon width="40px" height="40px" />
                        <Stack
                          direction="row"
                          alignItems="center">

                          <NoOkrsTitle>You don&apos;t own any OKRs yet.</NoOkrsTitle>
                          <StartHere
                            onClick={() => navigate(toActionPlan())}
                          >Get started</StartHere>
                        </Stack>
                      </Stack>
                    </Stack>}


              </Stack>
            </Section>}

          <Section>
            <ActionItemsList
              oneLineHeader
              title="My Tasks"
              showStatusFiltering={false}
              showSort
              showSearch
            />
          </Section>

          <Section>
            <Stack
              marginBottom={1}
              marginTop={2}
              gap={1}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between">
                <HeaderTitle>Discover what&apos;s new on Journey Connect</HeaderTitle>

                {user.type !== UserDetailsType.jp && (
                  <ButtonContainer onClick={newPostClicked}>
                    <ButtonText>Post a question</ButtonText>
                  </ButtonContainer>
                )}

              </Stack>

              <Stack>
                {postList?.map(post =>
                  <PostCard
                    key={post.id}
                    post={post} />)}
              </Stack>

              <ViewAllButton
                alignSelf="center"
                onClick={() => navigate(toForum())}
              >View more</ViewAllButton>

            </Stack>
          </Section>

        </Stack>
      </Stack>


      <Stack width="35%">
        <Section>
          <HomeSideMenu
          />
        </Section>
      </Stack>

    </Stack>
  );
};

export default Home;
