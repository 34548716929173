import { Stack, Typography, styled } from '@mui/material';
import isPropValid from '@emotion/is-prop-valid';
import palette from '../../theme/palette';
import { ReactComponent as SuggestionIcon } from '../../assets/suggestion.svg';

export const SuggestionsText = styled(Typography, { shouldForwardProp: isPropValid })(() => ({
  fontFamily: 'Plus Jakarta Sans',
  fontWeight: 700,
  fontSize: '12px',
  color: 'white',
  paddingRight: 5,
  cursor: 'pointer',
  whiteSpace: 'nowrap'
}));

export const SuggestionsButtonContainer = styled(Stack, { shouldForwardProp: isPropValid })<{ opened: boolean }>(
  () => ({
    backgroundColor: palette.blue[11],
    flexDirection: 'row',
    borderRadius: '60px',
    height: '20px',
    display: 'inline-flex',
    cursor:"pointer",
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
    width:"20px",
    WebkitTransition: 'max-width 0.5s'
  })
);

export const SuggestionsIconContainer = styled(Stack, { shouldForwardProp: isPropValid })(() => ({
  padding: 3.5
}));



export const AiIcon = styled(SuggestionIcon, { shouldForwardProp: isPropValid })<{ isLoading: boolean }>(
  ({ isLoading }) => ({
    animation: isLoading && "spin 3s linear infinite",
    "@keyframes spin": {
      "0%": {
        transform: "rotate(0deg)"
      },
      "100%": {
        transform: "rotate(360deg)"
      }
    }
  })
);
