import React, { SetStateAction, createContext, useContext, useState } from 'react';

export enum OkrTimeframe {
  QUARTERLY = 'quarterly',
  YEARLY = 'yearly'
}

type ActionPlanTimeframeContext = {
  timeframe: OkrTimeframe;
  setTimeframe: React.Dispatch<SetStateAction<OkrTimeframe>>;
};

export const ActionPlanTimeframeContext =
  createContext<ActionPlanTimeframeContext | null>(null);

const useActionPlanTimeframeContext = () => useContext(ActionPlanTimeframeContext);
export const ActionPlanTimeframeProvider = ({ children }: { children: React.ReactNode }) => {
  const [timeframe, setTimeframe] = useState<OkrTimeframe>(OkrTimeframe.YEARLY);
  const value = { timeframe, setTimeframe };


  return (
    <ActionPlanTimeframeContext.Provider value={value}>
      {children}
    </ActionPlanTimeframeContext.Provider>
  );
};

export default useActionPlanTimeframeContext;






