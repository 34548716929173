import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import { CircularProgress, Stack, Tooltip } from '@mui/material';

import {
  ActionPlanObjectiveDetailedDto,
  MetricAggregationDataPointDto,
  MetricDefinitionDtoPeriod,
  MetricDefinitionProgressDto,
  MetricTargetDefinitionDto
} from '../../api/generated';
import { OkrTimeframe } from '../../hooks/context-providers/useActionPlanTimeframeContext';
import useModal from '../../hooks/context-providers/useModal/useModal';
import useAuth from '../../hooks/useAuth';
import MetricChart from '../../screens/MetricsManagement/components/MetricViewSidebar/components/MetricChart/MetricChart';
import palette from '../../theme/palette';
import { getCurrentQurater } from '../../utils/dateUtil';
import { buildDataPointsForChart, predictMetricDataPoints } from '../../utils/metricUtil';
import { getAbbreviateNumber } from '../../utils/numberUtil';
import {
  Baseline,
  KeyResultDescription,
  KeyResultEmpty,
  KrValueText
} from './KeyResultViewStyles';
import useMetricDataPoints from '../../hooks/useMetricDataPoints';
import useMetricDefinition from '../../hooks/useMetricDefinition';
import { formatKeyResult, parseValue } from '../../utils/actionPlanUtil';




type KeyResultViewProps = {
  objective: ActionPlanObjectiveDetailedDto;
  timeframe: OkrTimeframe;
  includeChart?: boolean;
  refetch?: () => void;
  small?: boolean;
  header?: ReactNode;
};


const LazyLoadedObjectiveKeyResultModal = React.lazy(
  () => import('../../modals/ObjectiveModal/ObjectiveKeyResultModal')
);

const KeyResultView = ({
  objective,
  timeframe,
  includeChart,
  small,
  header,
  refetch
}: KeyResultViewProps) => {
  const krSubject = objective?.keyResult?.subject;
  const unit = objective?.keyResult?.unit;

  const { currentAccount } = useAuth();
  const monthToStart = currentAccount?.config?.fiscalYearStartMonth || 1;

  const { getMetricsProgress, metricDefinition: metric } = useMetricDefinition(
    objective.keyResult.metricId
  );
  const [metricProgress, setMetricProgress] = useState<MetricDefinitionProgressDto>();
  useEffect(() => {
    getMetricsProgress([metric?.id]).then(response => {
      setMetricProgress(response?.[0] || null);
    });
  }, [metric, getMetricsProgress, setMetricProgress]);

  const currentQ = getCurrentQurater(monthToStart);
  const krValue =
    timeframe === OkrTimeframe.YEARLY
      ? objective.keyResult?.value
      : objective.keyResult.targets?.find(tar => tar.period === currentQ)?.value || null;

  const progress =
    timeframe === OkrTimeframe.YEARLY
      ? metricProgress?.totalProgress
      : metricProgress?.currentProgress;


  const baseline =
    timeframe === OkrTimeframe.YEARLY
      ? objective?.keyResult.startingPoint
      : metricProgress?.currentBaseline;


  const { showModal } = useModal();
  const editKrClick = useCallback(() => {
    const modal = showModal(LazyLoadedObjectiveKeyResultModal, {
      onClose: () => {
        modal.hide();
      },
      onCancel: () => {
        modal.hide();
      },
      onConfirm: () => {
        refetch && refetch();
        modal.hide();
      },
      objective: objective,
      openQuraterlyTargets: true
    });
  }, [showModal, objective, refetch]);

  const { metricDatapoints, metricDataPointsLoading } =
    useMetricDataPoints(metric?.id, {
      period: metric?.period !== MetricDefinitionDtoPeriod.annually ? MetricDefinitionDtoPeriod.monthly : MetricDefinitionDtoPeriod.annually,
      summarize: metric?.summarize
    });

  const yearlyDatapoints = buildDataPointsForChart<MetricAggregationDataPointDto>(
    monthToStart,
    metricDatapoints,
    true
  );

  const allDatapoints = buildDataPointsForChart<MetricAggregationDataPointDto>(
    monthToStart,
    metricDatapoints,
    false
  );

  const lastYearDatapoints = buildDataPointsForChart<MetricAggregationDataPointDto>(
    monthToStart,
    metricDatapoints,
    true,
    true
  );

  const targetDatapoints = buildDataPointsForChart<MetricTargetDefinitionDto>(
    monthToStart,
    metric?.targets?.sort(
      (a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
    ),
    true,
    false
  );

  const predictionDataPoints = buildDataPointsForChart<MetricAggregationDataPointDto>(
    monthToStart,
    predictMetricDataPoints(yearlyDatapoints, metric?.trendType, metric?.dataPointType),
    true
  );


  const currentValue = yearlyDatapoints[yearlyDatapoints.length - 1]?.value;
  const krDescription = formatKeyResult(unit, krValue, krSubject);

  return (
    <Stack
      width="100%"
      height="100%"
    >

      {small ?

        <Stack
          height="100%"
          padding={1}

          gap={1}

          justifyContent="space-between"
          alignItems="center"
        >
          {krDescription !== null ? (
            <Tooltip title={krDescription}>
              <KeyResultDescription
                fontSize="14px"
              >
                {krDescription}
              </KeyResultDescription>
            </Tooltip>
          ) : (
            <KeyResultEmpty
              onClick={event => {
                event.stopPropagation();
                !krValue && editKrClick();
              }}
            >
              {'Set Q' + getCurrentQurater(monthToStart) + ' Key Result'}
            </KeyResultEmpty>
          )}



          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            gap={2}>


            <Stack width="50px" height="50px">
              <CircularProgressbarWithChildren
                value={progress || 0}
                text={`${Math.trunc(progress) || 0}%`}
                styles={buildStyles({
                  textSize: '20px',
                  pathColor: palette.primary.main,
                  textColor: palette.primary.main
                })}
              />
            </Stack>

            <Stack
              alignItems="start">
              <KrValueText>{currentValue ? parseValue(currentValue, 2, unit) : '0'} / {krValue}</KrValueText>
              {<Baseline>Baseline: {getAbbreviateNumber(baseline | 0, 2)}</Baseline>}

            </Stack>
          </Stack>







        </Stack>

        : <Stack
          width="100%">
          <Stack
            width="100%"
            alignItems="center"
            direction="row"
            justifyContent="space-between"
            gap={2}

          >

            <Stack
              direction="row"
              marginTop={2}
              alignItems="center"
              justifyContent="center"
              gap={2}>



              <Stack width="80px" height="80px">
                <CircularProgressbarWithChildren
                  value={progress || 0}
                  text={Math.trunc(progress || 0) + '%'
                  }
                  styles={buildStyles({
                    textSize: '20px',
                    pathColor: palette.primary.main,
                    textColor: palette.primary.main
                  })}
                />
              </Stack>


              <Stack
                gap={!small && "3px"}

                alignItems="start">
                <KrValueText>
                  {(currentValue ? parseValue(currentValue, 2, unit) : '0') + "/" + krValue}
                </KrValueText>
                <Stack
                  direction="row"


                  justifyContent="space-between"


                >
                  {krValue !== null ? (
                    <Tooltip title={small ? krDescription : krSubject}>
                      <KeyResultDescription

                        maxWidth={small ? '3000px' : '400px'}
                        fontSize={small ? '12px' : '16px'}
                      >
                        {small ? krDescription : krSubject}
                      </KeyResultDescription>
                    </Tooltip>
                  ) : (
                    <KeyResultEmpty
                      onClick={event => {
                        event.stopPropagation();
                        !krValue && editKrClick();
                      }}
                    >
                      {'Set Q' + getCurrentQurater(monthToStart) + ' Key Result'}
                    </KeyResultEmpty>
                  )}
                </Stack>
                {<Baseline>Baseline: {getAbbreviateNumber(baseline | 0, 2)}</Baseline>}

              </Stack>
            </Stack>
            {header &&
              <Stack
              paddingRight={1}>

                {header}
              </Stack>}




          </Stack>

          {includeChart && (
            <Stack

              width="100%">
              {metricDataPointsLoading ? (
                <CircularProgress />
              ) : (
                metric && (
                  <MetricChart
                    currentMetric={metric}
                    metricDatapoints={yearlyDatapoints}
                    allDatapoints={allDatapoints}
                    lastYearDatapoints={lastYearDatapoints}
                    targetDatapoints={targetDatapoints}
                    predictionDataPoints={predictionDataPoints}
                    currentDateAnnotation
                    small={true}
                    height={300}
                    width={750}

                  />
                )
              )}
            </Stack>
          )}</Stack>}



    </Stack>
  );
};

export default KeyResultView;
