import { useCallback, useMemo } from 'react';

import {
  SearchEntityRequest,
  SharedWorkspacePointDto,
  SharedWorkspacePointDtoStatus,
  useAddSharedWorkspaceStarredPoint,
  useBulkPatchSharedWorkspacePoints,
  useCreateSharedWorkspacePoint,
  useDeleteSharedWorkspacePoint,
  useListSharedWorkspacePoints,
  useListStarredSharedWorkspacePoints,
  usePatchSharedWorkspacePoint,
  useRemoveSharedWorkspaceStarredPoint,
  useSearchSharedWorkspacePoints,
  useUpdateSharedWorkspacePoint
} from '../api/generated';


const useSharedWorkspacePoints = (sharedWorkspaceId?: string, status?: SharedWorkspacePointDtoStatus) => {
  const {
    data: pointsResponse,
    isLoading: pointsLoading,
    refetch: refetchPoints
  } = useListSharedWorkspacePoints(sharedWorkspaceId,
    { status: status || null },
    {
      query: {
        queryKey: ['sharedWorkspacePoints', sharedWorkspaceId, status],
        enabled: !!sharedWorkspaceId
      }
    });

  const {
    data: starredPointsResponse,
    isLoading: starredPointsLoading,
    refetch: refetchStarredPoints
  } = useListStarredSharedWorkspacePoints({
    query: {
      queryKey: ['starredSharedWorkspacePoints'],
      enabled: !sharedWorkspaceId
    }
  });

  const { mutateAsync: mutatedeletePoint } = useDeleteSharedWorkspacePoint();
  const { mutateAsync: mutateCreatePoint } = useCreateSharedWorkspacePoint();
  const { mutateAsync: mutatePatchPoint } = usePatchSharedWorkspacePoint();
  const { mutateAsync: mutateUpdatePoint } = useUpdateSharedWorkspacePoint();
  const { mutateAsync: mutateBulkPatchSharedWorkspacePoints } = useBulkPatchSharedWorkspacePoints();
  const { mutateAsync: mutateSearchSharedWorkspacePoints, isPending: searchSharedWorkspacePointsLoading } = useSearchSharedWorkspacePoints();

  const { mutateAsync: mutatAddSharedWorkspaceStarredPoint } = useAddSharedWorkspaceStarredPoint();
  const { mutateAsync: mutateRemoveSharedWorkspaceStarredPoint } = useRemoveSharedWorkspaceStarredPoint();


  const addStarredPoint = useCallback(
    async (id: string) => {
      await mutatAddSharedWorkspaceStarredPoint({ id });

    },
    [mutatAddSharedWorkspaceStarredPoint]
  );

  const removeStarredPoint = async (id: string) => await mutateRemoveSharedWorkspaceStarredPoint({ id });

  const searchSharedWorkspacePoints = useCallback(
    async (data: SearchEntityRequest, includeResolved?: boolean) => {
      const response = await mutateSearchSharedWorkspacePoints({ id: sharedWorkspaceId, data, params: { includeResolved: includeResolved || null } });
      return response?.items || [];
    },
    [mutateSearchSharedWorkspacePoints, sharedWorkspaceId]
  );

  const createPoint = useCallback(
    async (data: Partial<SharedWorkspacePointDto>) => {
      const response = await mutateCreatePoint({ data: { ...data, status: SharedWorkspacePointDtoStatus.pending } });
      return response?.items?.[0];
    },
    [mutateCreatePoint]
  );


  const patchPoint = async (id: string, data: Partial<SharedWorkspacePointDto>) =>
    await mutatePatchPoint({ id, data });

  const updatePoint = async (id: string, data: Partial<SharedWorkspacePointDto>) =>
    await mutateUpdatePoint({ id, data });

  const deletePoint = async (id: string) => await mutatedeletePoint({ id });

  const bulkPatchPoints = useCallback(
    async (data: Partial<SharedWorkspacePointDto>[]) => {
      await mutateBulkPatchSharedWorkspacePoints({ data: { items: data } });
    },
    [mutateBulkPatchSharedWorkspacePoints]
  );

  const points = useMemo(() => pointsResponse?.items || [], [pointsResponse]);

  const starredPoints = useMemo(() => starredPointsResponse?.items || [], [starredPointsResponse]);

  return {
    points,
    pointsLoading,
    createPoint,
    patchPoint,
    updatePoint,
    deletePoint,
    refetchPoints,
    starredPoints,
    starredPointsLoading,
    refetchStarredPoints,
    bulkPatchPoints,
    searchSharedWorkspacePoints,
    searchSharedWorkspacePointsLoading,
    addStarredPoint,
    removeStarredPoint
  };
};

export default useSharedWorkspacePoints;
