import React, { useMemo, useRef, useState } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import isPropValid from '@emotion/is-prop-valid';
import { Box, BoxProps, SxProps, styled } from '@mui/material';
import Mention from 'quill-mention';
import 'quill-mention/dist/quill.mention.css';
import QuillMarkdown from 'quilljs-markdown';

import useUsers from '../../../hooks/useUsers';
import palette from '../../../theme/palette';

const DynamicToolbarReactQuillContainer = styled(Box, { shouldForwardProp: isPropValid }) <
  BoxProps & { $toolbarOpen: boolean; $showBorder: boolean; $fontSize: string; $fontColor: string }
>`
  @keyframes fade_in_show {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  font-family: Plus Jakarta Sans;
  font-weight: 500;
  font-size: 13px;
  color: ${palette.common.darkBlue};

  .ql-toolbar {
    display: ${({ $toolbarOpen }) => ($toolbarOpen ? 'block' : 'none')};
    animation: fade_in_show 0.5s ease-in-out;
    border-top: ${({ $showBorder }) => !$showBorder && '0 !important'};
    border-right: ${({ $showBorder }) => !$showBorder && '0 !important'};
    border-left: ${({ $showBorder }) => !$showBorder && '0 !important'};
    border-radius: 3px 3px 0 0;
  }

  .ql-container {
    border: ${({ $showBorder }) => ($showBorder ? '1px solid #ccc' : '0 !important')};
    color: ${({ $fontColor }) => $fontColor && $fontColor};
    font-size: ${({ $fontSize }) => $fontSize && $fontSize};
    font-family: Plus Jakarta Sans;
    border-radius: ${({ $toolbarOpen }) => ($toolbarOpen ? '0 0 3px 3px' : '3px')};
    border-color: ${palette.border.grey};
  }

  border-radius: ${({ $toolbarOpen }) => ($toolbarOpen ? '0 0 3px 3px' : '3px')};
  border-top: ${({ $toolbarOpen, $showBorder }) =>
    !$toolbarOpen && $showBorder && '1px solid #ccc'};

  .ql-blank::before {
    color: ${palette.grey[500]};
    font-size: 14px;
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 500;
  }

  .ql-editor::before {
    font-size: 13px; 
    font-family: 'Plus Jakarta Sans';
  }
`;
interface EditorProps {
  onChange: (content: string) => void;
  defaultValue?: string;
  value?: string;
  onBlur?: (content: string) => void;
  sx?: SxProps;
  placeholder?: string;
  showBorder?: boolean;
  setFocused?: () => void;
  alwaysShowToolbar?: boolean;
  includeMentions?: boolean;
  addTaggedUserId?: (userId: string) => void;
  fontSize?: string;
  fontColor?: string;
  openContextMenu?: (anchorEl: null | HTMLElement, text: string) => void;
}

Quill.register('modules/QuillMarkdown', QuillMarkdown, true);
Quill.register('modules/mention', Mention, true);

const Editor = ({
  onChange,
  defaultValue,
  onBlur,
  sx,
  placeholder = 'Type here',
  showBorder = true,
  setFocused,
  alwaysShowToolbar = false,
  includeMentions = false,
  addTaggedUserId,
  fontColor,
  fontSize,
  openContextMenu,
  ...props
}: EditorProps) => {

  const [toolbarOpen, setToolbarOpen] = useState(alwaysShowToolbar);
  const quillRef = useRef(null);
  const { usersList } = useUsers();


  const values = useMemo(
    () => usersList?.map(user => ({
      id: user.id,
      value: user.firstName + ' ' + user.lastName
    })),
    [usersList]
  );

  const modules = useMemo(
    () => ({
      toolbar: [
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
        ['link', 'image']
      ],
      clipboard: {
        matchVisual: false
      },
      QuillMarkdown: {
        ignoreTags: ['pre', 'strikethrough'], // @option - if you need to ignore some tags.

        tags: {
          // @option if you need to change for trigger pattern for some tags.
          blockquote: {
            pattern: /^(\|){1,6}\s/g
          },
          bold: {
            pattern: /^(\|){1,6}\s/g
          },
          italic: {
            pattern: /(_){1}(.+?)(?:\1){1}/g
          }
        }
      },
      mention: includeMentions
        ? {
          allowedChars: /^[A-Za-z\s]*$/,
          mentionDenotationChars: ['@'],
          source: function (searchTerm, renderList) {
            if (searchTerm.length === 0) {
              renderList(values, searchTerm);
            } else {
              const matches = [];
              for (const element of values)
                if (~element.value.toLowerCase().indexOf(searchTerm.toLowerCase()))
                  matches.push(element);
              renderList(matches, searchTerm);
            }
          },
          onSelect: function (item, insertItem) {
            !!addTaggedUserId && addTaggedUserId(item.id);
            insertItem(item);
          }
        }
        : undefined
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [values]
  );

  const formats = useMemo(
    () => [
      'mention',
      'header',
      'bold',
      'italic',
      'underline',
      'strike',
      'blockquote',
      'list',
      'bullet',
      'indent',
      'link',
      'image'
    ],
    []
  );

  return ((!includeMentions || values?.length > 0) &&
    <DynamicToolbarReactQuillContainer
      $toolbarOpen={toolbarOpen}
      $fontSize={fontSize}
      $fontColor={fontColor}
      $showBorder={showBorder}
      ref={quillRef}
      sx={{
        '& .wrapper': {
          border: `1px solid ${palette.grey['500']}`,
          borderRadius: '3px',
          minHeight: 110,
          '&:hover': { border: `1px solid ${palette.grey['700']}` },
          '&:focus-visible': { border: `1px solid ${palette.grey['700']}` }
        },
        ...sx
      }}
    >
      <ReactQuill
        {...props}
        placeholder={placeholder}
        theme="snow"
        modules={modules}
        formats={formats}
        defaultValue={defaultValue}
        onBlur={(range, source, quill) => {
          setToolbarOpen(alwaysShowToolbar);
          setFocused?.();
          onBlur?.(quill.getHTML());
        }}
        onChange={(newValue, delta, source) => {
          if (source === 'user') {
            onChange(newValue);
          }
        }}
        onFocus={() => {
          setToolbarOpen(true);
          setFocused?.();
        }}
        onChangeSelection={(selection, source, editor) => {
          if (openContextMenu) {
            if (source !== "silent" && selection && selection.length > 0) {
              const text = editor.getText(selection.index, selection.length);
              openContextMenu(quillRef.current.children?.[0], text);
            }
          }

        }}
      />
    </DynamicToolbarReactQuillContainer>
  );
};

export default React.memo(Editor);
