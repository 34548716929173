import isPropValid from '@emotion/is-prop-valid';
import { AddBox } from '@mui/icons-material';
import {
  AccordionSummary,
  alpha,
  Box,
  Button,
  FormControlLabel,
  Input,
  InputBase,
  MenuItem,
  Select,
  Stack,
  styled,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from '@mui/material';

import palette from '../../theme/palette';
import { ActionPlanActionItemDtoStatus } from '../../api/generated';

const HeaderBlock = styled(Stack)(() => ({
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: "10px",
  marginTop: "5px"
}));


const ActionItemsSuggestionContainer = styled(Stack)({
  gap: '8px',
  width: '100%'
});

const HeaderTitleBlock = styled(Stack)(() => ({
  margin: '18px 0',
  flexDirection: 'row',
  alignItems: 'center',
  gap: "10px"
}));

const ShowCompletedCheckbox = styled(FormControlLabel)(({ theme }) => ({
  color: theme.journeyPalette.common.darkBlue,
  '.MuiTypography-root': {
    fontSize: theme.typography.body2.fontSize
  },
  '.MuiCheckbox-root': {
    padding: '0',
    color: theme.journeyPalette.primary.main,
    marginRight: '5px',
    '& .MuiSvgIcon-root': { fontSize: 28 }
  }
}));

const HeaderAddBoxIcon = styled(AddBox)(({ theme }) => ({
  width: 30,
  height: 30,
  color: theme.journeyPalette.primary.main,
  cursor: 'pointer',
  ':hover': { opacity: 0.8 }
}));

const HeaderButtonBlock = styled(Stack)(() => ({
  flexDirection: 'row',
  alignItems: 'center',
  gap: 15
}));

const AddActionItemInput = styled(Input)(({ theme }) => ({
  backgroundColor: 'transparent',
  fontFamily: 'Plus Jakarta Sans',
  fontSize: '13px',
  padding: '12px 15px',
  width: '100%',
  border: 'none',
  "& .MuiInput-input": {
    "&::placeholder": {
      opacity: 1
    }
  },
  borderRadius: 0,
  borderBottom: `1px solid ${theme.journeyPalette.border.grey}`,
  borderTop: `1px solid ${theme.journeyPalette.border.grey}`
}));

const HeaderTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  fontSize: "15px",
  color: theme.journeyPalette.common.darkBlue
}));

const ActionsEmpty = styled(Stack)(() => ({
  justifyContent: 'center',
  alignItems: 'center'
}));

const AddActionBlock = styled(Stack)(() => ({
  marginTop: '18px'
}));

const StatusTitle = styled(Stack)(() => ({
  borderRadius: '5px',
  alignItems: 'center',
  justifyContent: 'center',
  height: '30px',
  padding: 6,
  width: '80px',
  fontFamily: "'Plus Jakarta Sans'",
  fontSize: '12px',
  fontWeight: 500,
  color: palette.common.darkBlue
}));

const ActionItemTitle = styled(Stack)(() => ({
  borderRadius: '5px',
  justifyContent: 'center',
  fontSize: '13px',
  height: '30px',
  padding: 6
}));

const InitiativeTitleContainer = styled(Stack)({
  borderRadius: '5px',
  height: '30px',
  backgroundColor: palette.green[6],
  paddingRight: 5,
  paddingLeft: 5,
  padding: 7,
  width: '100px'
});

const InitiaiveTitle = styled(Typography)(() => ({
  fontFamily: 'Plus Jakarta Sans',
  color: palette.grey[1100],
  fontSize: '11px',
  fontWeight: 500,
  paddingLeft: 3,
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden'
}));

const DueDateTitle = styled(Typography)(() => ({
  fontFamily: 'Plus Jakarta Sans',
  color: palette.grey[1100],
  fontSize: '11px',
  fontWeight: 500
}));

const DueDate = styled(Typography, { shouldForwardProp: isPropValid })<{
  expired?: boolean;
}>(({ expired }) => ({
  fontFamily: 'Plus Jakarta Sans',
  width: "max-content",
  color: expired ? palette.red[2] : palette.grey[1100],
  fontSize: '11px',
  fontWeight: 500,
  ":hover": { opacity: 0.7, backgroundColor: palette.background.paper, padding: 1, borderRadius: "6px", border: `0.5px solid ${palette.border.grey}` }
}));

const UsersDivider = styled(Stack)(() => ({
  flexDirection: "row",
  alignItems: "center",
  marginLeft: "2px",
  gap: "2px"
}));

const BusinessAreaTitle = styled(Stack)(() => ({
  cursor: 'pointer',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 2,
  height: '30px',
  borderRadius: '5px',
  fontWeight: 600,
  fontSize: '12px',
  padding: 2,
  paddingRight: 5,
  ':hover': { opacity: 0.8 }
}));

const ActionItemContainer = styled(Stack)(() => ({
  width: '100%',
  justifyContent: 'space-between',
  flexDirection: 'row',
  alignItems: 'center',
  padding: 6,
  cursor: 'pointer',
  borderTop: `1px solid ${palette.border.grey}`,
  '&:hover': { background: palette.background.rowGrey }
}));



export const StatusSelect = styled(Select)<{
  status?: ActionPlanActionItemDtoStatus;
}>(({ status }) => ({
  width: '80px',
  paddingRight: "0px",


  "& .MuiOutlinedInput-input:read-only": {
    backgroundColor: status === ActionPlanActionItemDtoStatus.completed ? alpha(palette.primary.main, 0.2)
      : status === ActionPlanActionItemDtoStatus.inProgress ? alpha(palette.blue[8], 0.9) : alpha(palette.grey[1400], 0.2),
    paddingTop: "2px",
    paddingBottom: "2px",
    paddingLeft: status === ActionPlanActionItemDtoStatus.inProgress ? "10px" : "2px",
    paddingRight: "0px",
    textAlign: "center",
    borderRadius: '6px',
    textOverflow: "unset",
    ":hover": {
      paddingLeft: status === ActionPlanActionItemDtoStatus.inProgress ? "0px" : "2px",
      paddingRight: status !== ActionPlanActionItemDtoStatus.pending ? "15px" : "0px"
     }
  },

  "&.MuiInputBase-root": {
    padding: "0px"
  },


  "& .MuiSvgIcon-root": {
    right: "0px",
    top: "0px",
    left: "60px",
    widht: "20px",
    height: "20px"
  },

  fontSize: "11px",
  fontFamily: 'Plus Jakarta Sans',
  borderRadius: '6px',
  '.MuiOutlinedInput-notchedOutline': { border: 0, padding: 0 }
}));


export const StatusOption = styled(MenuItem)<{
  color: string;
}>(({ color }) => ({
  color: palette.grey[700],
  height: "25px",
  fontSize: "11px",
  fontFamily: 'Plus Jakarta Sans',
  '&:hover': { backgroundColor: color },
  '&.Mui-selected': {
    backgroundColor: color,
    '&:hover': { backgroundColor: color }
  }
}));

export const ViewArchivedTitle = styled(Typography)({
  fontFamily: "'Plus Jakarta Sans'",
  fontSize: '12px',
  color: palette.common.darkBlue
});


export const DetailsButton = styled(Stack)(() => ({
  width: "22px",
  height: "22px",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "8px",
  cursor: "pointer",
  svg: { path: { fill: palette.grey[600] } },
  padding: 4,
  '&:hover': { backgroundColor: palette.background.paper }
}));


const OwnerTitle = styled(Typography)({
  fontFamily: 'Plus Jakarta Sans',
  fontWeight: 500,
  fontSize: '11px',
  color: palette.grey[1100],
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  maxWidth: '80px'
});

export const ViewTypeText = styled(Typography)(() => ({
  fontFamily: 'Plus Jakarta Sans',
  color: palette.grey[3100],
  fontSize: '13px',
  fontWeight: 700
}));

const ActionItemHeaderContainer = styled(AccordionSummary)(() => ({
  '& .MuiAccordionSummary-content': {
    marginTop: '5px',
    marginBottom: '5px'
  }
}));

const ActionItemHeader = styled(Stack)(() => ({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%'
}));

const DraggableBoxItem = styled(Box, { shouldForwardProp: isPropValid })<{
  hideCompleted?: boolean;
}>(({ hideCompleted }) => ({
  display: hideCompleted && 'none'
}));

const ViewAllButton = styled(Button, { shouldForwardProp: isPropValid })<{
  isViewAll?: boolean;
}>(({ isViewAll }) => ({
  padding: '5px 14px',
  marginTop: '10px',
  width: 'max-content',
  borderRadius: '10px',
  '&:hover': {
    textDecoration: 'none'
  },
  svg: {
    transition: '.3s ease-in-out',
    transform: isViewAll && 'rotate(180deg)'
  }
}));


const InputKeyword = styled(Input)(({ theme }) => ({
  fontSize: '13px',
  padding: '0 10px',
  width: '200px',
  '&.Mui-focused': {
    borderColor: theme.journeyPalette.primary.main
  }
}));

const ActionsContainer = styled(Stack)({
  background: 'white',
  borderRadius: '6px',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer'
});


const ActionItemDescription = styled(InputBase, { shouldForwardProp: isPropValid })<{
  hovered?: boolean;
}>(({ hovered }) => ({
  padding: 0,
  paddingLeft: 3,
  paddingRight: 3,
  border: 'none',
  backgroundColor: hovered ? palette.background.rowGrey : palette.background.default,
  fontFamily: 'Plus Jakarta Sans',
  fontSize: '15px',
  width: "100%",
  '& .MuiInputBase-input': {
    cursor: "pointer",
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  color: palette.common.darkBlue,
  ':focus-within': { border: hovered ? `1px solid ${palette.border.grey}` : 'none', backgroundColor: palette.background.default }
}));


export const TaskViewButton = styled(ToggleButton)(() => ({
  padding:"2px",
  color:alpha(palette.grey[3100],0.5),
  "&.Mui-selected" :{
    backgroundColor:alpha(palette.primary.main, 0.2),
    color:palette.primary.main
  }
}));

export const TaskViewGroup = styled(ToggleButtonGroup)(() => ({
  height:"25px"

}));

export {
  ActionItemContainer,
  ActionItemHeaderContainer,
  ActionItemHeader,
  HeaderBlock,
  HeaderTitleBlock,
  HeaderTitle,
  ActionsEmpty,
  AddActionBlock,
  InitiativeTitleContainer,
  InitiaiveTitle,
  BusinessAreaTitle,
  ActionItemTitle,
  StatusTitle,
  OwnerTitle,
  HeaderButtonBlock,
  ShowCompletedCheckbox,
  HeaderAddBoxIcon,
  DraggableBoxItem,
  ViewAllButton,
  AddActionItemInput,
  ActionItemsSuggestionContainer,

  InputKeyword,
  ActionsContainer,
  ActionItemDescription,

  UsersDivider,
  DueDate,
  DueDateTitle
};
