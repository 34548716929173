import React, { forwardRef } from 'react';

import { InputLabelProps, TextField, TextFieldProps } from '@mui/material';

import typography from '../../../theme/typography';

interface TextAreaProps
  extends Pick<TextFieldProps, 'autoFocus' | 'value' | 'onBlur' | 'onKeyDown' | 'placeholder' | 'rows'> {
  onChange?: (value: string) => void;
  onClick?: () => void;
  onDoubleClick?: () => void;
  stopPropagation?: boolean;
  styles?: { [key: string]: string | number | unknown };
  identifer?: string;
  readOnly?: boolean;
  readonlyCurosr?: string;
  multiline?: boolean;
  maxRows?: number;
  InputLabelProps?: Partial<InputLabelProps>;
  placeholderFontSize?: string;
  focusOnMount?: boolean;
}

const TextArea = forwardRef(function TextArea(
  {
    onChange,
    onClick,
    onDoubleClick,
    stopPropagation,
    value,
    styles,
    identifer,
    readOnly = false,
    multiline = true,
    maxRows,
    InputLabelProps,
    placeholderFontSize,
    readonlyCurosr,
    ...restProps
  }: TextAreaProps,
  ref
) {
  return (
    <TextField
      id={identifer}
      fullWidth
      maxRows={maxRows}
      inputRef={ref}
      inputProps={{ readOnly: readOnly }}
      sx={[
        {
          p: 0,
          verticalAlign: 'baseline',
          '& .Mui-focused': { borderColor: 'transparent !important', borderWidth: 0 },
          '& .MuiInputBase-root': {
            p: 0,
            backgroundColor: 'transparent',
            borderRadius: '3px',
            border: 'unset',
            '&:hover': {
              border: 'unset',
              '& .MuiOutlinedInput-notchedOutline': { borderColor: 'transparent', borderWidth: 0 }
            },
            '& ::placeholder': {
              color: 'grey.600',
              fontSize: placeholderFontSize ? placeholderFontSize : typography.body1,
              opacity: 1
            },
            '&:focus': { border: 'unset', outline: 'unset' },
            '& .MuiOutlinedInput-input': {
              '&:read-only': { backgroundColor: 'transparent', cursor: readonlyCurosr ? readonlyCurosr : 'default' },
              p: 0
            },
            '& .MuiOutlinedInput-notchedOutline': { borderColor: 'transparent', borderWidth: 0, borderStyle: 'none' }
          }
        },
        styles
      ]}
      InputLabelProps={InputLabelProps}
      value={value}
      multiline={multiline}
      onClick={e => {
        stopPropagation && e.stopPropagation();
        onClick && onClick();
      }}
      onChange={e => (readOnly ? null : onChange(e.target.value))}
      onDoubleClick={e => {
        e.stopPropagation();
        onDoubleClick && onDoubleClick();
      }}
      {...restProps}
    />
  );
});

export default TextArea;
