import { Typography, Tabs as TabsComponent, styled, Box, Tab, Stack } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';


export const Container = styled(MuiDrawer)(({ theme }) => ({
  borderLeft: `1px solid ${theme.journeyPalette.border.grey}`,
  boxShadow: '0px 10px 20px rgba(28, 39, 76, 0.03)',
  '>.MuiPaper-root': {
    backgroundColor: theme.journeyPalette.secondary.main
  }
}));

export const TitleText = styled(Typography)(() => ({
  fontWeight: 700,
  fontSize: '18px'
}));

export const Tabs = styled(TabsComponent)({
  width: '100%'
});

export const TabsContainer = styled(Box)({
  borderBottom: 1,
  borderColor: 'divider',
  background: 'white'
});

export const TabContainer = styled(Tab)(({ theme }) => ({
  padding: '14px 25px 22px',
  width: '33%',
  flexDirection: 'row',
  background: theme.journeyPalette.secondary.main,
  alignItems: 'center',
  justifyContent: 'space-between'
}));

export const TabLabel = styled(Stack)({
  width: '100%',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between'
});

export const TabLabelContainer = styled(Stack)({
  flexDirection: 'row',
  alignItems: 'center',
  gap: 3
});

export const TabTitle = styled(Typography)({
  fontFamily: 'Plus Jakarta Sans',
  fontWeight: "bold",
  fontSize: '15px',
  textTransform: 'none',
  marginLeft: '5px'
});
