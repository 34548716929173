import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  AccountUserDto,
  useCreateUser,
  useDeleteUser,
  useListUsers,
  useSendInviteEmail,
  useUpdateUser
} from '../api/generated';

const useUsers = (id?: string, includeStatus?: boolean) => {
  const { data, refetch: refetchUsers, isLoading } = useListUsers({ includeStatus: includeStatus || null });

  // TODO this needs to a be in a hook for a specific component
  const [user, setUser] = useState<AccountUserDto>(null);
  const usersList = useMemo(() => data?.items || [], [data?.items]);

  const { mutateAsync: mutateCreateUser } = useCreateUser();
  const { mutateAsync: mutateUpdateUser } = useUpdateUser();
  const { mutateAsync: mutateDeleteUser } = useDeleteUser();
  const { mutateAsync: mutateSendInviteEmail } = useSendInviteEmail();

  const deleteUser = useCallback(
    async (id: string) => {
      await mutateDeleteUser({ id });
    },
    [mutateDeleteUser]
  );

  const createUser = useCallback(
    async (
      data: Partial<Omit<AccountUserDto, 'id'>>,
      sendInviteEmail = false,
      assigneeUser = false
    ) => {
      await mutateCreateUser({
        data: { ...data, sendInviteEmail: sendInviteEmail },
        params: { assigneeUser: assigneeUser }
      });
    },
    [mutateCreateUser]
  );

  const updateUser = useCallback(
    async (id: string, user: Partial<AccountUserDto>) => {
      await mutateUpdateUser({ id, data: { ...user } });
    },
    [mutateUpdateUser]
  );

  const sendInviteEmail = useCallback(
    async (id: string) => {
      await mutateSendInviteEmail({ id });
    },
    [mutateSendInviteEmail]
  );

  useEffect(() => {
    !isLoading && usersList && setUser(usersList.find(u => u.id === id));
  }, [usersList, id, isLoading]);

  return useMemo(
    () => ({
      usersList,
      refetchUsers,
      isLoading,
      createUser,
      updateUser,
      deleteUser,
      user,
      setUser,
      sendInviteEmail
    }),
    [usersList, refetchUsers, isLoading, createUser, updateUser, deleteUser, user, setUser, sendInviteEmail]
  );
};

export default useUsers;
