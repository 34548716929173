import { LinearProgress, Stack, styled } from '@mui/material';

import palette from '../../theme/palette';

export const GoalProgressContainer = styled(Stack)({
  width: '100%',
  gap: '8px',
  alignItems: 'center',
  flexDirection: 'row'
});

export const LinearGoalProgress = styled(LinearProgress)({
  height: '7px',
  borderRadius: '100px',
  border: `0.3px solid ${palette.grey[1800]}`,
  background: 'white',
  '& .MuiLinearProgress-bar': {
    backgroundColor: palette.green[1]
  },
  minWidth: '90px'
});

export const ProgressTitleContainer = styled(Stack)({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between'
});
