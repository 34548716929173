import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DragDropContext, Draggable, DropResult } from 'react-beautiful-dnd';
import { Box, Checkbox, Collapse, FormControlLabel, List, ListItem, Pagination, Skeleton, Stack, Tooltip } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import sleep from 'sleep-promise';
import ChecklistOutlinedIcon from '@mui/icons-material/ChecklistOutlined';
import ViewKanbanOutlinedIcon from '@mui/icons-material/ViewKanbanOutlined';
import {
  ActionPlanActionItemDto,
  ActionPlanActionItemDtoSprint,
  ActionPlanActionItemDtoStatus
} from '../../api/generated';
import { ReactComponent as EmptyState } from '../../assets/empty-state.svg';
import useModal from '../../hooks/context-providers/useModal/useModal';
import useAuth from '../../hooks/useAuth';
import usePagination from '../../hooks/usePagination';
import { TOAST_VARIANTS } from '../NotificationToast/NotificationToast';
import ChipSelection, { ChipItem } from '../ChipSelection/ChipSelection';
import InitiativeSuggestionCard from '../InitiativeSuggestionCard/InitiativeSuggestionCard';
import { StrictModeDroppable } from '../StrictModeDroppable/StrictModeDroppable';
import SuggestionsButton from '../SuggestionsButton/SuggestionsButton';
import ActionItem from './ActionItem';
import {
  ActionItemsSuggestionContainer,
  ActionsEmpty,
  AddActionItemInput,
  DraggableBoxItem,
  HeaderAddBoxIcon,
  HeaderBlock,
  HeaderButtonBlock,
  HeaderTitle,
  HeaderTitleBlock,
  InputKeyword,
  TaskViewButton,
  TaskViewGroup,
  ViewArchivedTitle,
  ViewTypeText
} from './ActionItemsListStyles';
import ActionItemsSort, { SortType } from './ActionItemsSort/ActionItemsSort';
import useActionPlanObjective from '../../hooks/useActionPlanObjective';
import useActionPlanInitiative from '../../hooks/useActionPlanInitiative';
import useActionPlanActionItem from '../../hooks/useActionPlanActionItem';
import TasksBoard from '../../screens/ActionPlan/components/TasksBoard/TasksBoard';
import UserFilter from '../Filters/UserFilter/UserFilter';
import ActionItemStatusFilter from '../Filters/ActionItemFilters/ActionItemStatusFilter';
import palette from '../../theme/palette';

export enum TasksView {
  LIST = 'List',
  KANBAN = 'Kanban'
}

interface ActionItemsListProps {
  initiativeId?: string;
  userIds?: string[];
  initiativeIds?: string[];
  timeFrame?: { from: number; to: number };
  showStatusFiltering?: boolean;
  showSearch?: boolean;
  disabled?: boolean;
  showSuggestions?: boolean;
  showPagination?: boolean;
  showSort?: boolean;
  showArchivedFilter?: boolean;
  includeArchivedActionItems?: boolean;
  showAddButton?: boolean;
  rowsPerPage?: number;
  refetchData?: () => void;
  objectiveId?: string;
  title?: string;
  objectiveIds?: string[];
  taskList?: ActionPlanActionItemDto[];
  taskListLoading?: boolean;
  oneLineHeader?: boolean;
  includeViewBy?: boolean;
  onActionItemCreated?: (id: string, title: string) => void;
}

const filters = {
  CURRENT: ActionPlanActionItemDtoSprint.current,
  BACKLOG: ActionPlanActionItemDtoSprint.backlog,
  COMPLETED: ActionPlanActionItemDtoStatus.completed
} as const;

const LazyLoadedActionItemModal = React.lazy(
  () => import('../../modals/ActionItemModal/ActionItemModal')
);

const ActionItemsList = ({
  initiativeId,
  userIds,
  initiativeIds,
  timeFrame,
  objectiveIds,
  showStatusFiltering = true,
  disabled,
  showSuggestions = false,
  showSearch = false,
  showPagination = true,
  showSort,
  showArchivedFilter = false,
  showAddButton = true,
  objectiveId,
  includeViewBy = true,
  oneLineHeader,
  taskListLoading,
  taskList,
  onActionItemCreated,
  includeArchivedActionItems,
  refetchData,
  title,
  rowsPerPage = showPagination ? 6 : 1000
}: ActionItemsListProps) => {

  const [includeArchived, setIncludeArchived] = useState<boolean>(includeArchivedActionItems ? includeArchivedActionItems : false);

  useEffect(() => {
    setIncludeArchived(includeArchivedActionItems);
  }, [includeArchivedActionItems, setIncludeArchived]);


  const {
    actionItemsList,
    actionItemsLoading,
    refetchActionItems: refetch,
    createActionPlanActionItem,
    bulkPatchActionPlanActionItems,
    actionItemsSuggestionList,
    actionItemsSuggestionsLoading,
    getactionItemsSuggestions
  } = useActionPlanActionItem(initiativeId || null, timeFrame, includeArchived, userIds || null, initiativeIds || null, !taskList);

  const [actionItems, setActionItems] = useState<ActionPlanActionItemDto[]>([]);

  useEffect(() => {
    if (taskList) {
      setActionItems(taskList);
    }
  }, [taskList, setActionItems]);

  useEffect(() => {
    if (actionItemsList) {
      setActionItems(actionItemsList);
    }
  }, [actionItemsList, setActionItems]);

  useEffect(() => {
    if (taskList) {
      const aiList = includeArchived ? taskList : taskList.filter(ai => !ai.isArchived || ai.isArchived === null);
      setActionItems(aiList);
    }
  }, [includeArchived, setActionItems, taskList]);

  const {
    refetchDetailedObjectives
  } = useActionPlanObjective();

  const {
    initiativeList,
    initiativeListLoading
  } = useActionPlanInitiative();

  const maxDate = Date.parse('01 Jan 4000 00:00:00 GMT');
  const [filteredActionItems, setFilteredActionItems] = useState(actionItems || []);
  const [selectedActionItemOwners, setSelectedActionItemOwners] = useState([]);
  const [selectedActionItemAssignees, setSelectedActionItemAssignees] = useState([]);
  const [activeCategory, setActiveCategory] = useState<string>(filters.CURRENT);
  const [statusFilters, setStatusFilters] = useState<ActionPlanActionItemDtoStatus[]>([]);
  const [categoryHeaders, setCategoryHeaders] = useState({
    [filters.CURRENT]: 'Current',
    [filters.BACKLOG]: 'Backlog',
    [filters.COMPLETED]: 'Completed'
  });
  const actionItemsCategories = useMemo<ChipItem<string>[]>(
    () =>
      Object.values(filters).map(filter => ({
        checked: activeCategory === filter,
        label: categoryHeaders[filter],
        value: filter
      })),
    [categoryHeaders, activeCategory]
  );
  const [keyword, setKeyword] = useState('');
  const { user } = useAuth();

  const [sortType, setSortType] = useState<string>(null);

  const onApplySort = (sortType: string) => {
    setSortType(sortType);
  };

  const { showModal } = useModal();

  const [suggestionListOpen, setSuggestionListOpen] = useState(false);
  const [loadSuggestion, setLoadSuggestion] = useState<boolean>(false);
  const isSuggestionsLoading = actionItemsSuggestionsLoading || loadSuggestion;

  const toggleSuggestionsList = useCallback(() => {
    if (!suggestionListOpen) {
      setLoadSuggestion(true);
      sleep(1000);

      getactionItemsSuggestions().then(() => {
        setLoadSuggestion(false);
        setSuggestionListOpen(prev => !prev);
      });
    } else {
      setSuggestionListOpen(prev => !prev);
    }
  }, [getactionItemsSuggestions, suggestionListOpen]);

  const [newActionItemTitle, setNewActionItemTitle] = useState('');

  const onAddActionItemWithSuggestion = useCallback(
    (suggestedTitle?: string) => {
      const modal = showModal(LazyLoadedActionItemModal, {
        onClose: () => {
          modal.hide();
        },
        onCancel: () => {
          modal.hide();
        },
        onConfirm: async () => {
          modal.hide();
          setSuggestionListOpen(false);
          await refetch();
        },
        suggestedTitle: suggestedTitle,
        sourceIitiativeId: initiativeId,
        hideActionPlan: initiativeId != null
      });
    },
    [showModal, refetch, initiativeId]
  );

  const onAddActionItem = useCallback(() => {
    const modal = showModal(LazyLoadedActionItemModal, {
      onClose: () => {
        modal.hide();
      },
      onCancel: () => {
        modal.hide();
      },
      onConfirm: async (title: string, id: string) => {
        modal.hide();
        onActionItemCreated && onActionItemCreated(id, title);
        refetchDetailedObjectives();
        !taskList && await refetch();
        enqueueSnackbar({
          variant: 'custom',
          customProps: {
            caption: 'Task successfully created',
            variant: TOAST_VARIANTS.SUCCESS
          }
        });
      },
      objectiveIds: objectiveIds,
      initiativeIds: initiativeIds || null,
      sourceIitiativeId: initiativeId,
      hideActionPlan: initiativeId != null || !!initiativeIds,
      actionPlanOpen: !!objectiveIds

    });
  }, [showModal, refetch, taskList, initiativeIds, initiativeId, refetchDetailedObjectives, objectiveIds, onActionItemCreated]);

  const patchActionItemListOrder = useCallback(
    (dropResult: DropResult) => {
      const startIndex = dropResult.source.index;
      const endIndex = dropResult.destination.index;
      const actionsDraft = [...filteredActionItems];

      const [removedObjective] = actionsDraft.splice(startIndex, 1);
      actionsDraft.splice(endIndex, 0, removedObjective);
      const newOrder = actionsDraft.map((item, index) => ({ ...item, index: index }));

      const newActionsList = filteredActionItems.map(item => {
        const newItem = newOrder.find(result => item.id === result.id);
        return item.id === newItem?.id
          ? { id: newItem?.id, index: newItem.index }
          : { id: item.id, index: item.index };
      });

      setFilteredActionItems(actionsDraft);
      bulkPatchActionPlanActionItems(newActionsList).then(() => refetch());
    },
    [filteredActionItems, bulkPatchActionPlanActionItems, refetch]
  );


  const { begin, currentPage, end, jump, maxPage } = usePagination(
    filteredActionItems,
    rowsPerPage
  );

  const [tasksView, setTasksView] = useState<TasksView>(TasksView.LIST);


  const filter = useCallback(
    (actionItems: ActionPlanActionItemDto[], activeCategory: string) => {
      let filtered = actionItems;

      if (showStatusFiltering && tasksView === TasksView.LIST) {
        filtered = filtered.filter(
          actionItem =>
            activeCategory === actionItem.status ||
            (activeCategory === actionItem.sprint &&
              actionItem.status !== ActionPlanActionItemDtoStatus.completed)
        );
      }

      if (statusFilters.length) {
        filtered = filtered.filter(action => statusFilters.includes(action.status));
      }


      if (keyword) {
        filtered = filtered.filter(item =>
          item.title.toLocaleLowerCase().includes(keyword.toLocaleLowerCase())
        );
      }


      if (selectedActionItemOwners.length) {
        filtered = filtered.filter(action => selectedActionItemOwners.includes(action.ownerId));
      }

      if (selectedActionItemAssignees.length) {
        filtered = filtered.filter(action =>
          selectedActionItemAssignees.includes(action.assigneeId)
        );
      }


      if (!initiativeId) {
        filtered = filtered.filter(action => !action.initiativeId);
      }

      return filtered;
    },
    [
      selectedActionItemOwners,
      selectedActionItemAssignees,
      showStatusFiltering,
      statusFilters,
      keyword,
      tasksView,
      initiativeId
    ]
  );

  const sortActionItem = (a: ActionPlanActionItemDto, b: ActionPlanActionItemDto) => {
    if (sortType) {
      return sortType === SortType.DUE_DATE
        ? (a.dueDate ? Date.parse(a.dueDate) : maxDate) -
        (b.dueDate ? Date.parse(b.dueDate) : maxDate)
        : new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
    } else {
      return a.index === b.index
        ? new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        : a.index - b.index;
    }
  };

  useEffect(() => {
    jump(currentPage);
    setFilteredActionItems(filter(actionItems, activeCategory));
  }, [
    keyword,
    actionItems,
    activeCategory,
    selectedActionItemOwners,
    currentPage,
    filter,
    jump
  ]);

  const handleChange = (_e, p: number) => {
    jump(p);
  };

  useEffect(() => {
    if (!filteredActionItems) {
      return;
    }

    const backlogCount = filter(actionItems, ActionPlanActionItemDtoSprint.backlog).length;

    const todoCount = filter(actionItems, ActionPlanActionItemDtoSprint.current).length;

    const completedCount = filter(actionItems, ActionPlanActionItemDtoStatus.completed).length;

    setCategoryHeaders({
      backlog: `Backlog (${backlogCount})`,
      completed: `Completed (${completedCount})`,
      current: `To do (${todoCount})`
    });
  }, [filteredActionItems, actionItems, filter]);

  const categoryOnChange = useCallback(selectedCategory => {
    setActiveCategory(selectedCategory);
  }, []);

  const onAddActionItemRow = async () => {
    const currentSprint = ActionPlanActionItemDtoSprint[activeCategory];
    const data: Partial<ActionPlanActionItemDto> = {
      title: newActionItemTitle,
      sprint: currentSprint,
      index: 0,
      ownerId: user.id,
      initiativeId: initiativeId || null
    };
    const created = await createActionPlanActionItem(data);
    refetchDetailedObjectives();
    !taskList && refetch();
    onActionItemCreated && onActionItemCreated(created.id, created.title);
    (refetchData && !taskList) && refetchData();
    setNewActionItemTitle('');
    enqueueSnackbar({
      variant: 'custom',
      customProps: {
        caption: 'Task successfully created',
        variant: TOAST_VARIANTS.SUCCESS
      }
    });
  };

  const onKeyDown = async event => {
    if (event.key === 'Enter') {
      await onAddActionItemRow();
    }
  };

  const drageDisabled = sortType != null;




  const onTaskViewChange = (
    event: React.MouseEvent<HTMLElement>,
    newValue: TasksView
  ) => {
    setTasksView(newValue);
  };

  return (
    <>
      {actionItemsLoading || taskListLoading ? (
        ['i', 'am', 'a', 'skeleton', 'element', '!'].map((_, index) => (
          <Skeleton
            key={index}
            sx={{ marginTop: 1 }}
            variant="rounded"
            height={25} />
        ))
      ) : (
        <Stack>
          {title !== '' && <HeaderTitleBlock>

            <HeaderTitle>{title}</HeaderTitle>
          </HeaderTitleBlock>}


          {oneLineHeader ? (
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{
                width: "100%",
                backgroundColor: palette.background.rowGrey,
                height: "42px",
                padding: "4px 10px"
              }}
              marginBottom="10px">


              <HeaderButtonBlock>
                <UserFilter
                  title='Owner'
                  options={taskList && [...new Set(taskList?.filter(task => task.ownerId).map(task => task.ownerId))]}
                  appliedUsers={selectedActionItemOwners}
                  onApplyUsers={(userIds: string[]) => setSelectedActionItemOwners(userIds)}
                />

                <UserFilter
                  title='Assignee'
                  includeAssignee
                  appliedUsers={selectedActionItemAssignees}
                  onApplyUsers={(userIds: string[]) => setSelectedActionItemAssignees(userIds)}
                />


                <ActionItemStatusFilter
                  appliedStatues={statusFilters}
                  onApplyStatues={(statues: ActionPlanActionItemDtoStatus[]) => setStatusFilters(statues)}
                />

                {showSort &&
                  <ActionItemsSort appliedSort={sortType} onApplySort={onApplySort} />}

                {includeViewBy &&
                  <Stack
                    paddingLeft="5px"
                    direction="row"
                    alignItems="center"
                    gap="4px"
                  >
                    <ViewTypeText>View:</ViewTypeText>
                    <TaskViewGroup
                      value={tasksView}
                      exclusive
                      onChange={onTaskViewChange}
                    >
                      <TaskViewButton
                        value={TasksView.LIST}>
                        <Tooltip
                          title="List view">
                          <ChecklistOutlinedIcon sx={{ width: "22px", height: "20px" }} />
                        </Tooltip>
                      </TaskViewButton>
                      <TaskViewButton
                        value={TasksView.KANBAN}>
                        <Tooltip
                          title="Board view">
                          <ViewKanbanOutlinedIcon sx={{ width: "22px", height: "20px" }} />
                        </Tooltip>
                      </TaskViewButton>
                    </TaskViewGroup>

                  </Stack>}


              </HeaderButtonBlock>



              <HeaderButtonBlock>

                {showSearch && (
                  <InputKeyword
                    disableUnderline
                    placeholder="Search Tasks"
                    onChange={event => setKeyword(event.target.value)}
                  />
                )}
                {!initiativeId && showAddButton && <Tooltip title="Add task">

                  <HeaderAddBoxIcon onClick={onAddActionItem} />
                </Tooltip>}
              </HeaderButtonBlock>
            </Stack>

          ) : (

            <>

              {!initiativeId && (
                <HeaderBlock
                  direction={userIds ? "row-reverse" : "row"}>
                  {!userIds && <HeaderTitleBlock>

                    <HeaderTitle>{title !== undefined ? title : "Tasks"}</HeaderTitle>
                  </HeaderTitleBlock>}

                  <HeaderButtonBlock
                  >

                    {includeViewBy && <TaskViewGroup
                      value={tasksView}
                      exclusive
                      onChange={onTaskViewChange}
                    >
                      <TaskViewButton
                        value={TasksView.LIST}>
                        <Tooltip
                          title="List view">
                          <ChecklistOutlinedIcon sx={{ width: "25px", height: "22px" }} />
                        </Tooltip>
                      </TaskViewButton>
                      <TaskViewButton
                        value={TasksView.KANBAN}>
                        <Tooltip
                          title="Board view">
                          <ViewKanbanOutlinedIcon sx={{ width: "25px", height: "25px" }} />
                        </Tooltip>
                      </TaskViewButton>
                    </TaskViewGroup>}
                    {showSearch && (
                      <InputKeyword
                        disableUnderline
                        placeholder="Search Tasks"
                        onChange={event => setKeyword(event.target.value)}
                      />
                    )}
                    {!initiativeId && showAddButton && <HeaderAddBoxIcon onClick={onAddActionItem} />}
                  </HeaderButtonBlock>
                </HeaderBlock>
              )}

              {(showStatusFiltering || (!disabled && showSuggestions)) && (
                <Stack
                  direction={showStatusFiltering ? "row" : "row-reverse"}
                  alignItems="center"
                  justifyContent={tasksView === TasksView.LIST ? "space-between" : "end"}
                  marginBottom="10px"
                  marginTop="5px"
                >

                  {showStatusFiltering && tasksView === TasksView.LIST && (
                    <HeaderButtonBlock>
                      <ChipSelection
                        hideCheckoutMark={true}
                        items={actionItemsCategories}
                        onChange={categoryOnChange}
                      />
                    </HeaderButtonBlock>
                  )}

                  <Stack
                    direction="row"
                    alignItems="center"
                    gap={1}>

                    {!disabled && (
                      <HeaderButtonBlock
                      >

                        {showArchivedFilter &&
                          <FormControlLabel
                            control={<Checkbox
                              size='small'
                              sx={{ width: "25px" }}
                              checked={includeArchived}
                              onChange={() => setIncludeArchived(!includeArchived)}
                            />} label={<ViewArchivedTitle>Archived</ViewArchivedTitle>} />}

                        {showSuggestions && (
                          <SuggestionsButton
                            opened={suggestionListOpen}
                            isLoading={isSuggestionsLoading}
                            onClick={toggleSuggestionsList}
                          />
                        )}



                        {showSort && (
                          <Stack direction="row" alignItems="center" gap={1}>
                            <ActionItemsSort appliedSort={sortType} onApplySort={onApplySort} />
                          </Stack>
                        )}
                        {initiativeId && showAddButton && <HeaderAddBoxIcon onClick={onAddActionItem} />}
                      </HeaderButtonBlock>
                    )}


                  </Stack>
                </Stack>
              )}

              {suggestionListOpen && !actionItemsSuggestionsLoading && (
                <Collapse in={suggestionListOpen && !actionItemsSuggestionsLoading}>
                  <ActionItemsSuggestionContainer padding="5px">
                    {actionItemsSuggestionList?.map((suggestion, index) => (
                      <InitiativeSuggestionCard
                        key={index}
                        title={suggestion}
                        onAddSuggestion={() => onAddActionItemWithSuggestion(suggestion)}
                      />
                    ))}
                  </ActionItemsSuggestionContainer>
                </Collapse>
              )}

            </>)}



          {tasksView === TasksView.LIST ?
            <>
              <List disablePadding>
                <DragDropContext onDragEnd={dropResult => patchActionItemListOrder(dropResult)}>
                  <StrictModeDroppable droppableId="ap-action-items">
                    {objDroppableprovided => (
                      <Box ref={objDroppableprovided.innerRef} {...objDroppableprovided.droppableProps}>
                        {(filteredActionItems)
                          .sort((a, b) => sortActionItem(a, b))
                          .map(
                            (actionItem, index) =>
                              index >= begin &&
                              index < end && (
                                <Draggable
                                  isDragDisabled={drageDisabled}
                                  key={actionItem.id}
                                  draggableId={actionItem.id}
                                  index={index}
                                >
                                  {provided => (
                                    <Stack
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <DraggableBoxItem>
                                        <ListItem
                                          key={`${actionItem.id}${actionItem.index ? actionItem.index : index
                                            }`}
                                          disablePadding
                                        >
                                          {!initiativeListLoading && (
                                            <ActionItem
                                              initiative={actionItem.initiativeId ? initiativeList?.find(ini => ini.id === actionItem.initiativeId) : null}
                                              objectiveId={objectiveId}
                                              disableDrag={drageDisabled}
                                              moveAction={showStatusFiltering}
                                              key={actionItem.id}
                                              actionItem={actionItem}
                                              refetch={() => {
                                                refetch();
                                                refetchData && refetchData();
                                              }}
                                            />
                                          )}
                                        </ListItem>
                                      </DraggableBoxItem>
                                    </Stack>
                                  )}
                                </Draggable>
                              )
                          )}
                      </Box>
                    )}
                  </StrictModeDroppable>
                </DragDropContext>
              </List>
              {disabled && actionItems?.length == 0 && (
                <ActionsEmpty>
                  <EmptyState />
                </ActionsEmpty>
              )}
              {!disabled && (
                <AddActionItemInput
                  autoComplete="off"
                  sx={{ marginBottom: '10px', paddingLeft: '4%' }}
                  name="addActionItemInput"
                  value={newActionItemTitle}
                  placeholder="Type and enter new task..."
                  onChange={event => setNewActionItemTitle(event.target.value)}
                  onKeyDown={onKeyDown}
                  disableUnderline
                />
              )}
              {showPagination && (
                <Stack direction="row" alignItems="center" justifyContent="end" mt="4px">
                  <Pagination size="small" count={maxPage} page={currentPage} onChange={handleChange} />
                </Stack>
              )}
            </>

            :
            <TasksBoard
              actionItemsList={filteredActionItems}
              actionItemsLoading={actionItemsLoading}
              sortType={sortType} />
          }
        </Stack >
      )}
    </>
  );
};

export default ActionItemsList;
