import React, { MouseEvent, useCallback, useState } from 'react';
import ConfettiExplosion from 'react-confetti-explosion';
import { useNavigate, useParams } from 'react-router-dom';

import { DeleteOutline } from '@mui/icons-material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { CircularProgress, MenuItem, SelectChangeEvent, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useDebouncedCallback } from 'use-debounce';

import {
  ActionPlanInitiativeDtoPriority,
  ActionPlanInitiativeDtoStatus,
  AttachmentDto,
  Timeframe
} from '../../api/generated';
import useInitiativeSidebar from '../../hooks/context-providers/useInitiativeSidemenu';
import useModal from '../../hooks/context-providers/useModal/useModal';
import useInitiativeComments from '../../hooks/useInitiativeComments';
import AppPopover from '../../screens/ActionPlan/components/AppPopover/AppPopover';
import TimeRangePopover from '../../screens/ActionPlan/components/TimeRangePopover/TimeRangePopover';
import { getDueDateRange } from '../../utils/actionPlanUtil';
import ActionItemsList from '../ActionItemsList/ActionItemsList';
import { InitiativeGoalAndTimeRangeTitle } from '../InitiativesCard/InitiativesCardStyles';
import OwnerAndAssignee from '../OwnerAndAssignee/OwnerAndAssignee';


import InitiativePriority from './InitiativePriority/InitiativePriority';
import { ArrowDownIcon } from './InitiativePriority/InitiativePriorityStyles';
import {
  InitiativePropertiesContainer,
  InitiativeSidebarContainer,
  InitiativeInformation,
  InitiativeGoalAndTimeRange,
  InitiativeTimeFrame,
  StatusDropDownSelect,

  InitiativeInfoContainer,
  ValueInput,
  InitiativeActions,
  ActionsContainer,
  MoreHorizIcon,
  CloseIcon,
  DescriptionEdtiorContainer,
  GoalSection,
  SectionTitle
} from './InitiativeSidebarStyles';
import InitiativeStatusLabel from './InitiativeStatusLabel/InitiativeStatusLabel';

import { toActionPlan } from '../../services/linker';
import useAuth from '../../hooks/useAuth';
import Editor from '../../screens/BizAreaModules/components/Editor';
import AddUpdate from '../AddUpdate/AddUpdate';
import CommentsList from '../CommentsList/CommentsList';
import palette from '../../theme/palette';
import useActionPlanObjective from '../../hooks/useActionPlanObjective';
import GoalView from '../GoalView /GoalView';
import useActionPlanInitiative from '../../hooks/useActionPlanInitiative';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import UnarchiveOutlinedIcon from '@mui/icons-material/UnarchiveOutlined';
import useActionPlanArchivedContext from '../../hooks/context-providers/useActionPlanArchivedContext';
import Attachments from '../Attachments/Attachments';
import TeamsSelect from '../Team/TeamsSelect';

const LazyLoadedAreYouSureModal = React.lazy(
  () => import('../../modals/AreYouSureModal/AreYouSureModal')
);


const InitiativeSidebar = () => {
  const theme = useTheme();
  const {
    open,
    currentInitiative,
    setOpen,
    currentObjective,
    setCurrentInitiative,
    setCurrentObjective,
    refetch: refetchData
  } = useInitiativeSidebar();
  const [titleValue, setTitleValue] = useState(
    currentInitiative?.title
  );
  const [description, setDescription] = useState<string>(currentInitiative?.description);
  const { showModal } = useModal();
  const [rangePopoverAnchor, setRangePopoverAnchor] = useState(null);
  const rangePopoverOpen = !!rangePopoverAnchor;

  const [teamIds, setTeamIds] = useState<string[]>(currentInitiative?.teamIds || []);

  const [isExploding, setIsExploding] = useState(false);
  const { initiativeId } = useParams();
  const { includeArchived, initiativeIncludeArchived } = useActionPlanArchivedContext();
  const { detailedObjective, refetchDetailedObjective } = useActionPlanObjective(currentObjective?.id, null, null, null, initiativeIncludeArchived);
  const { refetchDetailedObjectives } = useActionPlanObjective(null, null, null, null, includeArchived);


  const initiativeProgress = detailedObjective?.initiatives?.find(ini => ini.id === currentInitiative.id)?.goalProgress;

  const { updateActionPlanInitiative, patchActionPlanInitiative, deleteActionPlanInitiative, refetchInitiatives } = useActionPlanInitiative(null, includeArchived);
  const {
    initiativeComments,
    createInitiativeComment,
    deleteInitiativeComment,
    patchInitiativeComment,
    refetchComments
  } = useInitiativeComments(currentInitiative?.id);



  const { user } = useAuth();

  const refetch = useCallback(() => {
    refetchDetailedObjectives();
    refetchDetailedObjective();
    refetchInitiatives();
    refetchData && refetchData();
  }, [
    refetchDetailedObjectives,
    refetchDetailedObjective,
    refetchInitiatives,
    refetchData
  ]);



  const createComment = async (content: string, taggedUserIds: string[]) => {
    await createInitiativeComment({
      userId: user.id,
      content: content,
      initiativeId: currentInitiative.id,
      taggedUserIds: taggedUserIds
    });
  }


  const debouncedPatchInitiativeTitle = useDebouncedCallback((title: string) => {
    patchActionPlanInitiative(currentInitiative.id, { title }).then(() =>
      refetch()
    );
  }, 500);


  const onTeamsSelected = (teamIds: string[]) => {
    setTeamIds(teamIds);
    patchActionPlanInitiative(currentInitiative.id, { teamIds: teamIds }).then(() =>
      refetch()
    );
  }

  const deleteInitiativeClick = useCallback(() => {
    const modal = showModal(LazyLoadedAreYouSureModal, {
      onClose: () => {
        setEditAndDeleteAnchorEl(null);
        modal.hide();
      },
      onConfirm: (deleteMetric: boolean) => {
        deleteActionPlanInitiative(currentInitiative.id, deleteMetric)
          .then(() => refetch())
          .finally(() => setOpen(false));
        setEditAndDeleteAnchorEl(null);
        modal.hide();
      },
      ...(currentInitiative.goal && {
        additionalProprty: {
          title: "Delete Goal metric",
          defaultValue: true
        }
      }),
      modalTitle: `Are you sure you want to delete \n"${currentInitiative.title}"?`,
      confirmButtonIcon: <DeleteOutline />,
      confirmButtonTitle: 'Delete'
    });
  }, [
    currentInitiative,
    deleteActionPlanInitiative,
    refetch,
    setOpen,
    showModal
  ]);

  const navigate = useNavigate();

  const onClose = useCallback(() => {
    setCurrentInitiative(null);
    setCurrentObjective(null);
    setOpen(false);

    initiativeId && navigate(toActionPlan());
  }, [setOpen, setCurrentInitiative, setCurrentObjective, initiativeId, navigate]);



  const archiveInitiativeClick = useCallback(() => {
    const modal = showModal(LazyLoadedAreYouSureModal, {
      onClose: () => {
        setEditAndDeleteAnchorEl(null);
        modal.hide();
      },
      onConfirm: () => {
        patchActionPlanInitiative(currentInitiative.id, { isArchived: true }).then(() => refetch());
        setEditAndDeleteAnchorEl(null);
        modal.hide();
        onClose();
      },
      modalTitle: `Are you sure you want to archive \n"${currentInitiative.title}"?`,
      confirmButtonIcon: <ArchiveOutlinedIcon />,
      confirmButtonTitle: 'Archive'
    });
  }, [onClose, patchActionPlanInitiative, currentInitiative, refetch, showModal]);

  const unarchiveInitiativeClick = useCallback(() => {
    const modal = showModal(LazyLoadedAreYouSureModal, {
      onClose: () => {
        setEditAndDeleteAnchorEl(null);
        modal.hide();
      },
      onConfirm: () => {
        patchActionPlanInitiative(currentInitiative.id, { isArchived: false }).then(() => refetch());
        setEditAndDeleteAnchorEl(null);
        modal.hide();
        onClose();
      },
      modalTitle: `Are you sure you want to unarchive \n"${currentInitiative.title}"?`,
      confirmButtonIcon: <UnarchiveOutlinedIcon />,
      confirmButtonTitle: 'Unarchive'
    });
  }, [onClose, patchActionPlanInitiative, currentInitiative, refetch, showModal]);

  const [editAndDeleteAnchorEl, setEditAndDeleteAnchorEl] = React.useState<null | HTMLElement>(
    null
  );
  const editAndDeletePopoverOpen = Boolean(editAndDeleteAnchorEl);





  const onClickActions = (event: MouseEvent<HTMLDivElement>) => {
    setEditAndDeleteAnchorEl(event.currentTarget);
  };

  const onChangeTitle = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setTitleValue(event.currentTarget.value);
    debouncedPatchInitiativeTitle(event.currentTarget.value);
  };

  const onBlurTitle = () => {
    patchActionPlanInitiative(currentInitiative.id, {
      title: titleValue
    }).then(() => refetch());
  };

  const onSelectPriority = (priority: ActionPlanInitiativeDtoPriority) => {
    setPriority(priority);
    patchActionPlanInitiative(currentInitiative.id, {
      priority: priority
    }).then(() => refetch());
  };

  const onChangeTimeframe = (timeframe: Timeframe) => {
    setTimeframe(timeframe);
    patchActionPlanInitiative(currentInitiative.id, {
      timeframe: timeframe
    }).then(() => refetch());
  };

  const [status, setStatus] = useState<ActionPlanInitiativeDtoStatus>(currentInitiative.status);
  const [priority, setPriority] = useState<ActionPlanInitiativeDtoPriority>(
    currentInitiative.priority
  );
  const [timeframe, setTimeframe] = useState<Timeframe>(currentInitiative.timeframe);

  const [ownerId, setOwnerId] = useState<string>(currentInitiative.ownerId);
  const [assigneeId, setAssigneeId] = useState<string>(currentInitiative.assigneeId);

  const updateOwnerId = (userId: string) => {
    setOwnerId(userId);
    if (userId) {
      patchActionPlanInitiative(currentInitiative.id, { ownerId: userId }).then(() =>
        refetch()
      );
    } else {
      updateActionPlanInitiative(currentInitiative.id, {
        ...currentInitiative,
        ownerId: null
      }).then(() => refetch());
    }
  };

  const updateAssigneeId = (userId: string) => {
    setAssigneeId(userId);
    if (userId) {
      patchActionPlanInitiative(currentInitiative.id, { assigneeId: userId }).then(() =>
        refetch()
      );
    } else {
      updateActionPlanInitiative(currentInitiative.id, {
        ...currentInitiative,
        assigneeId: null
      }).then(() => refetch());
    }
  };

  const [descriptionUpdating, setDescriptionUpdating] = useState<boolean>(false);

  const updateDescription = useDebouncedCallback(
    (text: string) =>
      patchActionPlanInitiative(currentInitiative.id, { description: text }).then(() => {
        refetch();
        setDescriptionUpdating(false);
      }

      ),
    1000
  );

  const [attachments, setAttachments] = useState<AttachmentDto[]>(currentInitiative.attachments || []);

  const addAttachment = (name: string) => {
    patchActionPlanInitiative(currentInitiative.id, { attachments: [...attachments, { name }] }).then(() => {
      setAttachments([...attachments, { name }]);
      refetch();
    }
    );

  }

  const deleteAttachment = (name: string) => {
    const newList = attachments?.filter(att => att.name !== name);
    setAttachments(newList);
    patchActionPlanInitiative(currentInitiative.id, { attachments: newList }).then(() => refetch());
  }


  return (
    <InitiativeSidebarContainer anchor="right" open={open} onClose={onClose} theme={theme}>

      <InitiativeInformation>

        <InitiativePropertiesContainer>
          <Stack
            width="100%"
            gap="3px">
            <InitiativeInfoContainer>
              <ValueInput
                multiline
                defaultValue={currentInitiative?.title}
                onChange={onChangeTitle}
                placeholder="Type an initiative name"
                onBlur={onBlurTitle}
              />
              <InitiativeActions>
                <ActionsContainer onClick={onClickActions}>
                  <MoreHorizIcon />
                </ActionsContainer>
                <ActionsContainer>
                  <CloseIcon onClick={onClose} />
                </ActionsContainer>
              </InitiativeActions>
            </InitiativeInfoContainer>


            <Stack
              width="100%"
              direction="row"
              gap={1}
              justifyContent="space-between"
              alignItems="center">

              <DescriptionEdtiorContainer
                width="95%">
                <Editor
                  showBorder={false}
                  value={description}
                  defaultValue={description}
                  onChange={content => {
                    setDescription(content);
                    setDescriptionUpdating(true);
                    updateDescription(content);
                  }}
                  placeholder='Add a description...'
                />
              </DescriptionEdtiorContainer>

              {descriptionUpdating && <CircularProgress size={20} />}
            </Stack>

            <Stack
              paddingLeft="15px"
              paddingRight="10px"
              marginTop={1}
              width="100%"
              gap={2}
              alignItems="start">
              <GoalSection
              >
                <GoalView
                  businessArea={currentObjective?.businessArea}
                  refetch={refetch}
                  initiative={currentInitiative}
                  setCurrentInitiative={setCurrentInitiative}
                  progress={initiativeProgress ? initiativeProgress : currentInitiative.goalProgress}
                />

              </GoalSection>

              <Stack

                width="100%"
                justifyContent="space-between"
                direction="row"
                alignItems="center">

                <OwnerAndAssignee
                  size='30px'
                  onlyAvatar
                  sx={{ gap: "15px" }}
                  ownerId={ownerId}
                  updateOwnerId={updateOwnerId}
                  assigneeId={assigneeId}
                  updateAssigneeId={updateAssigneeId}
                />
                <TeamsSelect
                  size='30px'
                  tooltip
                  teamIds={teamIds}
                  onChange={onTeamsSelected} />
                <Stack
                  direction="row"
                  alignItems="center"
                  gap="5px"
                >

                  <StatusDropDownSelect
                    IconComponent={KeyboardArrowDownIcon}
                    value={status}
                    onChange={(event: SelectChangeEvent<ActionPlanInitiativeDtoStatus>) => {
                      setStatus(event.target.value as ActionPlanInitiativeDtoStatus);
                      patchActionPlanInitiative(currentInitiative.id, {
                        status: event.target.value as ActionPlanInitiativeDtoStatus
                      })
                        .then(() => refetch())
                        .finally(
                          () =>
                            event.target.value === ActionPlanInitiativeDtoStatus.completed &&
                            setIsExploding(true)
                        );
                    }}
                  >
                    {Object.keys(ActionPlanInitiativeDtoStatus).map(
                      (status: keyof typeof ActionPlanInitiativeDtoStatus) => (
                        <MenuItem key={status} value={status}>
                          <InitiativeStatusLabel status={status} key={status} />
                        </MenuItem>
                      )
                    )}
                  </StatusDropDownSelect>

                  {isExploding && (
                    <ConfettiExplosion
                      duration={2500}
                      zIndex={9999}
                      onComplete={() => setIsExploding(false)}
                      particleCount={200}
                    />
                  )}

                  {currentInitiative?.priority && (
                    <InitiativePriority priority={priority} onSelection={onSelectPriority} />
                  )}

                  {currentInitiative?.timeframe && (
                    <InitiativeTimeFrame>
                      <InitiativeGoalAndTimeRange
                        onClick={event => setRangePopoverAnchor(event.currentTarget)}
                      >
                        <InitiativeGoalAndTimeRangeTitle>
                          {getDueDateRange(timeframe)}
                        </InitiativeGoalAndTimeRangeTitle>
                        <ArrowDownIcon open={rangePopoverOpen} />
                      </InitiativeGoalAndTimeRange>
                      <TimeRangePopover
                        timeframe={timeframe}
                        onChangeTimeframe={onChangeTimeframe}
                        anchorEl={rangePopoverAnchor}
                        open={rangePopoverOpen}
                        handleClose={() => setRangePopoverAnchor(null)}
                      />
                    </InitiativeTimeFrame>
                  )}
                </Stack>

              </Stack>






            </Stack>

          </Stack>

        </InitiativePropertiesContainer>

        <Stack
          height="100%"
          gap={3}
        >
          <Stack
            paddingRight={1}>
            <SectionTitle>Tasks</SectionTitle>
            <Stack
              paddingLeft={1}>
              <ActionItemsList
                refetchData={refetch}
                includeArchivedActionItems={initiativeIncludeArchived}
                showArchivedFilter
                initiativeId={currentInitiative.id}
                showPagination={false}
                showStatusFiltering={false}
                showSuggestions={true} />
            </Stack>
          </Stack>

          <Stack
            marginTop={2}
            gap={2}
          >
            <SectionTitle>Attachments</SectionTitle>
            <Attachments
              parnetId={currentInitiative.id}
              attachments={attachments}
              onUpload={addAttachment}
              onDelete={name => deleteAttachment(name)} />
          </Stack>

          <Stack
            minHeight="500px"
            marginTop={2}
            sx={{ backgroundColor: palette.grey[1500] }}>
            <SectionTitle>Comments</SectionTitle>
            <AddUpdate
              refetch={refetchComments}
              createComment={createComment}
            />
            <Stack
            paddingLeft="10px">

            
            <CommentsList
              patchComment={patchInitiativeComment}
              comments={initiativeComments}
              deleteComment={deleteInitiativeComment}
              refetchComments={refetchComments}
            />
            </Stack>
          </Stack>

        </Stack>

      </InitiativeInformation>
      <AppPopover
        anchorEl={editAndDeleteAnchorEl}
        open={editAndDeletePopoverOpen}
        handleClose={() => setEditAndDeleteAnchorEl(null)}
        onDelete={deleteInitiativeClick}
        customActions={[
          {
            title: currentInitiative.isArchived ? "Unarchive" : "Archive",
            icon: currentInitiative.isArchived ? <UnarchiveOutlinedIcon fontSize='small' /> : <ArchiveOutlinedIcon fontSize='small' />,
            onAction: currentInitiative.isArchived ? unarchiveInitiativeClick : archiveInitiativeClick
          }
        ]}
      />
    </InitiativeSidebarContainer>
  );
};

export default InitiativeSidebar;
