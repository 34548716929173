import React, { useCallback, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import { IconButton, Popover, Stack, Tooltip } from '@mui/material';
import { enqueueSnackbar } from 'notistack';

import { SharedWorkspaceDto, SharedWorkspacePointDto } from '../../../api/generated';
import useModal from '../../../hooks/context-providers/useModal/useModal';
import useSharedWorkspaceSidemenu from '../../../hooks/context-providers/useSharedWorkspaceSidemenu';
import useActionPlanActionItem from '../../../hooks/useActionPlanActionItem';
import useSharedWorkspacePoints from '../../../hooks/useSharedWorkspacePoints';
import useSharedWorkspaceUpdates from '../../../hooks/useSharedWorkspaceUpdates';
import AppPopover from '../../../screens/ActionPlan/components/AppPopover/AppPopover';
import { TOAST_VARIANTS } from '../../NotificationToast/NotificationToast';
import { toSharedWorkspace } from '../../../services/linker';
import palette from '../../../theme/palette';
import { PointInput, SpaceName, SpaceNameContainer } from '../PointsOfDiscussionStyles';

const LazyLoadedActionItemModal = React.lazy(
  () => import('../../../modals/ActionItemModal/ActionItemModal')
);

const LazyLoadedEditInitiativeModal = React.lazy(
  () => import('../../../modals/InitiativeModal/InitiativeModal')
);

const updateOrDeleteError = `oops, something went wrong, please contact us!`;
const emptyContentError = 'Please write something first';

const PointOfDiscussion = ({
  point,
  sharedWorkspaceId,
  sharedWorkspaces,
  collaboratorUserIds,
  objectiveIds,
  refetch,
  enableAddToNotes,
  initiativeIds,
  dragable
}: {
  point: SharedWorkspacePointDto;
  sharedWorkspaceId: string;
  sharedWorkspaces?: SharedWorkspaceDto[];
  collaboratorUserIds?: string[];
  initiativeIds?: string[];
  objectiveIds?: string[];
  refetch?: () => void;
  enableAddToNotes?: boolean;
  dragable?: boolean;
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);
  const [pointContentDraft, setPointContentDraft] = useState(point.content || '');
  const { patchPoint, deletePoint, refetchPoints, addStarredPoint, removeStarredPoint } = useSharedWorkspacePoints(sharedWorkspaceId);
  const { createUpdate, refetechUpdates } = useSharedWorkspaceUpdates(sharedWorkspaceId);
  const [hovered, setHovered] = useState(false);
  const { showModal } = useModal();
  const { refetchActionItems } = useActionPlanActionItem(
    null,
    null,
    null,
    collaboratorUserIds || null,
    initiativeIds || null
  );

  const { setNotesPoint } = useSharedWorkspaceSidemenu();

  const renderErrorToast = useCallback((message: string) => {
    enqueueSnackbar({
      variant: 'custom',
      customProps: {
        caption: message,
        variant: TOAST_VARIANTS.ERROR
      }
    });
  }, []);

  const updatePoint = () => {
    patchPointTriggered();
    pointRef?.current?.children?.[0].blur();
  };

  const onPointKeyDown = async event => {
    if (event.key === 'Enter') {
      updatePoint();
    }
  };

  const createUpdateAndDeletePoint = useCallback(
    (content: string) => {
      createUpdate({ sharedWorkspaceId: sharedWorkspaceId, content: content }).then(() => {
        deletePoint(point.id).then(() => {
          refetchPoints();
          refetechUpdates();
          refetch && refetch();
        });
      });
    },
    [createUpdate, deletePoint, point, refetchPoints, refetechUpdates, refetch, sharedWorkspaceId]
  );

  const onDeletePoint = useCallback(() => {
    deletePoint(point.id).then(() => {
      refetchPoints();
      refetch && refetch();
    });
  }, [deletePoint, refetchPoints, point.id, refetch]);

  const patchPointTriggered = useCallback(() => {
    if (pointContentDraft !== '') {
      patchPoint(point.id, { ...point, content: pointContentDraft })
        .then(() => {
          refetchPoints();
        })
        .catch(() => {
          renderErrorToast(updateOrDeleteError);
        });
    } else {
      renderErrorToast(emptyContentError);
    }
  }, [patchPoint, point, pointContentDraft, refetchPoints, renderErrorToast]);

  const onAddTask = useCallback(() => {
    setAnchorEl(null);
    const modal = showModal(LazyLoadedActionItemModal, {
      onClose: () => {
        setAnchorEl(null);
        modal.hide();
      },
      onCancel: () => {
        setAnchorEl(null);
        modal.hide();
      },
      onConfirm: (actionItemTitle: string) => {
        createUpdateAndDeletePoint(`<b>Task added</b>: ${actionItemTitle}`);
        refetchActionItems();
        setAnchorEl(null);
        modal.hide();
      },
      suggestedTitle: point.content,
      objectiveIds: objectiveIds,
      actionPlanOpen: true
    });
  }, [point, showModal, refetchActionItems, createUpdateAndDeletePoint, objectiveIds]);

  const onInInitiativeClick = useCallback(() => {
    setAnchorEl(null);
    const modal = showModal(LazyLoadedEditInitiativeModal, {
      onClose: () => {
        setAnchorEl(null);
        modal.hide();
      },
      onCancel: () => {
        setAnchorEl(null);
        modal.hide();
      },
      onConfirm: (initiativeTitle: string) => {
        createUpdateAndDeletePoint(`<b>Initiative added</b>: ${initiativeTitle}`);
        setAnchorEl(null);
        modal.hide();
      },
      initiativeSuggestion: point.content
    });
  }, [showModal, point, createUpdateAndDeletePoint]);

  const pointRef = useRef(null);

  const [starred, setStarred] = useState<boolean>(point.isStarred);

  const onStarClick = () => {
    patchPoint(point.id, { isStarred: !starred }).then(() => {
      refetch && refetch();
      refetchPoints();
    });

    if (starred) {
      removeStarredPoint(point.id);
    } else {
      addStarredPoint(point.id);
    }
    
    setStarred(!starred);
  };

  const actions = [
    {
      title: 'Add as task',
      icon: <FormatListBulletedOutlinedIcon fontSize="small" />,
      onAction: onAddTask
    },
    {
      title: 'Add as initiative',
      icon: <AccountTreeOutlinedIcon fontSize="small" />,
      onAction: onInInitiativeClick
    },
    {
      title: 'Resolve',
      icon: <CheckCircleOutlineOutlinedIcon fontSize="small" />,
      onAction: () => createUpdateAndDeletePoint('<b>Resolved:</b> ' + point.content)
    }
  ];

  if (enableAddToNotes) {
    actions.splice(0, 0, {
      title: 'Add to notes',
      icon: <TextSnippetOutlinedIcon fontSize="small" />,
      onAction: () => {
        setNotesPoint(sharedWorkspaceId, point.content + ':');
        setAnchorEl(null);
      }
    });
  }

  const navigate = useNavigate();

  const [spacesAnchorEl, setSpacesAnchorEl] = useState<HTMLElement | null>(null);

  return (
    <Stack
      onMouseEnter={() => {
        setHovered(true);
      }}
      onMouseLeave={() => setHovered(false)}
      minHeight="30px"
    >
      <Stack
        width="100%"
        direction="row"
        justifyContent="space-between"
        alignItems={sharedWorkspaces ? 'start' : 'center'}
        sx={{ borderRadius: '8px' }}
      >
        <Stack direction="row" width="100%" alignItems="center" gap="10px">
          {dragable && (
            <Stack width="10px">
              {hovered && (
                <Stack data-movable-handle>
                  <DragIndicatorIcon
                    sx={{ cursor: 'grab', width: '20px', color: palette.grey[3100] }}
                  />
                </Stack>
              )}
            </Stack>
          )}

          <PointInput
            multiline
            ref={pointRef}
            hovered={hovered}
            value={pointContentDraft}
            onChange={event => {
              setPointContentDraft(event.currentTarget.value);
            }}
            onKeyDown={onPointKeyDown}
            onBlur={updatePoint}
          />
        </Stack>

        <Stack direction="row" alignItems="center" gap="3pxƒ">
          {!sharedWorkspaces && (
            <Tooltip title={starred ? 'Unfollow' : 'Follow'}>
              <Stack
                onClick={onStarClick}
                sx={{
                  cursor: 'pointer',
                  svg: { path: { fill: palette.primary.main } }
                }}
              >
                {starred ? <StarIcon fontSize="small" /> : <StarBorderIcon fontSize="small" />}
              </Stack>
            </Tooltip>
          )}
          <IconButton
            onClick={event => {
              event.stopPropagation();
              setAnchorEl(event.currentTarget);
            }}
            sx={{ p: 0 }}
          >
            <MoreHorizIcon fontSize="small" />
          </IconButton>
        </Stack>

        <AppPopover
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          handleClose={() => {
            setAnchorEl(null);
            setHovered(false);
          }}
          onDelete={onDeletePoint}
          customActions={actions}
        />
      </Stack>

      {sharedWorkspaces && (
        <Stack direction="row" alignItems="center" gap={1}>
          {sharedWorkspaces.slice(0, 2).map(space => (
            <SpaceNameContainer
              key={space.id}
              onClick={() => navigate(toSharedWorkspace(space.id))}
            >
              <SpaceName>{space.name}</SpaceName>
            </SpaceNameContainer>
          ))}

          {sharedWorkspaces.length > 2 && (
            <Tooltip title="See all spaces">
              <IconButton
                sx={{ padding: 0 }}
                onClick={event => setSpacesAnchorEl(event.currentTarget)}
              >
                <MoreHorizIcon sx={{ width: '15px', height: '15px', color: palette.blue[4] }} />
              </IconButton>
            </Tooltip>
          )}
        </Stack>
      )}

      <Popover
        open={Boolean(spacesAnchorEl)}
        anchorEl={spacesAnchorEl}
        onClose={() => setSpacesAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        sx={{
          borderRadius: '20px',
          padding: 10,
          filter: 'drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.13))'
        }}
      >
        <Stack padding="5px 2px">
          {sharedWorkspaces?.map(space => (
            <SpaceNameContainer
              key={space.id}
              marginBottom="3px"
              onClick={() => navigate(toSharedWorkspace(space.id))}
            >
              <SpaceName>{space.name}</SpaceName>
            </SpaceNameContainer>
          ))}
        </Stack>
      </Popover>
    </Stack>
  );
};

export default PointOfDiscussion;
