import isPropValid from '@emotion/is-prop-valid';
import { Button, Stack, Typography, styled } from '@mui/material';

import palette from '../../theme/palette';

export const Container = styled(Stack)({
  background: palette.grey[1500],
  paddingTop: '20px',
  paddingBottom: '20px',
  paddingRight: '18px',
  paddingLeft: '18px',
  alignItems: 'end',
  width: '100%',
  justifyContent: 'space-between',
  gap: 5
});

export const RowContainer = styled(Stack)({
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',
  justifyContent: 'space-between',
  gap: 1,
  padding: '0 18px'
});

export const AvatarAndEditorContainer = styled(Stack)({
  flexDirection: 'row',
  gap: '10px',
  width: '100%',
  alignItems: 'baseline'
});

export const CommentInputContainer = styled(Stack)({
  alignItems: 'end',
  justifyContent: 'space-between',
  flexDirection: 'row',
  minHeight: '50px',
  background: 'white',
  width: '100%',
  paddingBottom: '5px',
  border: `1px solid ${palette.border.grey}`,
  borderRadius: '8px'
});

export const CommentButtonContainer = styled(Stack, { shouldForwardProp: isPropValid })<{
  disabled?: boolean;
}>(({ disabled }) => ({
  textDecoration: 'none',
  flexDirection: 'row',
  cursor: disabled ? 'default' : 'pointer',
  alignItems: 'center',
  height: '33px',
  padding: '0px 5px',
  borderRadius: '10px',
  backgroundColor: disabled ? palette.grey[500] : palette.primary.main,
  fontFamily: 'Plus Jakarta Sans',
  fontWeight: 700,
  fontSize: '12px',
  color: 'white'
}));

export const CommentButtonText = styled(Typography)({
  fontFamily: 'Plus Jakarta Sans',
  fontWeight: 700,
  padding: 4,
  fontSize: '12px',
  color: 'white'
});

export const CancelButton = styled(Button)(({ theme }) => ({
  backgroundColor: 'transparent',
  fontWeight: 700,
  color: theme.journeyPalette.grey[1100],
  width: '30px'
}));
