import isPropValid from '@emotion/is-prop-valid';
import { Close, Edit } from '@mui/icons-material';
import { Avatar, Stack, Typography, styled } from '@mui/material';

import { randomBgColor } from '../../utils/avatarHelper';
import palette from '../../theme/palette';

export const CommentItem = styled(Stack)({
  flexDirection: 'row',
  alignItems: 'center'
});

export const Container = styled(Stack)(() => ({
  padding: '14px',
  borderRadius: '8px',
  width: '100%'
}));

export const HeaderBlock = styled(Stack)({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  height:"10px"
});

export const ContentBlock = styled(Stack)({
  marginTop: '1px'
});

export const UserInfoBlock = styled(Stack)({
  flexDirection: 'row',
  alignItems: 'center',
  gap:"10px"
});

export const CommenterNameTitle = styled(Typography)({
  fontWeight: 700,
  fontSize: '14px',
  marginLeft: '7px'
});

export const AvatarIcon = styled(Avatar, { shouldForwardProp: isPropValid })<{ userName?: string }>(
  ({ userName }) => ({
    fontSize: '12px',
    borderRadius: '8px',
    height: '27px',
    width: '27px',
    background: randomBgColor(userName)
  })
);

export const DaysAgoTitle = styled(Typography)(({ theme }) => ({
  color: theme.journeyPalette.grey[1100],
  fontWeight: 400,
  fontSize: '12px'
}));

export const EditButton = styled(Edit)(({ theme }) => ({
  cursor: 'pointer',
  '&:hover': {
    color: theme.journeyPalette.primary.main
  }
}));

export const CloseButton = styled(Close)(({ theme }) => ({
  cursor: 'pointer',
  '&:hover': {
    color: theme.journeyPalette.primary.main
  }
}));

export const ActionsContainer = styled(Stack)({

  borderRadius: '6px',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer'
});

export const CancelText = styled(Typography)({
  fontFamily: "'Plus Jakarta Sans'",
  fontWeight: 500,
  fontSize: '13px',
  color: palette.grey[1100],
  cursor: 'pointer'
});


export const SaveButton = styled(Stack)(() => ({
  flexDirection: 'row',
  cursor:"pointer",
  justifyContent: 'center',
  alignItems: 'center',
  padding: '5px 22px',
  fontFamily: "'Plus Jakarta Sans'",
  fontWeight: 500,
  fontSize: '13px',
  color: 'white',
  opacity:  1,
  background:  palette.green[1],
  borderRadius: '8px'
}));

export const CommentInputContainer = styled(Stack)({
  minHeight: '68px',
  background: 'white',
  padding: '10px',
  border: `1px solid ${palette.border.grey}`,
  borderRadius:"8px"
});
