// @see https://orval.dev/guides/custom-axios
import { useEffect } from 'react';

import Axios, { AxiosRequestConfig } from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { closeSnackbar, enqueueSnackbar } from 'notistack';

import { EnvVariables } from '../enums/EnvVariables';
import useAuth from '../hooks/useAuth';
import { TOAST_VARIANTS } from '../components/NotificationToast/NotificationToast';
import { RoutesMap } from './linker';

enum ToastMessages {
  Status403 = 'status403'
}

enum StatusCodeErrorMessages {
  Status403 = 'You are not authorized to perform this action'
}
declare module 'axios' {
  interface AxiosRequestConfig {
    // Add the parameter used by axios-auth-refresh to skip its own queuing logic when running the refresh query
    skipAuthRefresh?: boolean;
  }
}

export const AXIOS_INSTANCE = Axios.create({
  baseURL: process.env.REACT_APP_API_BASE as EnvVariables.REACT_APP_API_BASE
});

// TODO: is a hook, not a service, restructure folders to find the right place to put this file.
export const useConfigureCustomAxios = () => {
  const { tokens, refreshTokens } = useAuth();

  const refreshAuthLogic = async failedRequest => {
    const isAuth = [RoutesMap.AUTH_LOGIN, RoutesMap.AUTH_SIGNUP].includes(window.location.pathname as RoutesMap);
    const isError401 = failedRequest.response.status === 401;
    if (isAuth && isError401) return Promise.reject(failedRequest);

    const { idToken } = await refreshTokens();
    // eslint-disable-next-line no-param-reassign
    failedRequest.response.config.headers['Authorization'] = `Bearer ${idToken}`;

    return Promise.resolve();
  };

  const setAuthorizationHeader = () => {
    AXIOS_INSTANCE.defaults.headers['Authorization'] = `Bearer ${tokens.idToken}`;
  };

  const unauthorizedFailedRequestInterceptor = () => {
    AXIOS_INSTANCE.interceptors.response.use(undefined, function axiosUnauthorizedInterceptor(err) {
      if (err.response.status === 403)
        enqueueSnackbar(ToastMessages.Status403, {
          autoHideDuration: 3000,
          variant: 'custom',
          preventDuplicate: true,
          customProps: {
            caption: StatusCodeErrorMessages.Status403,
            variant: TOAST_VARIANTS.ERROR,
            cta: {
              onClick: () => {
                closeSnackbar();
              }
            }
          }
        });

      return Promise.reject(err);
    });
  };

  useEffect(setAuthorizationHeader, [tokens.idToken]);
  useEffect(unauthorizedFailedRequestInterceptor, [tokens.idToken]);

  createAuthRefreshInterceptor(AXIOS_INSTANCE, refreshAuthLogic, { statusCodes: [401] });
};

export const useCustomAxios =
  <T>(): ((config: AxiosRequestConfig) => Promise<T>) =>
  (config: AxiosRequestConfig) => {
    const source = Axios.CancelToken.source();
    const promise = AXIOS_INSTANCE({
      ...config,
      cancelToken: source.token
    }).then(({ data }) => data);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    promise.cancel = () => {
      source.cancel('Query was cancelled by React Query');
    };

    return promise;
  };

export default useCustomAxios;
