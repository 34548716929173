import React, { useEffect, useState } from 'react';

import { DragIndicator } from '@mui/icons-material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Stack, Tooltip } from '@mui/material';

import { ActionPlanInitiativeDto, ActionPlanInitiativeDtoStatus } from '../../../../api/generated';
import OwnerAndAssignee from '../../../../components/OwnerAndAssignee/OwnerAndAssignee';
import useInitiativeSidebar from '../../../../hooks/context-providers/useInitiativeSidemenu';
import palette from '../../../../theme/palette';
import {
  formatKeyResult,
  getDueDateRange,
  initiativesPriorityColorsMap,
  initiativesStatusMetaDataMap
} from '../../../../utils/actionPlanUtil';
import {
  BigPrioritySelectionOptionContainer,
  CheckCircle,
  InitiativeDescription,
  TimeframeContainer,
  TimeframeText,
  InnitiativeViewContainer,
  SelectionTitle,
  StatusCircle,
  StatusTitle,
  GoalDescription
} from './InitiativeViewStyles';
import GoalProgress from '../../../../components/GoalProgress/GoalProgress';
import useActionPlanObjective from '../../../../hooks/useActionPlanObjective';
import useActionPlanInitiative from '../../../../hooks/useActionPlanInitiative';
import TeamsSelect from '../../../../components/Team/TeamsSelect';

interface InitiativeViewProps {
  initiative: ActionPlanInitiativeDto;
  hoveredId: string;
  refetch?: () => void;
  wide?: boolean
}

const InitiativeView = ({ initiative, hoveredId, refetch, wide = true }: InitiativeViewProps) => {
  const { setCurrentInitiative, setOpen, setCurrentObjective, setRefetch } = useInitiativeSidebar();
  const {
    patchActionPlanInitiative,
    updateActionPlanInitiative
  } = useActionPlanInitiative();

  const {
    detailedObjectivesList,
    refetchDetailedObjectives
  } = useActionPlanObjective();
  const completed = initiative.status === ActionPlanInitiativeDtoStatus.completed;

  const [ownerId, setOwnerId] = useState<string>(initiative.ownerId);
  const [assigneeId, setAssigneeId] = useState<string>(initiative.assigneeId);

  useEffect(() => {
    setOwnerId(initiative.ownerId);
    setAssigneeId(initiative.assigneeId)
  }, [initiative]);

  const updateOwnerId = (userId: string) => {
    setOwnerId(userId);
    if (userId) {
      patchActionPlanInitiative(initiative.id, { ownerId: userId }).then(() =>
        refetchDetailedObjectives()
      );
    } else {
      updateActionPlanInitiative(initiative.id, { ...initiative, ownerId: null }).then(() =>
        refetchDetailedObjectives()
      );
    }
  };

  const updateAssigneeId = (userId: string) => {
    setAssigneeId(userId);
    if (userId) {
      patchActionPlanInitiative(initiative.id, { assigneeId: userId }).then(() =>
        refetchDetailedObjectives()
      );
    } else {
      updateActionPlanInitiative(initiative.id, { ...initiative, assigneeId: null }).then(() =>
        refetchDetailedObjectives()
      );
    }
  };

  return (
    <InnitiativeViewContainer
      sx={{
        opacity: initiative.isArchived ? 0.5 : 1
      }}>
      <Stack width="12px" alignItems="center" marginRight={1}>
        <DragIndicator
          fontSize="small"
          sx={{ display: hoveredId === initiative.id ? 'block' : 'none' }}
        />
      </Stack>

      <Stack
        onClick={() => {
          setCurrentInitiative(initiative);
          setCurrentObjective(
            detailedObjectivesList.find(
              detailedObjective => initiative.objectiveIds[0] === detailedObjective.id
            )
          );
          refetch && setRefetch(() => refetch);
          setOpen(true);
        }}
        width="98%"
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        paddingRight={1}>
        <Stack
          width="50%"
          direction="row"
          alignItems="center"
          gap={1}

        >
          {completed && <CheckCircleIcon sx={{ fontSize: '20px', color: palette.primary.main }} />}

          <Stack
            gap="4px">


            <Tooltip title={initiative.title}>
              <InitiativeDescription completed={completed}>
                {initiative.title}
              </InitiativeDescription>
            </Tooltip>

            {!wide && initiative.goal &&
              <Stack
                width="120px">
                <GoalDescription>
                  {formatKeyResult(initiative.goal?.unit, initiative.goal?.value, initiative.goal.subject)}
                </GoalDescription>
                {initiative.goalProgress >= 0 &&
                  <GoalProgress goalProgress={initiative.goalProgress} />
                }
              </Stack>}
          </Stack>
        </Stack>

        <Stack direction="row" alignItems="center" gap={2} >
          {initiative?.teamIds &&
            <TeamsSelect
              teamIds={initiative?.teamIds}
              viewOnly
              tooltip
            />}

          {wide && initiative.goal &&
            <Stack
              width="120px">
              <GoalDescription>
                {formatKeyResult(initiative.goal?.unit, initiative.goal?.value, initiative.goal.subject)}
              </GoalDescription>
              {initiative.goalProgress >= 0 &&
                <GoalProgress goalProgress={initiative.goalProgress} />
              }
            </Stack>}


          <Stack
            width="80px"
            direction="row"
            alignItems="center">
            <StatusCircle
              circleColor={initiativesStatusMetaDataMap[initiative.status].circleColor}
            />
            <StatusTitle>
              {initiativesStatusMetaDataMap[initiative.status].label}
            </StatusTitle>
          </Stack>


          {initiative.priority && (
            <BigPrioritySelectionOptionContainer
              backgroundColor={initiativesPriorityColorsMap[initiative.priority].backgroundColor}
            >
              <CheckCircle
                checkedColor={initiativesPriorityColorsMap[initiative.priority].checkedColor}
              />
              <SelectionTitle>
                {initiativesPriorityColorsMap[initiative.priority].label}
              </SelectionTitle>
            </BigPrioritySelectionOptionContainer>
          )}

          {initiative?.timeframe && (
            <TimeframeContainer
            >
              <TimeframeText>
                {getDueDateRange(initiative?.timeframe)}
              </TimeframeText>
            </TimeframeContainer>
          )}



          <OwnerAndAssignee
            viewOnly
            onlyAvatar
            ownerId={ownerId}
            updateOwnerId={updateOwnerId}
            assigneeId={assigneeId}
            updateAssigneeId={updateAssigneeId}
          />
        </Stack>
      </Stack>
    </InnitiativeViewContainer>
  );
};

export default InitiativeView;
