import React, { MouseEvent, useCallback, useState } from 'react';
import ConfettiExplosion from 'react-confetti-explosion';
import { useNavigate, useParams } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { DeleteOutline } from '@mui/icons-material';
import { CircularProgress, SelectChangeEvent, Stack, Tooltip, alpha } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useDebouncedCallback } from 'use-debounce';
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import {
  ActionPlanActionItemDtoStatus,
  AttachmentDto
} from '../../api/generated';
import useModal from '../../hooks/context-providers/useModal/useModal';
import AppPopover from '../../screens/ActionPlan/components/AppPopover/AppPopover';
import OwnerAndAssignee from '../OwnerAndAssignee/OwnerAndAssignee';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import UnarchiveOutlinedIcon from '@mui/icons-material/UnarchiveOutlined';

import {
  TaskPropertiesContainer,
  Container,
  TaskInfoContainer,
  ValueInput,
  TaskActions,
  ActionsContainer,
  MoreHorizIcon,
  CloseIcon,
  DescriptionEdtiorContainer,
  SectionTitle,
  StatusSelect,
  StatusOption,
  DueDate,
  InitiativeTitle
} from './TaskSidebarStyles';


import { toActionPlan } from '../../services/linker';
import useAuth from '../../hooks/useAuth';
import Editor from '../../screens/BizAreaModules/components/Editor';


import AddUpdate from '../AddUpdate/AddUpdate';
import CommentsList from '../CommentsList/CommentsList';
import palette from '../../theme/palette';
import useTaskSidebar from '../../hooks/context-providers/useTaskSidebar';
import useActionItemComments from '../../hooks/useActionItemComments';
import AppDatePicker from '../Common/AppDatePicker';
import { getDate, toUtcIsoDate } from '../../utils/dateUtil';
import dayjs, { Dayjs } from 'dayjs';
import useActionPlanActionItem from '../../hooks/useActionPlanActionItem';
import useActionPlanArchivedContext from '../../hooks/context-providers/useActionPlanArchivedContext';
import useActionPlanObjective from '../../hooks/useActionPlanObjective';
import useActionPlanInitiative from '../../hooks/useActionPlanInitiative';
import Attachments from '../Attachments/Attachments';

const LazyLoadedAreYouSureModal = React.lazy(
  () => import('../../modals/AreYouSureModal/AreYouSureModal')
);


const TaskSidebar = () => {
  const theme = useTheme();
  const {
    open,
    currentActionItem,
    setOpen,
    setCurrentActionItem,
    currentObjectiveId,
    refetch: refetchData
  } = useTaskSidebar();
  const [titleValue, setTitleValue] = useState(
    currentActionItem?.title
  );
  const [description, setDescription] = useState<string>(currentActionItem?.description);
  const { showModal } = useModal();
  const { initiativeIncludeArchived } = useActionPlanArchivedContext();

  const [isExploding, setIsExploding] = useState(false);
  const { actionItemId } = useParams();
  const {
    deleteActionPlanActionItem,
    patchActionPlanActionItem,
    refetchActionItems,
    updateActionPlanActionItem
  } = useActionPlanActionItem(currentActionItem?.initiativeId || null, null, initiativeIncludeArchived);

  const { refetchDetailedObjective } = useActionPlanObjective(currentObjectiveId, null, null, null, initiativeIncludeArchived);
  const { initiative } = useActionPlanInitiative(currentActionItem.initiativeId);


  const refetch = useCallback(() => {
    refetchData && refetchData();
    refetchActionItems();
    refetchDetailedObjective();
  }, [
    refetchData,
    refetchActionItems,
    refetchDetailedObjective
  ]);

  const {
    comments,
    createActionItemComment,
    deleteActionItemComment,
    patchActionItemComment,
    refetchComments
  } = useActionItemComments(currentActionItem.id);

  const navigate = useNavigate();

  const { user } = useAuth();

  const createComment = async (content: string, taggedUserIds: string[]) => {
    await createActionItemComment({
      userId: user.id,
      content: content,
      actionItemId: currentActionItem.id,
      taggedUserIds: taggedUserIds
    });
  }



  const onClose = useCallback(() => {
    setCurrentActionItem(null);
    setOpen(false);

    actionItemId && navigate(toActionPlan());
  }, [
    setCurrentActionItem,
    setOpen,
    actionItemId,
    navigate
  ]);

  const debouncedPatchTitle = useDebouncedCallback((title: string) => {
    patchActionPlanActionItem(currentActionItem.id, { title }).then(() =>
      refetch()
    );
  }, 500);

  const deleteActionItemClick = useCallback(() => {
    setAnchorEl(null);
    const modal = showModal(LazyLoadedAreYouSureModal, {
      onClose: () => {
        modal.hide();
      },
      onConfirm: () => {
        deleteActionPlanActionItem(currentActionItem.id)
          .then(() => {
            refetch();
            onClose();
          });
        modal.hide();
      },
      modalTitle: `Are you sure you want to delete \n"${currentActionItem.title}"?`,
      confirmButtonIcon: <DeleteOutline />,
      confirmButtonTitle: 'Delete'
    });
  }, [
    currentActionItem,
    deleteActionPlanActionItem,
    refetch,
    onClose,
    showModal
  ]);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(
    null
  );
  const editAndDeletePopoverOpen = Boolean(anchorEl);






  const onClickActions = (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onChangeTitle = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setTitleValue(event.currentTarget.value);
    debouncedPatchTitle(event.currentTarget.value);
  };

  const onBlurTitle = () => {
    patchActionPlanActionItem(currentActionItem.id, {
      title: titleValue
    }).then(() => refetch());
  };


  const [status, setStatus] = useState<ActionPlanActionItemDtoStatus>(currentActionItem.status);
  const [ownerId, setOwnerId] = useState<string>(currentActionItem.ownerId);
  const [assigneeId, setAssigneeId] = useState<string>(currentActionItem.assigneeId);

  const updateOwnerId = (userId: string) => {
    setOwnerId(userId);
    if (userId) {
      patchActionPlanActionItem(currentActionItem.id, { ownerId: userId }).then(() =>
        refetch()
      );
    } else {
      updateActionPlanActionItem(currentActionItem.id, {
        ...currentActionItem,
        ownerId: null
      }).then(() => refetch());
    }
  };

  const updateAssigneeId = (userId: string) => {
    setAssigneeId(userId);
    if (userId) {
      patchActionPlanActionItem(currentActionItem.id, { assigneeId: userId }).then(() =>
        refetch()
      );
    } else {
      updateActionPlanActionItem(currentActionItem.id, {
        ...currentActionItem,
        assigneeId: null
      }).then(() => refetch());
    }
  };

  const statusColors = {
    "pending": alpha(palette.grey[1400], 0.2),
    "inProgress": alpha(palette.blue[8], 0.9),
    "completed": alpha(palette.primary.main, 0.2)
  }

  const [dueDate, setDueDate] = useState<Dayjs | null>(
    currentActionItem?.dueDate ? dayjs(currentActionItem.dueDate) : null
  );

  const updateDueDate = (date: Dayjs) => {
    setDueDate(date);
    patchActionPlanActionItem(currentActionItem.id, { dueDate: toUtcIsoDate(date.toDate()) }).then(() => refetch());
  };


  const updateStatus = (newStatus: ActionPlanActionItemDtoStatus) => {
    if (newStatus === ActionPlanActionItemDtoStatus.completed) {
      setIsExploding(true);
    }
    setStatus(newStatus);
    patchActionPlanActionItem(currentActionItem.id, { status: newStatus }).then(() => refetch());

  };

  const [descriptionUpdating, setDescriptionUpdating] = useState<boolean>(false);

  const updateDescription = useDebouncedCallback(
    (text: string) =>
      patchActionPlanActionItem(currentActionItem.id, { description: text }).then(() => {
        refetch();
        setDescriptionUpdating(false);
      }

      ),
    1000
  );

  const archiveActionItemClick = useCallback(() => {
    const modal = showModal(LazyLoadedAreYouSureModal, {
      onClose: () => {
        setAnchorEl(null);
        modal.hide();
      },
      onConfirm: () => {
        patchActionPlanActionItem(currentActionItem.id, { isArchived: true }).then(() => refetch());
        setAnchorEl(null);
        modal.hide();
        onClose();
      },
      modalTitle: `Are you sure you want to archive \n"${currentActionItem.title}"?`,
      confirmButtonIcon: <ArchiveOutlinedIcon />,
      confirmButtonTitle: 'Archive'
    });
  }, [onClose, patchActionPlanActionItem, currentActionItem, refetch, showModal, setAnchorEl]);

  const unarchiveActionItemClick = useCallback(() => {
    const modal = showModal(LazyLoadedAreYouSureModal, {
      onClose: () => {
        setAnchorEl(null);
        modal.hide();
      },
      onConfirm: () => {
        patchActionPlanActionItem(currentActionItem.id, { isArchived: false }).then(() => refetch());
        setAnchorEl(null);
        modal.hide();
        onClose();
      },
      modalTitle: `Are you sure you want to unarchive \n"${currentActionItem.title}"?`,
      confirmButtonIcon: <UnarchiveOutlinedIcon />,
      confirmButtonTitle: 'Unarchive'
    });
  }, [onClose, patchActionPlanActionItem, currentActionItem, refetch, showModal, setAnchorEl]);


  const [attachments, setAttachments] = useState<AttachmentDto[]>(currentActionItem.attachments || []);

  const addAttachment = (name: string) => {
    patchActionPlanActionItem(currentActionItem.id, { attachments: [...attachments, { name }] }).then(() => {
      setAttachments([...attachments, { name }]);
      refetch();
    }
    );

  }

  const deleteAttachment = (name: string) => {
    const newList = attachments?.filter(att => att.name !== name);
    setAttachments(newList);
    patchActionPlanActionItem(currentActionItem.id, { attachments: newList }).then(() => refetch());
  }

  return (
    <Container anchor="right" open={open} onClose={onClose} theme={theme}>
      <Stack>

        <TaskPropertiesContainer>
          <Stack
            width="100%"
            gap="5px">
            <TaskInfoContainer>
              <Stack
              width="80%"
                gap={1}
                >
                {initiative && <Tooltip title={initiative.title}><InitiativeTitle>{initiative.title}</InitiativeTitle></Tooltip>}
                <ValueInput
                  multiline
                  defaultValue={currentActionItem?.title}
                  onChange={onChangeTitle}
                  placeholder="Type a task"
                  onBlur={onBlurTitle}
                />
              </Stack>

              <TaskActions>
                <ActionsContainer onClick={onClickActions}>
                  <MoreHorizIcon />
                </ActionsContainer>
                <ActionsContainer>
                  <CloseIcon onClick={onClose} />
                </ActionsContainer>
              </TaskActions>
            </TaskInfoContainer>


            <Stack
              width="100%"
              direction="row"
              gap={1}
              justifyContent="space-between"
              alignItems="center">
              <DescriptionEdtiorContainer
                width="95%">
                <Editor
                  showBorder={false}
                  value={description}
                  defaultValue={description}
                  onChange={content => {
                    setDescription(content);
                    setDescriptionUpdating(true);
                    updateDescription(content);
                  }}
                  placeholder='Add a description...'
                />

              </DescriptionEdtiorContainer>
              {descriptionUpdating && <CircularProgress size={20} />}
            </Stack>

            <Stack
              width="100%"
              direction="row"
              gap={10}
              alignItems="center">
              <OwnerAndAssignee
                includeTitle
                sx={{ marginBottom: 2, gap: 3, paddingLeft: '15px', width: (ownerId || assigneeId) ? '40%' : '15%' }}
                ownerId={ownerId}
                updateOwnerId={updateOwnerId}
                assigneeId={assigneeId}
                updateAssigneeId={updateAssigneeId}
              />

              <StatusSelect
                MenuProps={{
                  MenuListProps: {
                    sx: {
                      padding: 0
                    }
                  }
                }}
                status={status}
                IconComponent={KeyboardArrowDownIcon}
                value={status}
                onChange={(event: SelectChangeEvent<ActionPlanActionItemDtoStatus>) =>
                  updateStatus(event.target.value as ActionPlanActionItemDtoStatus)
                }
              >


                <StatusOption
                  color={statusColors[ActionPlanActionItemDtoStatus.pending]}
                  value={ActionPlanActionItemDtoStatus.pending}>
                  To do
                </StatusOption>
                <StatusOption
                  color={statusColors[ActionPlanActionItemDtoStatus.inProgress]}
                  value={ActionPlanActionItemDtoStatus.inProgress}>
                  In progress
                </StatusOption>
                <StatusOption
                  color={statusColors[ActionPlanActionItemDtoStatus.completed]}
                  value={ActionPlanActionItemDtoStatus.completed}>
                  Completed
                </StatusOption>
              </StatusSelect>

              {isExploding && (
                <ConfettiExplosion
                  duration={2500}
                  zIndex={9999}
                  onComplete={() => setIsExploding(false)}
                  particleCount={200}
                />
              )}

              <AppDatePicker
                component={
                  dueDate ? (
                    <DueDate
                      expired={
                        currentActionItem.status !== ActionPlanActionItemDtoStatus.completed
                          ? dueDate.toDate().setHours(0, 0, 0, 0) <
                          new Date().setHours(0, 0, 0, 0)
                          : false
                      }
                    >
                      {getDate(dueDate.toISOString())}
                    </DueDate>
                  ) : (
                    <Tooltip title="Set due date">
                      <Stack
                        sx={{
                          cursor: "pointer",
                          svg: { path: { fill: palette.grey[500] } },
                          ':hover': { opacity: 0.8 }
                        }}
                      >
                        <EventOutlinedIcon sx={{ width: "30px", height: "30px" }} />
                      </Stack>
                    </Tooltip>
                  )
                }
                value={dueDate}
                onChange={(date: Dayjs) => updateDueDate(date)}
              />
            </Stack>



          </Stack>
        </TaskPropertiesContainer>


        <Stack
          marginTop={2}
          gap={2}
        >
          <SectionTitle>Attachments</SectionTitle>
          <Attachments
            parnetId={currentActionItem.id}
            attachments={attachments}
            onUpload={addAttachment}
            onDelete={name => deleteAttachment(name)} />
        </Stack>



        <Stack
          marginTop={2}
          sx={{ backgroundColor: palette.grey[1500] }}>
          <SectionTitle>Comments</SectionTitle>
          <AddUpdate
            refetch={refetchComments}
            createComment={createComment}
          />
           <Stack
            paddingLeft="10px">
          <CommentsList
            patchComment={patchActionItemComment}
            comments={comments}
            deleteComment={deleteActionItemComment}
            refetchComments={refetchComments}
          />
          </Stack>
        </Stack>



      </Stack>
      <AppPopover
        anchorEl={anchorEl}
        open={editAndDeletePopoverOpen}
        handleClose={() => setAnchorEl(null)}
        onDelete={deleteActionItemClick}
        customActions={[{
          title: currentActionItem.isArchived ? "Unarchive" : "Archive",
          icon: currentActionItem.isArchived ? <UnarchiveOutlinedIcon fontSize='small' /> : <ArchiveOutlinedIcon fontSize='small' />,
          onAction: currentActionItem.isArchived ? unarchiveActionItemClick : archiveActionItemClick
        }]}
      />
    </Container>
  );
};

export default TaskSidebar;
