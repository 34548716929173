import React, { useCallback, useEffect, useRef, useState } from 'react';
import ConfettiExplosion from 'react-confetti-explosion';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { DragIndicator } from '@mui/icons-material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import { IconButton, SelectChangeEvent, Stack, Tooltip, alpha } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import UnarchiveOutlinedIcon from '@mui/icons-material/UnarchiveOutlined';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import {
  ActionPlanActionItemDto,
  ActionPlanActionItemDtoSprint,
  ActionPlanActionItemDtoStatus,
  ActionPlanInitiativeDto
} from '../../api/generated';
import useModal from '../../hooks/context-providers/useModal/useModal';
import AppPopover from '../../screens/ActionPlan/components/AppPopover/AppPopover';
import palette from '../../theme/palette';
import { getDate, toUtcIsoDate } from '../../utils/dateUtil';
import AppDatePicker from '../Common/AppDatePicker';
import OwnerAndAssignee from '../OwnerAndAssignee/OwnerAndAssignee';
import {
  ActionItemContainer,
  ActionItemDescription,
  DetailsButton,
  DueDate,
  StatusOption,
  StatusSelect
} from './ActionItemsListStyles';
import useTaskSidebar from '../../hooks/context-providers/useTaskSidebar';
import useActionPlanActionItem from '../../hooks/useActionPlanActionItem';
import useActionPlanObjective from '../../hooks/useActionPlanObjective';



type ActionItemProps = {
  actionItem: ActionPlanActionItemDto;
  refetch: () => void;
  disableDrag?: boolean;
  moveAction?: boolean;
  objectiveId?: string;
  initiative?: ActionPlanInitiativeDto;
};

const LazyLoadedAreYouSureModal = React.lazy(
  () => import('../../modals/AreYouSureModal/AreYouSureModal')
);

const LazyLoadedAttachToInitiativeModal = React.lazy(
  () => import('../../modals/AttachToInitiativeModal/AttachToInitiativeModal')
);


const ActionItem = ({
  actionItem,
  refetch,
  disableDrag,
  objectiveId,
  initiative,
  moveAction
}: ActionItemProps) => {


  const [hovered, setHovered] = useState<boolean>(false);
  const { showModal } = useModal();
  const [isExploding, setIsExploding] = useState(false);
  const [status, setStatus] = useState<ActionPlanActionItemDtoStatus>(actionItem.status);


  const {
    deleteActionPlanActionItem,
    patchActionPlanActionItem,
    updateActionPlanActionItem
  } = useActionPlanActionItem();

  const {
    refetchDetailedObjectives
  } = useActionPlanObjective();




  const updateSprint = (sprint: ActionPlanActionItemDtoSprint) => {
    patchActionPlanActionItem(actionItem.id, { sprint: sprint }).then(() => refetch());
  };


  const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);

  const deleteActionItemClick = useCallback(() => {
    setAnchorEl(null);
    const modal = showModal(LazyLoadedAreYouSureModal, {
      onClose: () => {
        setAnchorEl(null);
        modal.hide();
      },
      onConfirm: () => {
        setAnchorEl(null);
        deleteActionPlanActionItem(actionItem.id).then(() => {
          refetch();
          refetchDetailedObjectives();
        });
        modal.hide();
      },
      modalTitle: `Are you sure you want to delete \n${actionItem.title
        }?`,
      confirmButtonIcon: <DeleteOutlineIcon />,
      confirmButtonTitle: 'Delete'
    });
  }, [
    actionItem.title,
    actionItem.id,
    setAnchorEl,
    deleteActionPlanActionItem,
    showModal,
    refetch,
    refetchDetailedObjectives
  ]);


  const [ownerId, setOwnerId] = useState<string>(actionItem.ownerId);
  const [assigneeId, setAssigneeId] = useState<string>(actionItem.assigneeId);
  const [title, setTitle] = useState<string>(actionItem.title);
  const [dueDate, setDueDate] = useState<Dayjs | null>(
    actionItem?.dueDate ? dayjs(actionItem.dueDate) : null
  );

  useEffect(() => {
    setOwnerId(actionItem.ownerId);
    setAssigneeId(actionItem.assigneeId);
    setTitle(actionItem.title);
    setStatus(actionItem.status);
  }, [actionItem]);

  const updateOwnerId = (userId: string) => {
    setOwnerId(userId);
    if (userId) {
      patchActionPlanActionItem(actionItem.id, { ownerId: userId }).then(() => refetch());
    } else {
      updateActionPlanActionItem(actionItem.id, { ...actionItem, ownerId: null }).then(() =>
        refetch()
      );
    }
  };

  const updateAssigneeId = (userId: string) => {
    setAssigneeId(userId);
    if (userId) {
      patchActionPlanActionItem(actionItem.id, { assigneeId: userId }).then(() => refetch());
    } else {
      updateActionPlanActionItem(actionItem.id, { ...actionItem, assigneeId: null }).then(() =>
        refetch()
      );
    }
  };

  const updateDueDate = (date: Dayjs) => {
    setDueDate(date);
    patchActionPlanActionItem(actionItem.id, { dueDate: toUtcIsoDate(date.toDate()) }).then(() => refetch());
  };

  const updateStatus = (newStatus: ActionPlanActionItemDtoStatus) => {
    if (newStatus === ActionPlanActionItemDtoStatus.completed) {
      setIsExploding(true);
    }
    setStatus(newStatus);
    patchActionPlanActionItem(actionItem.id, { status: newStatus }).then(() => refetch());

  };

  const descriptionRef = useRef(null);

  const updateTitle = () => {
    patchActionPlanActionItem(actionItem.id, { title: title }).then(() => {
      descriptionRef?.current?.children?.[0].blur();
      refetch();
    });
  };

  const onDescriptionKeyDown = async event => {
    if (event.key === 'Enter') {
      updateTitle();
    }
  };

  const archiveActionItemClick = useCallback(() => {
    setAnchorEl(null);
    const modal = showModal(LazyLoadedAreYouSureModal, {
      onClose: () => {
        setAnchorEl(null);
        modal.hide();
      },
      onConfirm: () => {
        patchActionPlanActionItem(actionItem.id, { isArchived: true }).then(() => refetch());
        setAnchorEl(null);
        modal.hide();
      },
      modalTitle: `Are you sure you want to archive \n"${actionItem.title}"?`,
      confirmButtonIcon: <ArchiveOutlinedIcon />,
      confirmButtonTitle: 'Archive'
    });
  }, [patchActionPlanActionItem, actionItem, refetch, showModal, setAnchorEl]);

  const unarchiveActionItemClick = useCallback(() => {
    setAnchorEl(null);
    const modal = showModal(LazyLoadedAreYouSureModal, {
      onClose: () => {
        setAnchorEl(null);
        modal.hide();
      },
      onConfirm: () => {
        patchActionPlanActionItem(actionItem.id, { isArchived: false }).then(() => refetch());
        setAnchorEl(null);
        modal.hide();
      },
      modalTitle: `Are you sure you want to unarchive \n"${actionItem.title}"?`,
      confirmButtonIcon: <UnarchiveOutlinedIcon />,
      confirmButtonTitle: 'Unarchive'
    });
  }, [patchActionPlanActionItem, actionItem, refetch, showModal, setAnchorEl]);


  const attachToInitiativeClick = useCallback(() => {
    setAnchorEl(null);
    const modal = showModal(LazyLoadedAttachToInitiativeModal, {
      onClose: () => {
        setAnchorEl(null);
        modal.hide();
      },
      onConfirm: () => {
        setAnchorEl(null);
        modal.hide();
      },
      actionItem: actionItem,
      refetch: refetch

    });
  }, [actionItem, refetch, showModal, setAnchorEl]);


  const actions = [
    {
      title: actionItem.isArchived ? "Unarchive" : "Archive",
      icon: actionItem.isArchived ? <UnarchiveOutlinedIcon fontSize='small' /> : <ArchiveOutlinedIcon fontSize='small' />,
      onAction: actionItem.isArchived ? unarchiveActionItemClick : archiveActionItemClick
    }
  ];

  if (!actionItem.initiativeId) {
    actions.push({
      title: "Attach to Initiative",
      icon: <AccountTreeOutlinedIcon fontSize='small' />,
      onAction: attachToInitiativeClick
    });
  }

  if (moveAction && actionItem.status !== ActionPlanActionItemDtoStatus.completed) {
    actions.push({
      title: `Move to ${actionItem.sprint === ActionPlanActionItemDtoSprint.backlog ? 'To do' : 'Backlog'
        }`,
      icon:
        actionItem.sprint === ActionPlanActionItemDtoSprint.backlog ? (
          <TrendingFlatIcon />
        ) : (
          <Inventory2OutlinedIcon fontSize="small" />
        ),
      onAction: () =>
        actionItem.sprint === ActionPlanActionItemDtoSprint.backlog
          ? updateSprint('current')
          : updateSprint('backlog')
    });
  }

  const [statusHover, setStatusHover] = useState<boolean>(false);

  const statusColors = {
    "pending": alpha(palette.grey[1400], 0.2),
    "inProgress": alpha(palette.blue[8], 0.9),
    "completed": alpha(palette.primary.main, 0.2)
  }


  const { setOpen: openSidebar, setCurrentActionItem, setCurrentObjectiveId, setRefetch } = useTaskSidebar();

  return (
    <ActionItemContainer
      sx={{ opacity: actionItem.isArchived ? 0.5 : 1 }}
      id={actionItem.id}
      onMouseOver={() => setHovered(true)}
      onMouseOut={() => setHovered(false)}
    >
      <Stack direction="row" alignItems="center" width="65%">
        <Stack width="2px" alignItems="center" marginRight="6px">
          {!disableDrag && (
            <DragIndicator sx={{ cursor: 'grab', display: hovered ? 'block' : 'none' }} />
          )}
        </Stack>



        <Stack width="100%" >
          <Tooltip title={initiative ? `Initiative: ${initiative.title}` : ''}>
            <ActionItemDescription
              ref={descriptionRef}
              hovered={hovered}
              value={title}
              onChange={event => {
                setTitle(event.currentTarget.value);
              }}
              onKeyDown={onDescriptionKeyDown}
              onBlur={updateTitle}
            />
          </Tooltip>


        </Stack>
      </Stack>

      <Stack direction="row" alignItems="center" gap={1} >
        {hovered &&
          <DetailsButton
            onClick={() => {
              refetch && setRefetch(() => refetch);
              setCurrentActionItem(actionItem);
              setCurrentObjectiveId(objectiveId);
              openSidebar(true);
            }}>
            <Tooltip
              title="Details">
              <KeyboardArrowRightOutlinedIcon sx={{ width: "22px", height: "22px" }} />
            </Tooltip>
          </DetailsButton>}
        <AppDatePicker
          component={
            actionItem.dueDate ? (
              <DueDate
                expired={
                  actionItem.status !== ActionPlanActionItemDtoStatus.completed
                    ? new Date(actionItem.dueDate).setHours(0, 0, 0, 0) <
                    new Date().setHours(0, 0, 0, 0)
                    : false
                }
              >
                {getDate(actionItem.dueDate)}
              </DueDate>
            ) : (
              <Tooltip title="Set due date">
                <Stack
                  sx={{
                    svg: { path: { fill: palette.grey[500] } },
                    ':hover': { opacity: 0.8 }
                  }}
                >
                  <EventOutlinedIcon fontSize="small" />
                </Stack>
              </Tooltip>
            )
          }
          value={dueDate}
          onChange={(date: Dayjs) => updateDueDate(date)}
        />

        <StatusSelect
          MenuProps={{
            MenuListProps: {
              sx: {
                padding: 0
              }
            },
            PaperProps: {
              sx: {
                borderRadius: "10px",
                boxShadow: `0px 10px 10px ${palette.grey[2000]}`
              }
            }
          }}
          onMouseEnter={() => setStatusHover(true)}
          onMouseLeave={() => setStatusHover(false)}
          onMouseOut={() => setStatusHover(false)}
          status={status}
          IconComponent={statusHover ? KeyboardArrowDownIcon : () => null}
          value={status}
          onChange={(event: SelectChangeEvent<ActionPlanActionItemDtoStatus>) => {
            updateStatus(event.target.value as ActionPlanActionItemDtoStatus);
          }}
        >


          <StatusOption
            color={statusColors[ActionPlanActionItemDtoStatus.pending]}
            value={ActionPlanActionItemDtoStatus.pending}>
            To do
          </StatusOption>
          <StatusOption
            color={statusColors[ActionPlanActionItemDtoStatus.inProgress]}
            value={ActionPlanActionItemDtoStatus.inProgress}>
            In progress
          </StatusOption>
          <StatusOption
            color={statusColors[ActionPlanActionItemDtoStatus.completed]}
            value={ActionPlanActionItemDtoStatus.completed}>
            Completed
          </StatusOption>
        </StatusSelect>

        {isExploding && (
          <ConfettiExplosion
            zIndex={10000}
            duration={2500}
            onComplete={() => setIsExploding(false)}
            particleCount={200}
          />
        )}

        <OwnerAndAssignee
          ownerId={ownerId}
          updateOwnerId={updateOwnerId}
          assigneeId={assigneeId}
          updateAssigneeId={updateAssigneeId}
          onlyAvatar
          sx={{ gap: 1 }}
        />

        <IconButton onClick={event => setAnchorEl(event.currentTarget)} sx={{ p: 0 }}>
          <MoreHorizIcon fontSize="small" />
        </IconButton>
        <AppPopover
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          handleClose={() => {
            setAnchorEl(null);
            setHovered(false);
          }}
          onDelete={deleteActionItemClick}
          customActions={actions}
        />
      </Stack>

    </ActionItemContainer>
  );
};

export default ActionItem;
