import { useCallback, useMemo } from 'react';

import {
  ActionPlanActionItemDto,
  SearchEntityRequest,
  UserFeatureFeature,
  useBulkPatchActionItems,
  useCreateActionPlanActionItem,
  useDeleteActionPlanActionItem,
  useGetActionItemsSuggestion,
  useListActionPlanActionItems,
  usePatchActionPlanActionItem,
  useSearchActionPlanActionItems,
  useUpdateActionPlanActionItem
} from '../api/generated';
import useUserFeature from './useUserFeature';

export const useActionPlanActionItem = (
  initiativeId?: string,
  timeFrame?: { from: number; to: number },
  includeArchived?: boolean,
  userIds?: string[],
  initiativeIds?: string[],
  fetchList = true
) => {
  const { isFeatureBlocked } = useUserFeature();

  const isActionPlanEnabled = !isFeatureBlocked(UserFeatureFeature.actionPlan);


  const {
    data: actionItemResponse,
    isLoading: actionItemsLoading,
    refetch: refetchActionItems
  } = useListActionPlanActionItems(
    {
      initiativeId: initiativeId || null,
      from: timeFrame?.from ? timeFrame.from : null,
      to: timeFrame?.to ? timeFrame.to : null,
      includeArchived: includeArchived || null,
      userIds: userIds ? userIds.toString() : null,
      initiativeIds: initiativeIds ? initiativeIds.toString() : null
    },
    {
      query: {
        queryKey: ['actionPlanActionItems', initiativeId, timeFrame, includeArchived, userIds, initiativeIds],
        enabled: isActionPlanEnabled && fetchList
      }
    }
  );


  const {
    data: actionItemsSuggestionsResponse,
    refetch: getactionItemsSuggestions,
    isLoading: actionItemsSuggestionsLoading
  } = useGetActionItemsSuggestion(
    { initiativeId: initiativeId },
    {
      query: {
        queryKey: ['actionItemsSuggestions', initiativeId],
        enabled: false
      }
    }
  );

  const { mutateAsync: mutateCreateActionPlanActionItem } = useCreateActionPlanActionItem();
  const { mutateAsync: mutatePatchActionPlanActionItem } = usePatchActionPlanActionItem();
  const { mutateAsync: mutateUpdateActionPlanActionItem } = useUpdateActionPlanActionItem();
  const { mutateAsync: mutateDeleteActionPlanActionItem } = useDeleteActionPlanActionItem();
  const { mutateAsync: mutateBulkPatchActionPlanActionItems } = useBulkPatchActionItems();
  const { mutateAsync: mutateSearchActionPlanActionItems, isPending: searchActionItemssLoading } = useSearchActionPlanActionItems();

  const searchActionItems = useCallback(
    async (data: SearchEntityRequest, includeArchived: boolean) => {
      const response = await mutateSearchActionPlanActionItems({ data, params: { includeArchived: includeArchived } });
      return response?.items || [];
    },
    [mutateSearchActionPlanActionItems]
  );



  const createActionPlanActionItem = useCallback(
    async (actionItem: Partial<ActionPlanActionItemDto>) => {
      const response = await mutateCreateActionPlanActionItem({ data: actionItem });
      return response?.items?.[0];
    },
    [mutateCreateActionPlanActionItem]
  );

  const deleteActionPlanActionItem = useCallback(
    async (id: string) => {
      await mutateDeleteActionPlanActionItem({ id });
    },
    [mutateDeleteActionPlanActionItem]
  );


  const patchActionPlanActionItem = useCallback(
    async (id: string, actionItem: Partial<ActionPlanActionItemDto>) => {
      await mutatePatchActionPlanActionItem({ id, data: actionItem });
    },
    [mutatePatchActionPlanActionItem]
  );

  const updateActionPlanActionItem = useCallback(
    async (id: string, actionItem: Partial<ActionPlanActionItemDto>) => {
      await mutateUpdateActionPlanActionItem({ id, data: actionItem });
    },
    [mutateUpdateActionPlanActionItem]
  );

  const bulkPatchActionPlanActionItems = useCallback(
    async (data: ActionPlanActionItemDto[]) => {
      await mutateBulkPatchActionPlanActionItems({ data: { items: data } });
    },
    [mutateBulkPatchActionPlanActionItems]
  );

  const actionItemsSuggestionList = useMemo(
    () => actionItemsSuggestionsResponse?.items[0].suggestions || [],
    [actionItemsSuggestionsResponse?.items]
  );

  const actionItemsList = useMemo(
    () => actionItemResponse?.items || [],
    [actionItemResponse?.items]
  );


  return useMemo(
    () => ({
      refetchActionItems,
      actionItemsList,
      createActionPlanActionItem,
      updateActionPlanActionItem,
      patchActionPlanActionItem,
      deleteActionPlanActionItem,
      getactionItemsSuggestions,
      actionItemsSuggestionList,
      actionItemsSuggestionsLoading,
      actionItemsLoading,
      bulkPatchActionPlanActionItems,
      searchActionItems,
      searchActionItemssLoading
    }),
    [
      refetchActionItems,
      actionItemsList,
      createActionPlanActionItem,
      updateActionPlanActionItem,
      patchActionPlanActionItem,
      deleteActionPlanActionItem,
      getactionItemsSuggestions,
      actionItemsSuggestionList,
      actionItemsSuggestionsLoading,
      actionItemsLoading,
      bulkPatchActionPlanActionItems,
      searchActionItems,
      searchActionItemssLoading
    ]
  );
};

export default useActionPlanActionItem;
