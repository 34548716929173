import React, { useEffect, useState } from 'react';

import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { IconButton, Stack } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';

import {
  MetricDefinitionDtoPeriod
} from '../../../../../../api/generated';
import AppDatePicker from '../../../../../../components/Common/AppDatePicker';
import { CircleProgress } from '../../../../../../components/InitiativeSidebar/InitiativeSidebarStyles';
import useMetricDefinition from '../../../../../../hooks/useMetricDefinition';
import AppPopover from '../../../../../ActionPlan/components/AppPopover/AppPopover';
import { RowContainer, Value, ValueInput } from './MetricDataPointRowStyles';
import useMetricDataPoints from '../../../../../../hooks/useMetricDataPoints';
import { toUtcIsoDate } from '../../../../../../utils/dateUtil';

type MetricDataPointRowProps = {
  metricDefinitionId: string;
  isAuto: boolean;
  id?: string;
  timestamp: Date;
  value: number;
  period: MetricDefinitionDtoPeriod;
  refetch: () => void;
  onCancelNew?: (id: string) => Promise<void>;
};

const MetricDataPointRow = ({
  isAuto,
  id,
  timestamp,
  value,
  period,
  refetch,
  onCancelNew,
  metricDefinitionId
}: MetricDataPointRowProps) => {
  const isNew = value === undefined;
  const [editMode, setEditMode] = useState<boolean>(isNew);
  const [hovered, setHovered] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);
  const { deleteMetricDataPoint, patchMetricDataPoint } =
    useMetricDefinition();

  const { createMetricDataPoints } = useMetricDataPoints()

  const [dataPointDate, setDataPointDate] = useState<Date>(timestamp);
  const [dataPointValue, setDataPointValue] = useState<number>(value);

  const [isPending, setIsPending] = useState<boolean>(false);

  const onDateChange = (dateValue: Dayjs) => {
    setDataPointDate(dateValue.toDate());
  }

  useEffect(() => {
    if (timestamp && value) {
      setDataPointDate(timestamp);
      setDataPointValue(value);
    }
  }, [timestamp, value, setDataPointDate, setDataPointValue]);


  const onCancelEdit = () => {
    if (isNew) {
      onCancelNew(id);
    } else {
      setDataPointDate(timestamp);
      setDataPointValue(value);
    }

    setEditMode(false);
  };


  const updateDataPointValue = async () => {

    if (dataPointValue === null || Number.isNaN(dataPointValue)) {
      await deleteMetricDataPoint(id).then(() => refetch());
    } else if (!value) {
      await createMetricDataPoints([
        {
          metricDefinitionId: metricDefinitionId,
          createdAt: toUtcIsoDate(dataPointDate),
          value: dataPointValue
        }
      ]).then(() => refetch());
    } else {
      if (timestamp !== dataPointDate || value !== dataPointValue) {
        await patchMetricDataPoint(id, {
          createdAt: toUtcIsoDate(dataPointDate),
          value: dataPointValue
        }).then(() => refetch());
      }
    }
  };

  const onSave = async () => {
    setIsPending(true);
    updateDataPointValue().then(() => {
      setEditMode(false);
      setIsPending(false);
    });
  };

  const onDelete = async () => {
    await deleteMetricDataPoint(id).then(() => refetch());
  };

  return (
    <RowContainer onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
      {isPending ? (
        <CircleProgress />
      ) : (
        <>
          {editMode ? (
            <Stack width="100%" direction="row" alignItems="center" justifyContent="space-between">
              <Stack direction="row" alignItems="center" gap={2} width="180px">
                <AppDatePicker
                  inputFormat={period === MetricDefinitionDtoPeriod.annually && 'YYYY'}
                  height="40px"
                  views={
                    period === MetricDefinitionDtoPeriod.annually ? ['year'] : ['month', 'year', 'day']
                  }
                  value={dayjs(dataPointDate)}
                  onChange={onDateChange}
                />
              </Stack>

              <ValueInput
                disableUnderline
                placeholder="Enter value"
                type="number"
                value={dataPointValue}
                onChange={event => setDataPointValue(parseFloat(event.target.value))}
              />


              <Stack direction="row" alignItems="center" gap={1}>
                {dataPointValue !== undefined && dataPointValue !== null && !Number.isNaN(dataPointValue) && (
                  <IconButton onClick={onSave}>
                    <DoneOutlinedIcon fontSize="small" />
                  </IconButton>
                )}

                <IconButton onClick={onCancelEdit}>
                  <CloseOutlinedIcon fontSize="small" />
                </IconButton>
              </Stack>
            </Stack>
          ) : (
            <Stack width="70%" direction="row" alignItems="center" justifyContent="space-between">
              <Value>
                {dataPointDate.toLocaleString([], {
                  day: "2-digit",
                  month: 'short',
                  year: 'numeric',
                  timeZone: 'UTC'
                })}
              </Value>


              <Value>{dataPointValue % 1 !== 0 ? dataPointValue.toFixed(2) : dataPointValue}</Value>

            </Stack>

          )}

          {!isAuto && hovered && !editMode && (
            <IconButton onClick={event => setAnchorEl(event.currentTarget)} sx={{ p: 0 }}>
              <MoreHorizIcon fontSize="small" />
            </IconButton>
          )}

          <AppPopover
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            handleClose={() => {
              setAnchorEl(null);
              setHovered(false);
            }}
            onDelete={onDelete}
            onEdit={() => {
              setEditMode(true);
              setAnchorEl(null);
              setHovered(false);
            }}
          />
        </>
      )}
    </RowContainer>
  );
};

export default MetricDataPointRow;
