
import { Input, Stack, Typography, alpha, styled } from '@mui/material';


import palette from '../../theme/palette';

export const GoalDescription = styled(Typography)(() => ({
  fontFamily: 'Plus Jakarta Sans',
  fontWeight: "bolder",
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  fontSize: "13px",
  color: palette.grey[1900]
}));

export const EmptyGoalTitle = styled(Typography)({
  fontFamily: 'Plus Jakarta Sans',
  fontSize: '14px',
  color: palette.grey[600]
});

export const AddGoal = styled(Typography)({
  fontFamily: 'Plus Jakarta Sans',
  fontSize: '14px',
  cursor: "pointer",
  ":hover": {
    fontWeight: "bold"
  },
  color: palette.primary.main
});


export const Baseline = styled(Typography)(() => ({
  fontFamily: 'Plus Jakarta Sans',
  fontSize: "11px",
  color: palette.common.darkBlue
}));

export const ProgressTitle = styled(Typography)(() => ({
  fontFamily: 'Plus Jakarta Sans',
  fontWeight: 700,
  fontSize: '13px',
  lineHeight: '16px',
  color: palette.common.darkBlue
}));


export const TargetPercentageView = styled(Typography)(() => ({
  fontWeight: 700,
  fontFamily: 'Plus Jakarta Sans',
  fontSize: '14px',
  textAlign: 'center',
  color: palette.primary.main
}));

export const CurrentValueTitle = styled(Typography)(() => ({
  fontSize: '12px',
  fontFamily: 'Plus Jakarta Sans',
  textAlign: 'center',
  color: palette.grey[1200]
}));


export const CancelValueButton = styled(Typography)({
  fontFamily: "'Plus Jakarta Sans'",
  fontSize: '12px',
  color: palette.grey[1100],
  cursor: 'pointer'
});

export const AddData = styled(Typography)({
  fontFamily: "'Plus Jakarta Sans'",
  fontSize: '13px',
  borderBottom:`1px solid ${palette.primary.main}`,
  color: palette.primary.main,
  cursor: 'pointer',
  ":hover":{opacity:0.8}
});


export const SaveValueButton = styled(Stack)<{
  disabled: boolean;
}>(({ disabled }) => ({
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding:"0px 5px",
  fontSize: '13px',
  color: 'white',
  opacity: disabled ? 0.4 : 1,
  background: disabled ? '#DBE1E2' : palette.green[1],
  borderRadius: '6px',
  cursor: disabled ? 'not-allowed' : 'pointer',
  ':hover': {
    opacity:0.8
  }
}));

export const CurrentValueInput = styled(Input)(() => ({
  backgroundColor: palette.secondary.main,
  borderRadius: '8px',
  padding: '5px 10px',
  fontSize: "14px"
}));

export const UnitIcon = styled(Stack)(({ theme }) => ({
  borderRadius: '50%',
  padding: '5px',
  backgroundColor: alpha(theme.journeyPalette.primary.main, 0.2),
  color: theme.journeyPalette.primary.main,
  svg: {
    width: 17,
    height: 17
  }
}));