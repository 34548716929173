export default {
  background: {
    default: '#fff',
    paper: '#fff',
    lightBlack: '#33373C',
    lightGreen: '#EDFBE8',
    grey: '#F4F6F8',
    rowGrey: '#f9f8f8',
    lightGrey: '#E2EAEF'
  },
  common: {
    black: '#252A2F',
    darkBlack: '#181604',
    darkBlue: '#012533',
    blue: '#083749',
    outlinedIcon: '#0000008a'
  },
  primary: {
    main: '#6DC24A'
  },
  secondary: {
    main: '#fff'
  },
  error: {
    main: '#E4062E',
    light: '#F44949',
    dark: '#A2132D'
  },
  success: {
    main: '#2EAB49'
  },
  action: {
    selected: '#f4e5dd'
  },
  grey: {
    1: '#f7f9f7',
    25: '#BDC0CA',
    50: '#DBDBDB',
    75: '#f9f8f8',
    100: '#f9f9fb',
    200: '#e9e9e9',
    300: '#d8d9d9',
    400: '#F4F5F8',
    500: '#C3C8CE',
    600: '#9C9C9C',
    700: '#656565',
    800: '#353B42',
    900: '#ABABAB',
    1000: '#EFF3F4',
    1100: '#84898B',
    1200: '#4B4B4B',
    1300: '#F6F6F6',
    1400: '#C4C4C4',
    1500: '#F8F9FA',
    1600: '#EFF3F7',
    1700: '#A6A6A6',
    1800: '#DBE1E2',
    1900: '#5F6368',
    2000: '#afabac',
    3000: '#ECF1F6',
    3100: '#6d6e6f',
    3200: '#ECECF4',
    3300: '#BEC0C9',
    3400: '#1C1C1C'
  },
  blue: {
    1: '#0E3A4B',
    2: '#195166',
    3: '#1A38D5',
    4: '#4F8DF5',
    5: '#E4EAF0',
    6: '#1C274C',
    7: '#CAD2F0',
    8: '#E6EDF9',
    9: '#4F8DF5',
    10: '#415FFF',
    11: '#4F68F3',
    12: '#102289',
    13: '#001C26'
  },
  green: {
    1: '#6DC24A',
    2: '#EDFBE7',
    3: '#5EAD3E',
    4: '#96d67a',
    5: '#64D4BA',
    6: '#DCF1DC',
    7: '#45991F',
    8: '#27D88D',
    9: '#6AD5BC'
  },
  yellow: {
    1: '#F4B300',
    2: '#FEF6CE',
    3: '#FFB433'
  },
  red: {
    1: '#a1010b',
    2: '#c9010e',
    3: '#FBD2D7',
    4: '#D31A47'
  },
  purple: {
    1: '#9F53FF',
    2: '#7A5FC4',
    3: '#B4C1F2',
    4: "#95A4FC"
  },
  border: {
    grey: '#DBE1E2',
    grey2: '#EFF3F4'
  },
  rate: {
    1: {
      default: '#E4062E',
      hover: '#F44949',
      selected: '#A2132D'
    },
    2: {
      default: '#F26628',
      hover: '#F44949',
      selected: '#A2132D'
    },
    3: {
      default: '#FEC925',
      hover: '#FFDE79',
      selected: '#DDAE1C'
    },
    4: {
      default: '#2EAB49',
      hover: '#48CC64',
      selected: '#3C874C'
    },
    5: {
      default: '#2EAB49',
      hover: '#48CC64',
      selected: '#3C874C'
    }
  },
  kpiScore: {
    1: {
      default: '#2EAB49',
      selected: '#3C874C'
    },
    2: {
      default: '#FEC925',
      selected: '#DDAE1C'
    },
    3: {
      default: '#E4062E',
      selected: '#A2132D'
    }
  }
};
