import { Stack, Typography, styled } from '@mui/material';
import palette from '../../../../../../theme/palette';


export const SectionTitle = styled(Typography)({
  fontWeight: 800,
  fontSize: '1.15rem',
  fontFamily: 'Plus Jakarta Sans'
});

export const DetailsItemDescriptionTitle = styled(Typography)({
  fontWeight: 600,
  fontSize: '0.9rem',
  fontFamily: 'Plus Jakarta Sans',
  color: palette.grey[1700],
  minWidth: '100px'
});

export const DetailsItemValueTitle = styled(Typography)({
  fontWeight: 600,
  fontFamily: 'Plus Jakarta Sans',
  fontSize: '0.9rem'
});

export const TargetTitle = styled(Typography)<{
  customColor?: string;
}>(({ theme, customColor }) => ({
  fontWeight: 700,
  fontSize: '13px',
  color: customColor ? customColor : theme.journeyPalette.grey[1100]
}));

export const ChartHeader = styled(Stack)(() => ({
  flexDirection: 'row',
  gap: '10px',
  justifyContent: 'space-between',
  alignItems: 'center'
}));


export const ValuePeriodLabel = styled(Typography)(
  ({ theme }) => ({
    fontSize: '12px',
    fontFamily: 'Plus Jakarta Sans',
    color: theme.journeyPalette.grey[900]
  })
);

export const TargetPercentageView = styled(Typography)(() => ({

  fontSize: '20px',
  padding: '5px',
  fontFamily: 'Plus Jakarta Sans',
  textAlign: 'center',
  color: palette.common.darkBlue
}));


export const ActionPlanContainer = styled(Stack)(() => ({
  border: `1px solid ${palette.border.grey}`,
  borderRadius: "8px",
  gap: "10px",
  padding: '10px',
  marginTop: 10
}));

export const ActionPlanTitle = styled(Typography)(() => ({
  fontWeight: "bold",
  fontFamily: 'Plus Jakarta Sans',
  fontSize: '14px',
  color: palette.common.darkBlue
}));


export const ActionPlanEntityDetails = styled(Stack)(() => ({
  borderRadius: "8px",
  padding: '12px',
  gap: 5,
  cursor: "pointer",
  backgroundColor: palette.blue[8]
}));

export const ActionPlanEntityTitle = styled(Typography)(() => ({
  fontFamily: 'Plus Jakarta Sans',
  fontWeight: 800,
  fontSize: '16px',
  color: palette.common.darkBlue
}));

export const ActionPlanEntityGoal = styled(Typography)(() => ({
  fontFamily: 'Plus Jakarta Sans',
  fontSize: '15px',
  color: palette.common.darkBlue
}));